import { FC, HTMLAttributes, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Autocomplete, AutocompleteRenderInputParams, Box, Button, CircularProgress, Grid, IconButton, Link, Paper, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { IBBPeriodFilter, IComboBoxIds, IComboBoxSetStates, IOption } from '../../interfaces/comboBox';
import LoadingBackdrop from '../common/loading-backdrop';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import { SelectedClientContext } from '../../context/selectedClientContext';
import { getARCollateralRequest, getBBPeriodRequest, getBBCalcDatesRequest, getCollateralRequest, getIneligibleRule, getIneligibleSettings, getOptionLabel, getOptions, isOptionEqualToValue, formatDate, checkRate, checkRatesByToCurrencyIds, getClientByIdRequest, getAvailableUpcDatesForCalc, getUpcToCurrencyIds, getAvailableUpcDatesForCalcForAPAging, getUpcToCurrencyIdsForAPAging } from '../../utility/helper';
import { useIsFirstRender } from '../../utility/hooks';
import { IBBPeriod, IClient, IIneligibleSetting } from '../../interfaces';
import { AMERICAN_DATE_FORMAT, GET, ISO_DATE_FORMAT, NON_EXISTING } from '../../utility/constants';
import WarningModal from '../file-import/modals/warning-modal';
import DisabledComponentsContainer from '../common/disabled-components-container';
import axiosInstance from '../../service/axiosInstance';
import { ineligibleSettingAPI, reportsAPI } from '../../service/api';

export interface IDialogSelectProps {
  handleClose         : () => void;
  title               : string;
  subtitle?           : string;
  selectARCollateral? : boolean;
  selectInvCollateral?: boolean;
  selectBBPeriod?     : boolean;
  selectMultipleBB?   : boolean;
  forReport?          : boolean;
  forCalculate?       : boolean;
  reportLink?         : IReportRoutes;
  calculateBB?        : (borrowerName: string, arCollateralName: string, borrowerId: number, bbPeriodId: number, arCollateralId: number) => void;
  calculateUPC?       : (selectedClient: IClient, selectedEndDate: string) => void;
  bbCalcDate?         : boolean;
  hideFileImportLoader?: () => void;
}

export type IReportRoutes = 'borrowing-base' | 'ar-ineligible' | 'ar-aging' | 'inventory' | 'roll-forward' | 'ar-compare' | 'ap-aging' | 'financial-mapping' | 'balance-sheet' | 'profit-and-loss';

const arCollateralAndAsOfDateSpecificReports: IReportRoutes[] = ['ap-aging', 'ar-aging', 'ar-ineligible', 'roll-forward'];

const noCurrencyParams: IReportRoutes[] = ['borrowing-base'];

const DialogSelect: FC<IDialogSelectProps> = (props) => {
  const navigate                                          = useNavigate();
  const { selectedClient, clients, setSelectedClient }    = useContext(SelectedClientContext);
  const [arCollaterals, setARCollaterals]                 = useState<IOption[]>([]);
  const [selectedARCollateral, setSelectedARCollateral]   = useState<IOption | null>(null);
  const [arCollateralInput, setARCollateralInput]         = useState<string>('')
  const [invCollaterals, setInvCollaterals]               = useState<IOption[]>([]);
  const [selectedInvCollateral, setSelectedInvCollateral] = useState<IOption | null>(null);
  const [invCollateralInput, setInvCollateralInput]       = useState<string>('')
  const [bbPeriods, setBBPeriods]                         = useState<IOption[]>([]);
  const [selectedBBPeriod, setSelectedBBPeriod]           = useState<IOption | null>(null);
  const [bbPeriodInput, setBBPeriodInput]                 = useState<string>('');
  const [selectedBBPeriods, setSelectedBBPeriods]         = useState<IOption[]>([]);
  const [showVerbiage, setShowVerbiage]                   = useState<boolean>(false);
  const [isLoading, setIsLoading]                         = useState<boolean>(false);
  const [isLoadingBBPeriod, setIsLoadingBBPeriod]         = useState<boolean>(false);
  const [isLoadingOptions, setIsLoadingOptions]           = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen]                     = useState<boolean>(false);
  const isFirstRender                                     = useIsFirstRender();
  const [childClient, setChildClient]                     = useState<IClient | null>(null);
  const isUltimateParent                                  = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);
  const isChildClient                                     = useMemo(() => Boolean(selectedClient?.parentClientFk), [selectedClient]);

  /**
   * This function is responsible for initializing data and options based on the selected client record.
   * It fetches relevant data for the calculation/report based on the selected client.
   */
  useEffect(() => {
    if (!isUltimateParent) {
      // Check if current selected client is a child of the parent
      if (isChildClient) {
        setChildClient(selectedClient);
        const parentClient = clients?.find(client => client.recordId === selectedClient?.parentClientFk);
        setSelectedClient(parentClient ?? null);
      } else {
        getOptionsForNonUpc();
      }
    } else {
      getOptionsForUpc();
    }

    return () => {
      // return the original client if child client is stored
      if (childClient) {
        setSelectedClient(childClient);
      }
    }
  }, [selectedClient, clients, childClient]);

  /**
   * This function handles the selection of an AR collateral and triggers actions based on the selection.
   * It fetches additional data related to the selected AR collateral.
   */
  useEffect(() => {
    if (!isUltimateParent) {
      (async () => {
        if (selectedARCollateral?.recordId === undefined) { return; }
        const borrowerId = parseInt(localStorage.getItem('selectedClientRecordId') ?? '0');
        const arCollateralId = selectedARCollateral.recordId;
        const params = { borrowerId, invCollateralId: '0', bbPeriodId: '0', arCollateralId: `${arCollateralId}`, customerId: '0' };
        handleBBPeriod(borrowerId, arCollateralId, params);
      })()
    }
  }, [isUltimateParent, selectedARCollateral])

  /**
   * This function gets the options for the dropdowns when the selected client is not an ultimate parent.
   */
  const getOptionsForNonUpc = async () => {
    const borrowerId = localStorage.getItem('selectedClientRecordId') ?? '0';
    const parsedBorrowerId = parseInt(borrowerId);
    const params = { borrowerId, invCollateralId: '0', bbPeriodId: '0', arCollateralId: '0', customerId: '0' };

    if (!parsedBorrowerId) { return; }

    if (props.bbCalcDate && props.selectBBPeriod) {
      getOptions({ type: 'bbCalcDate', params, getSetStatesByType, setSelectedByType, mainRequest: getBBCalcDatesRequest(parsedBorrowerId)});
      return;
    }

    if (props.selectARCollateral) {
      getOptions({type: 'arCollateral', params, getSetStatesByType, setSelectedByType, mainRequest: getARCollateralRequest(parsedBorrowerId), requestForDefault: true});
      return;
    }
    if (props.selectInvCollateral) {
      getOptions({type: 'invCollateral', params, getSetStatesByType, setSelectedByType, mainRequest: getCollateralRequest(parsedBorrowerId), requestForDefault: true});
    }
  };

  /**
   * This function gets the options for the dropdowns when the selected client is an ultimate parent.
   */
  const getOptionsForUpc = async () => {
    setIsLoadingOptions(true);
    await handleUpcBBPeriods(selectedClient?.recordId as number);
    setIsLoadingOptions(false);
  };

  /**
   * This function is responsible for handling the BBPeriod selection.
   * It initiates data fetching and loading states for BBPeriod options.
   *
   * @param borrowerId The ID of the borrower.
   * @param arCollateralId The ID of the AR collateral.
   * @param params Parameters for data retrieval.
   */
  const handleBBPeriod = async (
    borrowerId: number,
    arCollateralId: number,
    params: {
      borrowerId: number;
      invCollateralId: string;
      bbPeriodId: string;
      arCollateralId: string;
      customerId: string;
    }) => {
      try {
        setIsLoadingOptions(true);
        if (props.selectBBPeriod && props.forCalculate) {
          await getOptionsForCalculate(borrowerId, arCollateralId, params);
        }
        if ((props.selectBBPeriod || props.selectMultipleBB) && props.forReport) {
          await getBBPeriodOptions(params, borrowerId, getFilter(props.reportLink));
        }
      } finally {
        setIsLoadingOptions(false);
      }
  };

  /**
   * This function is responsible for handling the BBPeriod selection in UPC.
   * It initiates data fetching and loading states for BBPeriod options for the UPC.
   * @param borrowerId The record id of the Ultimate Parent Client
   */
  const handleUpcBBPeriods = async (borrowerId: number) => {
    if (props.selectBBPeriod && props.forCalculate) {
      const hasSettingAndRule = await hasUpcIneligibleSettingsAndRule(borrowerId);
      if (hasSettingAndRule) {
        const options: IOption[] | undefined = await getAvailableUpcDatesForCalc(borrowerId);
        setBBPeriods(options ?? []);
      }
      setShowVerbiage(!hasSettingAndRule);
    }
    if ((props.selectBBPeriod || props.selectMultipleBB) && props.forReport) {
      const options: IOption[] | undefined = await getAvailableUpcDatesForReport(borrowerId, props.reportLink as IReportRoutes);
      setBBPeriods(options ?? []);
    }
  };

  /**
   * This function gets the available as of dates for reports.
   * @param borrowerId The record id of the Ultimate Parent Client.
   */
  const getAvailableUpcDatesForReport = async (borrowerId: number, reportLink: IReportRoutes) => {
    if (reportLink === 'roll-forward') {
      return await getDatesForRollForwardReport(borrowerId);
    }
    if (reportLink === 'ap-aging') {
      return await getAvailableUpcDatesForCalcForAPAging(borrowerId);
    }
    return await getDatesForArIneligibleReport(borrowerId);
  };

  /**
   * This function gets the available as of dates for AR Ineligible Report.
   * @param borrowerId The record id of the Ultimate Parent Client.
   * @returns The options for the available as of dates.
   */
  const getDatesForArIneligibleReport = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.arIneligibleReport.GET_AVAILABLE_UPC_DATES,
        method: GET,
        params: { parentClientId: borrowerId }
      });

      const endDates: string[] = response.data;
      const bbPeriodOpts: IOption[] = endDates.map((endDate, index) => ({
        recordId: parseInt(formatDate(endDate, 'YYYYMMDD')),
        label: formatDate(endDate, AMERICAN_DATE_FORMAT),
        default: index === 0
      }))
      return bbPeriodOpts;
    } catch (error) {
      console.log('GET AVAILABLE UPC AS OF DATES FOR AR INELIGIBLE REPORT ERROR: ', error);
    }
  }

  /**
   * This function gets the available as of dates for Roll Forward Report.
   * @param borrowerId The record id of the Ultimate Parent Client.
   * @returns The options for the available as of dates.
   */
  const getDatesForRollForwardReport = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: reportsAPI.rollForwardReport.GET_AVAILABLE_UPC_DATES,
        method: GET,
        params: { parentClientId: borrowerId }
      });

      const endDates: string[] = response.data;
      const bbPeriodOpts: IOption[] = endDates.map((endDate, index) => ({
        recordId: parseInt(formatDate(endDate, 'YYYYMMDD')),
        label: formatDate(endDate, AMERICAN_DATE_FORMAT),
        default: index === 0
      }))
      return bbPeriodOpts;
    } catch (error) {
      console.log('GET AVAILABLE UPC AS OF DATES FOR ROLL FORWARD REPORT ERROR: ', error);
    }
  }

  /**
   * This function fetches BBPeriod options for calculation and handles the initial setup.
   * It checks if there are ineligible settings and rules and then fetches BBPeriod options accordingly.
   *
   * @param borrowerId The ID of the borrower.
   * @param arCollateralId The ID of the AR collateral.
   * @param params Parameters for data retrieval.
   */
  const getOptionsForCalculate = async (
    borrowerId: number,
    arCollateralId: number,
    params: {
      borrowerId: number;
      invCollateralId: string;
      bbPeriodId: string;
      arCollateralId: string;
      customerId: string;
    }
  ) => {
    if (!isFirstRender) {
      const hasSetupIneligibles = await hasIneligibleSettingsAndRule(borrowerId, arCollateralId);
      hasSetupIneligibles && await getBBPeriodOptions(params, borrowerId, 'settings')
    } else {
      await getBBPeriodOptions(params, borrowerId, 'settings');
      setShowVerbiage(false);
    }
  };

  /**
   * This function defines the paper component used for rendering dropdowns.
   *
   * @param props The props for the paper component.
   * @returns The paper component wrapped around the provided children.
   */
  const paperComponent = useCallback((props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.dropdown}>{props.children}</Paper>), []);

  /**
   * This function handles the action to proceed, either for generating a report or calculating ineligibles.
   * It invokes the appropriate action based on the context.
   */
  const handleProceed = useCallback(async () => {
    const isARCollateralAndAsOfDateSpecific = props.reportLink && arCollateralAndAsOfDateSpecificReports.includes(props.reportLink);

    if (isARCollateralAndAsOfDateSpecific) {
      const rate = await hasRateExistence();
      if (rate) {
        handleNavigate();
        return;
      }
    }

    if (props.forReport && !isARCollateralAndAsOfDateSpecific) {
      handleNavigate();
      return;
    }

    if (props.forCalculate) {
      const rate = await hasRateExistence();
      if (rate) { 
        handleCalculate();
        props.hideFileImportLoader?.();
        return;
      }
    }
    
    setIsModalOpen(true);
  }, [selectedClient, selectedARCollateral, selectedBBPeriod, selectedInvCollateral, selectedBBPeriods]);

  /**
   * This function checks the existence of a rate for the selected client, AR collateral, and BBPeriod.
   * If a rate exists or multiple BBPeriods are selected, it proceeds with the action.
   */
  const hasRateExistence = async () => {
    const nonNullSelectedClient = selectedClient as Required<IClient>
    const nonNullSelectedBBPeriod = selectedBBPeriod as Required<IOption>;

    if (!isUltimateParent) {
      const nonNullSelectedARCollateral = selectedARCollateral as Required<IOption>
      const rate = await checkRate(nonNullSelectedARCollateral.recordId, nonNullSelectedClient.recordId, nonNullSelectedBBPeriod.label);
      return Boolean(rate);
    } else {
      const asOfDate = formatDate(nonNullSelectedBBPeriod.label, 'YYYYMMDD');
      const toCurrencyIds = props.reportLink !== 'ap-aging'
        ? await getUpcToCurrencyIds(selectedClient?.recordId as number, asOfDate)
        : await getUpcToCurrencyIdsForAPAging(selectedClient?.recordId as number, asOfDate);

      const response = await getClientByIdRequest(nonNullSelectedClient.recordId);

      if (response) {
        const rate = await checkRatesByToCurrencyIds(
          nonNullSelectedClient.recordId,
          parseInt(response.data.currencyId),
          toCurrencyIds ?? [],
          asOfDate
        );

        return Boolean(rate);
      } else return false;
    }
  };

  /**
   * This function is responsible for fetching BBPeriod options used for selecting BBPeriods.
   *
   * @param params Parameters for data retrieval.
   * @param parsed The parsed borrowerId.
   * @param filter Optional filter for BBPeriod options.
   */
  const getBBPeriodOptions = async (params: any, parsed: number, filter?: IBBPeriodFilter) => {
    await getOptions({type: 'bbPeriod', params, getSetStatesByType, setSelectedByType, mainRequest: getBBPeriodRequest(parsed), requestForDefault: false, filter: filter});
    setIsLoadingOptions(false);
  };

  /**
   * This function determines the appropriate filter for BBPeriod options based on the report link.
   *
   * @param reportLink The report link to determine the filter.
   * @returns The determined BBPeriod filter.
   */
  const getFilter: ((reportLink?: IReportRoutes) => IBBPeriodFilter) = (reportLink?: IReportRoutes) => {
    if (reportLink === 'ar-aging') return 'settings';
    if (reportLink === 'ap-aging') return 'apAging';
    if (reportLink === 'roll-forward') return 'rollForward';
    return 'ineligible';
  };

  /**
   * This function checks whether there are ineligible settings and rules for a specific borrower and AR collateral.
   *
   * @param borrowerId The ID of the borrower.
   * @param arCollateralId The ID of the AR collateral.
   * @returns Returns true if there are settings and rules, otherwise false.
   */
  const hasIneligibleSettingsAndRule = async (borrowerId: number, arCollateralId: number) => {
    try {
      setIsLoading(true);
      const settingsResponse = await getIneligibleSettings(borrowerId, arCollateralId);
      const settings: IIneligibleSetting[] = settingsResponse.data;
      const hasSettings = settings.length > 0;

      const enabledSettingIds: number[] = settings
        .filter((setting: IIneligibleSetting) => !setting.disabled)
        .map((setting: IIneligibleSetting) => setting.ineligibleSettingId);

      const mappedSetupIneligibles = await Promise.all(enabledSettingIds.map(async (id: number) => {
        const ruleResponse = await getIneligibleRule(id, arCollateralId);
        return ruleResponse.data.totalElements > 0;
      }));
      const hasRules = mappedSetupIneligibles.length > 0 ? mappedSetupIneligibles.every(hasRule => hasRule) : false;

      const hasSettingsAndRules = hasSettings && hasRules;
      setShowVerbiage(!hasSettingsAndRules);
      if (!hasSettingsAndRules) setSelectedBBPeriod(null);
      return hasSettingsAndRules;
    } catch (error) {
      console.log('GET INELIGIBLE SETTINGS ERROR: ', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * This function checks whether there are ineligible settings and rules for the parent client id.
   * @param borrowerId The record id of the Ultimate Parent Client.
   * @returns A boolean value whether there are ineligible settings and rules for the parent client id.
   */
  const hasUpcIneligibleSettingsAndRule = async (borrowerId: number) => {
    try {
      const response = await axiosInstance.request({
        url: ineligibleSettingAPI.HAS_UPC_SETTINGS_AND_RULE,
        method: GET,
        params: { parentClientId: borrowerId }
      });
      const hasUpcIneligibleSettingsAndRule: boolean = response.data;
      return hasUpcIneligibleSettingsAndRule;
    } catch (error) {
      console.log('HAS UPC INELIGIBLE SETTINGS AND RULE ERROR: ', error);
      return false;
    }
  }

  /**
   * This function retrieves the IDs of selected BBPeriods and joins them into a comma-separated string.
   *
   * @returns A comma-separated string of selected BBPeriod IDs.
   */
  const getSelectedBBPeriodIds = () => {
    return selectedBBPeriods.map((period: IOption) => period.recordId).join(',');
  };

  /**
   * This function constructs the report link based on selected criteria and report type.
   * It includes query parameters for the selected client, AR collateral, BBPeriod, and additional options.
   *
   * @returns A promise that resolves to the constructed report link.
   */
  const getReportLink = async () => {
    const main = `/reports/${props.reportLink}?clientId=${selectedClient?.recordId}`
    const queryParams: string[] = [];

    if (selectedARCollateral) queryParams.push(`arCollateralId=${selectedARCollateral?.recordId}`);
    if (selectedInvCollateral) queryParams.push(`invCollateralId=${selectedInvCollateral?.recordId}`);
    if (props.bbCalcDate) {
      setIsLoadingBBPeriod(true);
      try {
        const response = await getBBPeriodRequest(parseInt(localStorage.getItem('selectedClientRecordId') ?? '0'));
        const fetchedBBPeriods: IBBPeriod[] = response.data.content;

        const sortedPeriodsByLatest = [...fetchedBBPeriods].sort((curr, prev) => {
          return new Date(prev.endDate).getTime() - new Date(curr.endDate).getTime();
        });
        queryParams.push(`bbPeriodId=${sortedPeriodsByLatest[0].recordId}`);
      } catch (error) {
        console.log('ERROR fetching BB PERIOD BY BORROWER ID: ', error);
      } finally {
        setIsLoadingBBPeriod(false);
      }

      queryParams.push(`calcDate=${formatDate((selectedBBPeriod as IOption).label, ISO_DATE_FORMAT)}`); 
    }
    if (selectedBBPeriod && props.reportLink !== 'borrowing-base') {
      queryParams.push(`bbPeriodId=${selectedBBPeriod?.recordId}`);
      queryParams.push(`endDate=${formatDate(selectedBBPeriod?.label, 'YYYYMMDD')}`);
    }
    if (selectedBBPeriods.length > 1) queryParams.push(`bbPeriodIds=${getSelectedBBPeriodIds()}`);

    if (props.reportLink && !noCurrencyParams.includes(props.reportLink)) {
      queryParams.push(`currencyId=${selectedClient?.currencyId}`);
    };

    const params: string = queryParams.join('&');
    return main.concat('&', params);
  };

  /**
   * This function generates the JSX for a dropdown component based on its type.
   *
   * @param type The type of the dropdown.
   * @returns The JSX element representing the dropdown.
   */
  const getDropdown = (type: IComboBoxIds) => {
    return (
      <Box sx={styles.dropdownContainer}>
        <Typography
          tabIndex={0}
          component='label'
          htmlFor={`${type}-dropdown`}
          sx={styles.dropdownLabel}
        >
          {getLabelByType(type)}
        </Typography>
        {getAutocompleteComponent(type)}
      </Box>
    )
  };

  /**
   * This function generates the Autocomplete component based on types and if for UPC.
   * @param type The type of the dropdown.
   * @returns The JSX element representing the dropdown.
   */
  const getAutocompleteComponent = (type: IComboBoxIds) => {
    const states = getSetStatesByType(type);

    if (isUltimateParent && type === 'arCollateral') {
      return (
        <Autocomplete
          id={`${type}-dropdown`}
          disablePortal
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          options={[{ recordId: 0, label: 'All', default: true }]}
          defaultValue={{ recordId: 0, label: 'All', default: true }}
          disabled={true}
          {...getRenderInput(type)}
          PaperComponent={paperComponent}
          size='small'
          componentsProps={{
            popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
            clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
          }
          sx={styles.dropdown}
        />
      )
    } else if (type === 'bbPeriod' && props.selectMultipleBB) {
      return (
        <Autocomplete
          multiple
          id={`${type}-dropdown`}
          disablePortal
          getOptionLabel={getOptionLabel}
          limitTags={1}
          disableCloseOnSelect
          isOptionEqualToValue={isOptionEqualToValue}
          options={states.options}
          value={selectedBBPeriods}
          loading={isLoadingOptions}
          inputValue={states.input}
          {...getRenderInput(type)}
          PaperComponent={paperComponent}
          size='small'
          componentsProps={{
            popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
            clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
          }
          {...getMultipleChange()}
          sx={styles.dropdown}
        />
      )
    } else {
      return (
        <Autocomplete
          id={`${type}-dropdown`}
          disablePortal
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          options={states.options}
          value={states.selected}
          loading={isLoadingOptions}
          inputValue={states.input}
          disabled={type === 'bbPeriod' && props.forCalculate && showVerbiage}
          {...getRenderInput(type)}
          PaperComponent={paperComponent}
          size='small'
          componentsProps={{
            popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
            clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
          }
          {...getHandleChange(states, type)}
          sx={styles.dropdown}
        />
      )
    }
  }

  /**
   * This function constructs event handlers for change and input events in the dropdown components.
   *
   * @param states The states associated with the dropdown.
   * @param type The type of the dropdown.
   * @returns Object with event handler functions.
   */
  const getHandleChange = (states: any, type: IComboBoxIds) => {
    return {
      onChange: (_: SyntheticEvent<Element, Event>, newVal: IOption | null) => {
        states.setSelected(newVal);
        if (type === 'arCollateral') resetDates();
      },
      onInputChange: (_: SyntheticEvent<Element, Event>, newInput: string) => states.setInput(newInput)
    }
  };

  /**
   * This function constructs event handlers for change and input events in multiple-select dropdown components.
   *
   * @returns Object with event handler functions.
   */
  const getMultipleChange = () => {
    return {
      onChange: (_: SyntheticEvent<Element, Event>, newVal: readonly IOption[]) => setSelectedBBPeriods([...newVal]),
      onInputChange: (_: SyntheticEvent<Element, Event>, newInput: string) => setBBPeriodInput(newInput)
    }
  };

  /**
   * This function defines the rendering of the dropdown input component.
   *
   * @param type The type of the dropdown.
   * @returns Object with renderInput function.
   */
  const getRenderInput = (type: IComboBoxIds) => {
    return {renderInput: (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        placeholder={type === 'bbPeriod' && selectedBBPeriods.length > 0 ? undefined : 'Please Select'}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
            {type === 'bbPeriod' && isLoadingOptions ?
              <CircularProgress color='inherit' size={15} sx={styles.loadingIcon} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  };

  /**
   * This function returns the label for the given dropdown type.
   *
   * @param type The type of the dropdown.
   * @returns The label for the dropdown.
   */
  const getLabelByType = (type: IComboBoxIds) => {
    switch (type) {
      case 'arCollateral':
        return 'Select Collateral';
      case 'invCollateral':
        return 'Select Collateral';
      default:
        return 'Select As of Date';
    }
  }

  /**
   * This function returns the states and state setters for a given dropdown type.
   *
   * @param type The type of the dropdown.
   * @returns Object containing options, selected value, and input value for the dropdown.
   */
  const getSetStatesByType = (type: IComboBoxIds) => {
    switch (type) {
      case 'arCollateral':
        return {options: arCollaterals, setOptions: setARCollaterals, selected: selectedARCollateral, setSelected: setSelectedARCollateral, input: arCollateralInput, setInput: setARCollateralInput};
      case 'invCollateral':
        return {options: invCollaterals, setOptions: setInvCollaterals, selected: selectedInvCollateral, setSelected: setSelectedInvCollateral, input: invCollateralInput, setInput: setInvCollateralInput};
      default:
        return {options: bbPeriods, setOptions: setBBPeriods, selected: selectedBBPeriod, setSelected: setSelectedBBPeriod, input: bbPeriodInput, setInput: setBBPeriodInput};
    }
  };

  /**
   * This function resets the selected BBPeriods and their input value.
   */
  const resetDates = () => {
    setBBPeriods([]);
    setSelectedBBPeriod(null);
    setSelectedBBPeriods([]);
    setBBPeriodInput('');
  };

  /**
   * This function sets the selected value and input value for a dropdown type.
   *
   * @param selected The selected option.
   * @param _type The type of the dropdown.
   * @param setStates The state setters for the dropdown.
   */
  const setSelectedByType = (selected: IOption, _type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    setStates.setSelected(selected);
    setStates.setInput(selected.label);
  };

  /**
   * This function handles navigation to the report page based on selected criteria and report type.
   * @param currencyId The currencyId of the borrower.
   */
  const handleNavigate = async () => {
    navigate(await getReportLink());
  };

  /**
   * This function handles the calculation process if the 'Calculate' button is clicked.
   * It checks if the required criteria are selected and then triggers the calculation.
   */
  const handleCalculate = () => {
    if (props.calculateBB) {
      const args = {
        borrowerName: (selectedClient as Required<IClient>).borrowerName ?? '',
        arCollateralName: (selectedARCollateral as Required<IOption>).label,
        borrowerId: (selectedClient as Required<IClient>).recordId ?? NON_EXISTING,
        bbPeriodId: (selectedBBPeriod as Required<IOption>).recordId,
        arCollateralId: (selectedARCollateral as Required<IOption>).recordId,
      }
      props.calculateBB(args.borrowerName, args.arCollateralName, args.borrowerId, args.bbPeriodId, args.arCollateralId);
    }

    if (props.calculateUPC) {
      props.calculateUPC(selectedClient as IClient, `${selectedBBPeriod?.recordId}`);
    }
  };

  /**
   * This function determines if the 'Proceed' button should be disabled based on various conditions.
   *
   * @returns True if the button should be disabled, false otherwise.
   */
  const handleDisabled = () => {
    const isDisabled: boolean[] = [];

    if (props.selectARCollateral) isDisabled.push(!selectedARCollateral);
    if (props.selectInvCollateral) isDisabled.push(!selectedInvCollateral);
    if (props.selectBBPeriod) isDisabled.push(!selectedBBPeriod);
    if (props.selectMultipleBB) isDisabled.push(Boolean(selectedBBPeriods.length < 2));
    if (props.forCalculate) isDisabled.push(showVerbiage);
    if (isLoading) isDisabled.push(isLoading);
    if (isLoadingOptions) isDisabled.push(isLoading);

    return isDisabled.includes(true);
  };

  /**
   * This function returns an object with an onClick event handler for a specific action.
   *
   * @returns Object with an onClick function for navigation.
   */
  const handleClick = () => {
    if (isUltimateParent) {
      return {
        onClick: () => {
          props.handleClose();
          navigate(`/ineligible-settings?arCollateralId=0`);
        }
      };
    }
    if (selectedARCollateral?.recordId === undefined) { return; }
    return {
      onClick: () => {
        props.handleClose();
        navigate(`/ineligible-settings?arCollateralId=${selectedARCollateral?.recordId}`);
      }
    };
  };

  return (
    <>
      <Grid container id=':rd:' sx={styles.dialog}>
        <Grid item xs={12}>
          <Grid container justifyContent='space-between' alignItems={props.subtitle ? 'flex-start' : 'center'} sx={styles.dropdownTitleContainer}>
            <Grid item>
              <Typography tabIndex={0} id='dialog-title' variant='h6' component='h1'>{props.title}</Typography>
              {props.subtitle && <Typography tabIndex={0} id='dialog-subtitle' variant='subtitle2' component='h2'>{props.subtitle}</Typography>}
            </Grid>
            <Grid item>
              <IconButton onClick={props.handleClose} aria-label='Close icon'><CloseIcon fontSize='inherit' /></IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.dialogBody}>
          {props.forCalculate && showVerbiage && 
            <Box sx={styles.warningMessage}>
              <Alert severity='info' variant='outlined' color='warning' icon={<InfoIcon sx={styles.infoIcon} />} sx={styles.info}>
                <Typography tabIndex={0} variant='subtitle2' component='h6' sx={styles.infoMessage}>
                  Please set up your Ineligible Settings prior to calculating the Ineligible.
                  Click <Link sx={styles.ineligibleLink} {...handleClick()}>here</Link> to go to the Ineligible Settings page.
                </Typography>
              </Alert>
            </Box>}
          {isUltimateParent && getDropdown(('arCollateral'))}
          {props.selectARCollateral && getDropdown('arCollateral')}
          {props.selectInvCollateral && getDropdown('invCollateral')}
          {(props.selectBBPeriod || props.selectMultipleBB) && getDropdown('bbPeriod')}
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Grid container justifyContent='flex-end' gap={1}>
            <Button
              variant='outlined'
              size='small'
              disableElevation
              sx={styles.cancelButton}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <DisabledComponentsContainer isDisabled={handleDisabled()}>
              <Button
                variant='contained'
                size='small'
                disableElevation
                disabled={handleDisabled()}
                aria-label={handleDisabled() ? 'Proceed button disabled' : 'Proceed'}
                sx={styles.proceedButton}
                onClick={handleProceed}
              >
                Proceed
              </Button>
            </DisabledComponentsContainer>
          </Grid>
        </Grid>
        <LoadingBackdrop
          isLoading={isLoadingBBPeriod}
        />
      </Grid>
      <WarningModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => navigate(`/clients/${selectedClient?.recordId}/settings/exchangeRate`)}
        noDataIncluded
        issueType='error'
        issueMessages={['There is no exchange rate yet for the selected \'As of Date\' and collateral currency. Click Proceed to visit Exchange Rate page']}
      />
    </>
  )
}
export default DialogSelect;