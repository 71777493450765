import { ChangeEvent, FC, MouseEvent, useEffect, useState, useCallback, SyntheticEvent, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertColor, Autocomplete, AutocompleteRenderInputParams, Box, Button, CircularProgress, Container, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, Skeleton, SxProps, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Theme, Tooltip, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SearchIcon from '@mui/icons-material/Search';
import { checkIfZeroOrUndefined, checkUserPermissions, formatDate, formatDateToLocal, formatNumberWithCurrencySymbol, getFileExtension, getFileValuesByDocument, getTooltip, getUniqueKeys, handleDownloadFile, sortAscending } from '../../../../utility/helper';
import { AMERICAN_DATE_8HR_FORMAT, AMERICAN_DATE_FORMAT, GET, LONG_DATE_8HR_FORMAT, NO_PERMISSION_MSG, PAGINATION, PERMISSIONS, PLACEHOLDERS, PROMPT, PUT, TOASTER } from '../../../../utility/constants';
import styles from './styles';
import { IOption } from '../../../../interfaces/comboBox';
import { IClient } from '../../../../interfaces';
import { fileImportAPI } from '../../../../service/api';
import axiosInstance from '../../../../service/axiosInstance';
import Toaster, { ToasterState } from '../../../../components/toaster';
import ConfirmModal from '../../../../components/modals/confirm-modal';
import { AuthContext } from '../../../../context/authContext';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import WarningModal from '../../../../components/file-import/modals/warning-modal';
import { DocumentIssueTypes, IDocumentIssue, IUploadedFile } from '../../../../interfaces/fileimport';
import { FileImportPermissionsContext, IFileImportPermissionsContext } from '../../../../context/fileImportPermissionsContext';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { getLabelDisplayedRows, getLabelRowsPerPage } from '../../../../components/client-settings/tabs/tab-panel-contents/pagination';
import DisabledComponentsContainer from '../../../../components/common/disabled-components-container';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import DataMapIcon from '../../../../assets/images/DataMapIcon.svg';
import MappingModal from '../../stepper/stepper-content/datamap-tab/file-row/mapping-modal';
import { FileImportContext, IFileImportContext } from '../../../../context/fileImportContext';
import { getOptionalFields, getRequiredFields } from '../../stepper/stepper-content/datamap-tab';

interface FileImportTableProps {
  client: IOption | null;
}

interface PaginationButtonProps {
  page                : number;
  handlePreviousClick : () => void;
  getFilteredRows     : (searched: boolean, filteredRows: IUploadedFile[], importedFiles: IUploadedFile[], archivedFiles: IUploadedFile[], tabIndex: number) => IUploadedFile[];
  searched            : boolean;
  filteredRows        : IUploadedFile[];
  importedFiles       : IUploadedFile[];
  archivedFiles       : IUploadedFile[];
  tabIndex            : number;
  rowsPerPage         : number;
  handleNextClick     : () => void;
}

/**
 * This function formats decimal string to be used for aria-labels.
 * Invariance: All decimal string will be of length 2 since we applied formatCurrency before applying formatDecimals
 * @param decimalString The decimal string to be formatted.
 * @returns The formatted decimal string.
 */
export const formatDecimals = (decimalString: string): string => {
  const [tens, ones] = decimalString.split('');
  if (tens === '1') {
    const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    return teens[parseInt(ones, 10)];
  } else {
    const tensNames = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const onesNames = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    return `${tensNames[parseInt(tens, 10)]} ${onesNames[parseInt(ones, 10)]}`.trim();
  }
};

const remappableDocTypes = ['GL Transaction'];

/**
 * Component for the File Import Table
 * @param props The File Import Table props
 * @returns a component for the File Import Table
 */
const FileImportTable: FC<FileImportTableProps> = (props) => {
  const TABS = [ { label: 'Imported' }, { label: 'Archived' } ];
  const DEFAULT_PAGE = 0;
  const DEFAULT_ROWS_PER_PAGE = 10;

  const navigate                                             = useNavigate();
  const {
    importFile,
    downloadFile,
    archiveFile,
  }                                                          = useContext(FileImportPermissionsContext) as IFileImportPermissionsContext
  const { setSelectedClient,
          uploadedFiles, setUploadedFiles }                  = useContext(FileImportContext) as IFileImportContext;
  const {state}                                              = useContext(AuthContext)
  const [tabIndex, setTabIndex]                              = useState(TABS.findIndex(tab => tab.label === 'Imported'));
  const [page, setPage]                                      = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage]                        = useState(DEFAULT_ROWS_PER_PAGE);
  const [importedFiles, setImportedFiles]                    = useState<IUploadedFile[]>([]);
  const [archivedFiles, setArchivedFiles]                    = useState<IUploadedFile[]>([]);
  const [filteredRows, setFilteredRows]                      = useState<IUploadedFile[]>([]);
  const [isLoading, setIsLoading]                            = useState<boolean>(true);
  const [searched, setSearched]                              = useState<boolean>(false);
  const [collateral, setCollateral]                          = useState<string | null>('All');
  const [collaterals, setCollaterals]                        = useState<string[]>([]);
  const [collateralInput, setCollateralInput]                = useState<string>('');
  const [asOfDate, setAsOfDate]                              = useState<string | null>('All');
  const [asOfDates, setAsOfDates]                            = useState<string[]>([]);
  const [asOfDatesInput, setAsOfDatesInput]                  = useState<string>('');
  const [documentType, setDocumentType]                      = useState<string | null>('All');
  const [documentTypes, setDocumentTypes]                    = useState<string[]>([]);
  const [documentInput, setDocumentInput]                    = useState<string>('');
  const [enableUploading, setEnableUploading]                = useState<boolean>(true);
  const [hasUnmappedFiles, setHasUnmappedFiles]              = useState<boolean>(false);
  const [searchQuery, setSearchQuery]                        = useState<string>('');
  const [toasterState, setToasterState]                      = useState<ToasterState>(TOASTER.INITIAL_STATES);
  const [isModalOpen, setIsModalOpen]                        = useState<boolean>(false);
  const [selectedFile, setSelectedFile]                      = useState<IUploadedFile | null>(null);
  const [warningModalOpen, setWarningModalOpen]              = useState<boolean>(false);
  const [documentIssues, setDocumentIssues]                  = useState<IDocumentIssue[]>([]);
  const [isFileParsing, setIsFileParsing]                    = useState<boolean>(false);
  const [dataMapModalOpen, setDataMapModalOpen]              = useState<boolean>(false);
  const isUltimateParent                                     = useMemo(() => Boolean(props.client?.parentClient), [props.client]);

  /**
   * This useEffect hook performs certain actions when the selected client is changed.
   * - Clears the states of the file.
   * - Clears the search query of the search bar, and resets the filters to 'All'.
   * 
   * If there is a selected client,
   * - Enables the Upload File button.
   * - Triggers the loading state of the table.
   * - Fetches all uploaded files for the given client.
   */
  useEffect(() => {
    handleClearFiles();
    handleClearInput();

    if (props.client) {
      setEnableUploading(true);
      setIsLoading(true);
      getAllFiles();
      setSelectedClient(props.client);
    } else {
      setSelectedClient(null);
      setIsLoading(false);
    }
  }, [props.client])

  /**
   * This useEffect hook performs certain action when the
   * search inputs, search filters, files, and tab index (active = 0, archive = 1) are changed.
   * - Set the searched state to true if some of the inputs is not an empty string, or the word 'All'.
   * - Set the page to the default page.
   * 
   * If the searched state is true, the rows of the table will be filtered based on the search value.
   */
  useEffect(() => {
    const inputs: string[] = [collateralInput, asOfDatesInput, documentInput];
    const searched = inputs.map((input: string) => valueAllOrClear(input.trim())).some((value: boolean) => value === false) || searchQuery.trim() !== '';
    setSearched(searched);
    setPage(DEFAULT_PAGE);

    if (searched) filterRows(inputs, searchQuery, importedFiles, archivedFiles, tabIndex);
  }, [collateralInput, asOfDatesInput, documentInput, searchQuery, importedFiles, archivedFiles, tabIndex])

  /**
   * This useEffect hook performs certain action when the files and tab index (active = 0, archive = 1) are changed.
   * - It computes the current rows based on 'importedFiles', 'archivedFiles', and 'tabIndex'.
   * - Extracts unique values for collaterals, as-of dates, and document types from the current rows.
   * - Sets component state with the updated unique values.
   */
  useEffect(() => {
    const currentRows: IUploadedFile[] = getTabulatedRows(importedFiles, archivedFiles, tabIndex);

    const collaterals = currentRows.map((row) => row.arCollateralName ?? '').sort((a, b) => sortAscending(a, b));
    const asOfDates = currentRows.map((row) => formatDate(row.endDate ?? '', AMERICAN_DATE_FORMAT)).sort((a, b) => sortAscending(a, b));
    const docTypes = currentRows.map((row) => row.documentType ?? '').sort((a, b) => sortAscending(a, b));

    setCollaterals(Array.from(new Set(collaterals)));
    setAsOfDates(Array.from(new Set(asOfDates)));
    setDocumentTypes(Array.from(new Set(docTypes)));

  }, [importedFiles, archivedFiles, tabIndex])

  /**
   * This function check if the current user has the spicific permission to do a specific page functionality.
   * @param func funtion that the currenct user want to access.
   * @param permission permission the user need to do specific page functionality.
   * @param args argurments of the 'func' params need.
   * @returns a null or render the Toaster Component.
   */
  const checkPermission = async (func: Function, permission: string, ...args: any[]) => {
    const isPermitted = await checkUserPermissions(state.uid, permission)
    if (isPermitted) {
      func(...args);
    } else {
      setToasterState({
        open: true,
        message: NO_PERMISSION_MSG,
        severity: 'error'
      })
    }
  }

  /**
   * This useCallback hook returns a function that sets the tabIndex state to either 0 (Active tab) or 1 (Archive tab),
   * sets the page to the default page and clears the search inputs.
   */
  const handleTabChange = useCallback((_event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setPage(DEFAULT_PAGE);
    handleClearInput();
  }, []);

  /**
   * This useCallback hook returns a function that clears the searchQuery state and resets the selected collateral,
   * as of date, and document type filters to 'All'.
   */
  const handleClearInput = useCallback(() => {
    setSearchQuery('');
    setCollateral('All');
    setAsOfDate('All');
    setDocumentType('All');
  }, []);

  /**
   * This useCallback hook returns a function that clears the importedFiles, archivedFiles, filteredRows, and hasUnmapped states.
   */
  const handleClearFiles = useCallback(() => {
    setImportedFiles([]);
    setArchivedFiles([]);
    setFilteredRows([]);
    setHasUnmappedFiles(false);
  }, [])

  /**
   * This useCallBack hook returns a function that sets the toasterState's open to false.
   */
  const handleToasterClose = useCallback(() => setToasterState({...toasterState, open: false}), []);

  /**
   * This useCallBack hook returns a function that sets the modalOpen state to true.
   */
  const handleModalOpen = useCallback(() => setIsModalOpen(true), []);

  /**
   * This useCallBack hook returns a function that sets the modalOpen state to false.
   */
  const handleModalClose = useCallback(() => setIsModalOpen(false), []);

  /**
   * This memoized object includes required and optional fields based on the document type of the uploaded file.
   * It is created using the useMemo hook to avoid unnecessary recalculations.
   */
  const optionalAndRequiredFields = useMemo(() => {
    const requiredFields = getRequiredFields(selectedFile?.documentType ?? '');
    const optionalFields = getOptionalFields(selectedFile?.documentType ?? '');
    return { requiredFields, optionalFields };
  }, [selectedFile])

  /**
   * This function checks if the input is equal to an empty string or the string 'All'.
   * @param input The search input to be checked.
   * @returns A boolean value if the value is equal to the string 'All' or an empty string.
   */
  const valueAllOrClear = (input: string) => input === 'All' || input === '';

  /**
   * This function is the one responsible when the Upload File Button is clicked.
   * If there is a selected client, navigate to the Upload Files page with the selected client record id as parameter.
   * @param client The selected client where the user will upload a file.
   */
  const handleUpload = (client: IOption) => {
    navigate(`/upload-files?step=0&clientId=${client.recordId}`);
  };

  /**
   * This function asynchronously fetches a series of endpoints:
   * - Gets current processed files by the selected Client record id.
   * - Checks if the selected Client record id has unmapped files.
   * - Gets the document issues of the files by the selected Client record id.
   * 
   * After the fetching, the processed files are filtered into active and archived,
   * and the importedFiles, archivedFiles, hasUnmappedFiles, and documentIssues states are updated.
   */
  const getAllFiles = async () => {
    try {
      const res = await axiosInstance.request({
        url: fileImportAPI.uploadedFile.GET_PROCESSED_FILES_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId: props.client?.recordId, ...PAGINATION.PARAMETERS.DEFAULT_MAX_PAGES, sortBy: 'filename,ASC' },
      });
      const files: IUploadedFile[] = res.data.content;

      const res2 = await axiosInstance.request({
        url: fileImportAPI.uploadedFile.HAS_UNMAPPED_FILES,
        method: GET,
        params: { borrowerId: props.client?.recordId },
      })
      const hasUnmapped: boolean = res2.data;

      const res3 = await axiosInstance.request({
        url: fileImportAPI.documentIssue.FIND_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId: props.client?.recordId },
      })
      const documentIssues = res3.data.content;
    
      const imported: IUploadedFile[] = files
        .filter((file: IUploadedFile) => !file.archive)
        .map((file: IUploadedFile) => {
          const fileIssues = documentIssues.filter((documentIssue: IDocumentIssue) => documentIssue.documentId === file.recordId);
          return ({...file, documentIssues: fileIssues })
        });
      const archived: IUploadedFile[] = files
        .filter((file: IUploadedFile) => file.archive)
        .map((file: IUploadedFile) => {
          const fileIssues = documentIssues.filter((documentIssue: IDocumentIssue) => documentIssue.documentId === file.recordId);
          return ({...file, documentIssues: fileIssues })
        });

      setImportedFiles(imported);
      setArchivedFiles(archived);
      setHasUnmappedFiles(hasUnmapped);
      setDocumentIssues(documentIssues);
    } catch (error) {
      console.log('GET ALL FILES ERROR : ', error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * This function asynchronously archives a file, and updates the state of the importedFiles and archivedFiles.
   * If archiving is successful, a success toaster will be displayed. 
   * If unsuccessful, an error toaster will be displayed.
   * @param row The current file to be archived.
   * @param importedFiles The files that are under the active tab.
   * @param archivedFiles The files that are under the archive tab.
   */
  const handleArchiveFile = async (row: IUploadedFile, importedFiles: IUploadedFile[], archivedFiles: IUploadedFile[]) => {
    try {
      await axiosInstance.request({
        url: fileImportAPI.document.ARCHIVE_FILE,
        method: PUT,
        params: { recordId: row.recordId }
      })
      const imported: IUploadedFile[] = importedFiles.filter((imported: IUploadedFile) => imported.recordId !== row.recordId);
      setImportedFiles(imported);

      const archived: IUploadedFile[] = [...archivedFiles, row];
      setArchivedFiles(archived);

      showToaster('success', `${row.filename} has been archived.`);
    } catch (error) {
      showToaster('error', TOASTER.ERROR_ARCHIVING);
    }
  };

  /**
   * This function handles the opening of the confirm modal when archiving a file,
   * @param row The current file to be selected.
   */
  const handleArchive = (row: IUploadedFile) => {
    setSelectedFile(row);
    handleModalOpen();
  };

  /**
   * Asynchronously handles remapping of a row by parsing values, setting selected file,
   * and opening the data mapping modal.
   * @async
   * @param row The row to be remapped.
   * @returns A Promise that resolves when remapping is complete.
   */
  const handleRemapping = async (row: IUploadedFile) => {
    setIsFileParsing(true);
    const parsedFile: IUploadedFile = await parseValues(row);
    setSelectedFile(parsedFile);
    setIsFileParsing(false);
    setDataMapModalOpen(true);
  };

  /**
   * Asynchronously parses values of an uploaded file and updates its properties.
   * @param row The row representing the uploaded file.
   * @returns A Promise that resolves with the parsed uploaded file.
   */
  const parseValues = async (row: IUploadedFile) => {
    const uploadedFile: IUploadedFile = {...row};
    const fileExtension = getFileExtension(uploadedFile.filename);
    if (fileExtension === 'xlsx' || fileExtension === 'xls') {
      uploadedFile.isExcelFile = true;
    }

    if (uploadedFile.values === undefined) {
      const values: string[][] | undefined = await getFileValuesByDocument(uploadedFile);
      if (!values) {
        uploadedFile.isParsing = false;
      };
      uploadedFile.values = values;
    }
    
    uploadedFile.isParsing = false;

    setUploadedFiles(uploadedFiles.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    setFilteredRows(filteredRows.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    setImportedFiles(importedFiles.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    return uploadedFile;
  };

  /**
   * Checks if the uploaded file and the current item of the array has the same record id.
   * @param uploaded - The current file in the array.
   * @param uploadedFile - The uploaded file object used to update the array.
   * @returns A file whether the new or the current depending on the matched record id.
   */
  const mapUpdatedFile = (uploaded: IUploadedFile, uploadedFile: IUploadedFile) => {
    if (uploaded.recordId === uploadedFile.recordId) return uploadedFile;
    else return uploaded;
  };

  /**
   * This function shows the toaster with the appropriate severity and message.
   * @param severity The severity of the toaster.
   * @param message The message of the toaster.
   */
  const showToaster = (severity: AlertColor, message: string) => {
    const toaster = { open: true, severity, message };
    setToasterState(toaster);
  };

  /**
   * This function gets the rows to be displayed according to the tab selected (active or archive).
   * @param importedFiles The files that are under the active tab.
   * @param archivedFiles The files that are under the archive tab.
   * @param tabIndex The index of the tab of the table (active = 0, archive = 1).
   * @returns 
   */
  const getTabulatedRows = (importedFiles: IUploadedFile[], archivedFiles: IUploadedFile[], tabIndex: number) => {
    if (tabIndex === TABS.findIndex(tab => tab.label === 'Imported')) return importedFiles;
    else return archivedFiles;
  };

  /**
   * This function gets the rows to be displayed depending if there is a search query in place or none.
   * @param searched The boolean value if the search inputs and search queries are not 'All' or empty string.
   * @param filteredRows The rows that matched the search query.
   * @param importedFiles The files that are under the active tab.
   * @param archivedFiles The files that are under the archive tab.
   * @param tabIndex The index of the tab of the table (active = 0, archive = 1).
   * @returns 
   */
  const getFilteredRows = (searched: boolean, filteredRows: IUploadedFile[], importedFiles: IUploadedFile[], archivedFiles: IUploadedFile[], tabIndex: number) => {
    if (searched) return filteredRows;
    else return getTabulatedRows(importedFiles, archivedFiles, tabIndex);
  };

  /**
   * This function gets the rows to be displayed when a user searched or use one of the filters.
   * @param inputs The array of search inputs (AR Collateral, As of Date, Document Type search inputs).
   * @param searchQuery The input in the search bar.
   * @param importedFiles The files that are under the active tab.
   * @param archivedFiles The files that are under the archive tab.
   * @param tabIndex The index of the tab of the table (active = 0, archive = 1).
   */
  const filterRows = (inputs: string[], searchQuery: string, importedFiles: IUploadedFile[], archivedFiles: IUploadedFile[], tabIndex: number) => {
    let files = getTabulatedRows(importedFiles, archivedFiles, tabIndex);

    if (!valueAllOrClear(inputs[0])) {
      files = files.filter(file => file.arCollateralName === inputs[0])
    };

    if (!valueAllOrClear(inputs[1])) {
      files = files.filter(file => formatDate(file.endDate ?? '', AMERICAN_DATE_FORMAT) === inputs[1])
    };

    if (!valueAllOrClear(inputs[2])) {
      files = files.filter(file => file.documentType === inputs[2])
    };

    if (searchQuery !== '') {
      const escapedSearchQuery = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const custRegEx = new RegExp(escapedSearchQuery, 'gi');

      files = files.filter(file => {
        const position = file.filename.search(custRegEx)
        if (position !== -1) return file;
      })
    }

    setFilteredRows(files);
  };
  
  /**
   * This function redirects to the Data Mapping Step of the Upload File Page.
   */
  const navigateToDataMapping = () => {
    navigate(`/upload-files?step=1&clientId=${props.client?.recordId}`);
  };

  /**
   * This functions gets the icon button for the warnings of the current file if issues exists.
   * If there are none, it returns a spacer for the row to be aligned with the other rows.
   * @param row The current file.
   * @param documentIssues The collection of document issues by selected Client record id.
   * @returns Either an icon button of a warning icon or a spacer only.
   */
  const getAlertIcon = (row: IUploadedFile, documentIssues: IDocumentIssue[]) => {
    const documentIssueByDocId: IDocumentIssue[] = documentIssues.filter(doc => doc.documentId === row.recordId);

    if (documentIssueByDocId.length) {
      return (
        <IconButton
          aria-label='Warning icon'
          onClick={() => {
            setWarningModalOpen(true)
            setSelectedFile(row);
          }}
          sx={styles.warningIconButton}
        >
          <WarningAmberRoundedIcon fontSize='small' color='warning'/>
        </IconButton>
      )
    } else {
      return (
        <Box sx={styles.warningIconSpacer}></Box>
      )
    }
  }

  /**
   * This function gets the issue type, and the issue message for the current file from the collection of document issues.
   * @param selectedFile The current file.
   * @param documentIssues The collection of document issues by selected Client record id.
   * @returns An object containing the issue type, and the issue message.
   */
  const getIssueTypeAndMessage = (selectedFile: IUploadedFile | null, documentIssues: IDocumentIssue[]) => {
    const issues: IDocumentIssue[] = selectedFile ? documentIssues.filter(doc => doc.documentId === selectedFile.recordId) : [];

    // refactor this if there will be more issue types instead of warnings
    let issueType: DocumentIssueTypes = 'warning';
    let issuesByType: IDocumentIssue[] = issues.filter(doc => doc.issueType === 'warning');
    
    // Warning
    const issueMessages: string[] = issuesByType.map(issue => issue.issueMessage ?? '');    
    return {issueType, issueMessages};
  }

  /**
   * This function gets the content of the body based on the loading, or empty state.
   * @param selectedClient The selected client from the dropdown.
   * @param files The processed files fetched from the server.
   * @param page The page selected in the table pagination.
   * @param rowsPerPage The number of rows per page selected in the table pagination.
   * @returns Either of the three components:
   * - A skeleton loading body.
   * - A table with the files as the contents.
   * - An empty verbiage.
   */
  const getTableBody = (selectedClient: IClient | null, files: IUploadedFile[], page: number, rowsPerPage: number) => {
    if (isLoading) {
      return getLoadingBody();
    } else if (selectedClient && files.length) {
      return getDataBody(files, page, rowsPerPage);
    } else {
      return getEmptyBody();
    }
  };

  /**
   * This function returns a skeleton loading component for a table with 5 rows and 7 columns.
   * @returns A skeleton loading body.
   */
  const getLoadingBody = () => {
    return (
      <TableBody>
        {[...Array(5)].map((row) => (
          <TableRow key={getUniqueKeys(row)} sx={styles.tableRow}>
            {[...Array(isUltimateParent ? 6 : 8)].map((cell, index) => {
              const style: SxProps<Theme> | undefined = {};
              return (<TableCell key={getUniqueKeys(cell)} sx={style}><Skeleton /></TableCell>)})}
          </TableRow>))}
      </TableBody>
    )
  };

  /**
   * This function gets the table component for displaying the processed files.
   * @param files The files to be displayed.
   * @param page The page selected in the table pagination.
   * @param rowsPerPage The number of rows per page selected in the table pagination.
   * @returns A table with the files as the contents.
   */
  const getDataBody = (files: IUploadedFile[], page: number, rowsPerPage: number) => {
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * (rowsPerPage === -1 ? files.length : rowsPerPage);

    return (
      <TableBody>
        <TableRow aria-label='spacer-row' sx={{ height: '0.5rem', visibility: 'hidden'}}></TableRow>
        {files.slice(startIndex, endIndex).map((row: IUploadedFile) => {

          const parsedTotalAmount = checkIfZeroOrUndefined(row.totalTrxAmt);
          const withCurrencySymbol = formatNumberWithCurrencySymbol(parsedTotalAmount, row.currencySymbol);
          const isRemappable = remappableDocTypes.includes(row.documentType as string);

          return (
            <TableRow key={row.recordId} sx={styles.tableRow}>
              <TableCell sx={{...styles.centerAlignedText, ...styles.tableCell, ...styles.tableCellDocumentIssue}}>
                {getAlertIcon(row, documentIssues)}
              </TableCell>
              <TableCell tabIndex={0} sx={{...styles.tableCell}}>
                <Box sx={styles.boxContainerFileName} onMouseEnter={getTooltip}>
                  {row.filename}
                </Box>
              </TableCell>
              {isUltimateParent &&
              <TableCell tabIndex={0} sx={{...styles.tableCell}}>
                <Box sx={styles.boxContainerGeneral} onMouseEnter={getTooltip}>
                  {row.selectedSheet}
                </Box>
              </TableCell>}
              <TableCell tabIndex={0} sx={{...styles.tableCell}}>
                <Box sx={styles.boxContainerGeneral} onMouseEnter={getTooltip}>
                  {row.documentType}
                </Box>
              </TableCell>
              {!isUltimateParent &&
              <>
              <TableCell tabIndex={0} sx={{...styles.tableCell}}>
                <Box sx={styles.boxContainerGeneral} onMouseEnter={getTooltip}>
                  {row.arCollateralName}
                </Box>
              </TableCell>
              <TableCell tabIndex={0} sx={{...styles.tableCell}}>
                <Box sx={styles.boxContainerGeneral} onMouseEnter={getTooltip}>
                  {formatDate(row.endDate ?? '', AMERICAN_DATE_FORMAT)}
                </Box>
              </TableCell>
              <TableCell tabIndex={0} sx={{...styles.rightAlignedText, ...styles.tableCell}}>
                <Box
                  aria-label={row?.totalTrxAmt ?
                    withCurrencySymbol.split('.')[0] + 
                    " point " + formatDecimals(withCurrencySymbol.split('.')[1]) : ""}
                  sx={styles.boxContainerGeneral}
                  onMouseEnter={getTooltip}
                >
                  {row?.totalTrxAmt ? withCurrencySymbol : ""}
                </Box>
              </TableCell>
              </>}
              <TableCell tabIndex={0} sx={{...styles.tableCell}}>
                <Box sx={styles.boxContainerGeneral} onMouseEnter={getTooltip}>
                  {formatDateToLocal(row.createdAt ?? '', LONG_DATE_8HR_FORMAT, AMERICAN_DATE_8HR_FORMAT)}
                </Box>
              </TableCell>
              <TableCell sx={{...styles.centerAlignedText, ...styles.tableCell}}>
                <Box sx={(isRemappable || tabIndex === TABS.findIndex(tab => tab.label === 'Archived')) ? styles.actionGroup : styles.actionGroupEnd}>
                  {isFileParsing && isRemappable &&
                  <Box sx={styles.loaderContainer}>
                    <CircularProgress size={20} />
                  </Box>
                  }
                  {!isFileParsing && isRemappable && tabIndex === TABS.findIndex(tab => tab.label === 'Imported') &&
                  <Tooltip title='Remap the File'>
                    <IconButton aria-label='Mapping icon' sx={styles.actionsIconButton} onClick={() => checkPermission(handleRemapping, PERMISSIONS.IMPORT_FILE, row)}>
                      <img height={22} src={DataMapIcon} alt="data-map-icon" aria-label='Data Map icon' />
                    </IconButton>
                  </Tooltip>
                  }
                  { downloadFile &&
                  <Tooltip title='Download the File'>
                    <IconButton aria-label='Download icon' sx={styles.actionsIconButton} onClick={() => checkPermission(handleDownloadFile, PERMISSIONS.DOWNLOAD_FILE ,row)}>
                      <FileDownloadOutlinedIcon sx={styles.actionsIcon} />
                    </IconButton>
                  </Tooltip>
                  }
                  {(tabIndex === TABS.findIndex(tab => tab.label === 'Imported') && archiveFile ) &&
                  <Tooltip title='Archive the File'>
                    <IconButton aria-label='Archive icon' sx={styles.actionsIconButton} onClick={() => checkPermission(handleArchive, PERMISSIONS.ARCHIVE_FILE ,row)}>
                      <Inventory2OutlinedIcon sx={styles.actionsIcon} />
                    </IconButton>
                  </Tooltip>}
                </Box>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    )
  };

  /**
   * This function returns the empty verbiage based on the tab selected (Imported/Archived)
   * @returns An empty verbiage.
   */
  const getEmptyBody = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={isUltimateParent ? 6 : 8} sx={styles.tableCellEmpty}>
            <Box sx={styles.verbiageContainer}>
              {getEmptyVerbiage()}
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  };


  const getEmptyVerbiage = () => {
    if (searched) {
      return (<Typography tabIndex={0} sx={styles.verbiage}>There are no records matching the criteria.</Typography>);
    } else if (tabIndex === TABS.findIndex(tab => tab.label === 'Imported')) {
      return (<Typography tabIndex={0} sx={styles.verbiage}>There are no Imported Files.</Typography>);
    } else {
      return (<Typography tabIndex={0} sx={styles.verbiage}>There are no Archived Files.</Typography>);
    }
  };

	return(
    <>
      <Container maxWidth='xl'>
        <Box sx={styles.comboBoxStyle}>
          <Grid container columnSpacing={1} rowSpacing={2} sx={styles.filterGridContainer}>
            <Grid item xs={10.5} md={2.5} sx={styles.searchStyle}>
              <TextField
                id={`searchField`}
                inputProps={{ 'aria-label': 'Search Field'}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setSearchQuery(e.target.value)}
                value={searchQuery}
                placeholder={PLACEHOLDERS.SEARCH_BAR}
                size='small'
                sx={styles.searchField}
              />
            </Grid>
            {!isUltimateParent &&
            <>
            <Grid item xs={10} md={2.5} sx={styles.filterChildBoxMargin}>
              <Typography tabIndex={0} component='label' htmlFor='combo-box-collateral' sx={styles.dropdownTitle}>
                Collateral
              </Typography>
              <Autocomplete
                blurOnSelect
                disablePortal
                id='combo-box-collateral'
                options={['All', ...collaterals]}
                size='small'
                fullWidth
                sx={styles.comboBox}
                renderInput={(params: AutocompleteRenderInputParams) => (<TextField {...params} placeholder='Select Collateral' />)}
                value={collateral}
                inputValue={collateralInput}
                onChange={(_event: any, newValue: string | null) => {
                  setCollateral(newValue);
                  setPage(0);
                }}
                onInputChange={(_event: any, newInputValue: string) => setCollateralInput(newInputValue)}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                }
              />
            </Grid>
            <Grid item xs={10} md={2.5} sx={styles.filterChildBoxMargin}>
              <Typography tabIndex={0} component='label' htmlFor='combo-box-bbperiod' sx={styles.dropdownTitle}>
                As of Date
              </Typography>
              <Autocomplete
                blurOnSelect
                disablePortal
                id='combo-box-bbperiod'
                options={['All', ...asOfDates]}
                size='small'
                fullWidth
                sx={styles.comboBox}
                renderInput={(params: AutocompleteRenderInputParams) => (<TextField {...params} placeholder='Select As of Date' />)}
                value={asOfDate}
                inputValue={asOfDatesInput}
                onChange={(_event: any, newValue: string | null) => {
                  setAsOfDate(newValue);
                  setPage(0);
                }}
                onInputChange={(_event: any, newInputValue: string) => setAsOfDatesInput(newInputValue)}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                }
              />
            </Grid>
            </>}
            <Grid item xs={10} md={2.5} sx={styles.filterChildBoxMargin}>
              <Typography tabIndex={0} component='label' htmlFor='combo-box-doc-type' sx={styles.dropdownTitle}>
                Document Type
              </Typography>
              <Autocomplete
                blurOnSelect
                disablePortal
                id='combo-box-doc-type'
                options={['All', ...documentTypes]}
                size='small'
                fullWidth
                sx={styles.comboBox}
                renderInput={(params: AutocompleteRenderInputParams) => (<TextField {...params} placeholder='Select Document Type' />)}
                value={documentType}
                inputValue={documentInput}
                onChange={(_event: any, newValue: string | null) => {
                  setDocumentType(newValue);
                  setPage(0);
                }}
                onInputChange={(_event: any, newInputValue: string) => setDocumentInput(newInputValue)}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
                  clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
                }
              />
            </Grid>
          </Grid>
          { importFile &&
          <Grid sx={styles.searchStyle}>
            <Button
              disabled={!props.client}
              variant='contained'
              color='primary'
              aria-label='Upload File- Link to the Upload Page'
              onClick={() => checkPermission(handleUpload, PERMISSIONS.IMPORT_FILE, props.client)}
            >
              <Typography variant='button' sx={styles.uploadFileStyle}>
                + Upload New File
              </Typography>
            </Button>
            <FormHelperText sx={{...styles.errorText, ...(enableUploading && styles.hidden)}}>Please select a client first.</FormHelperText>
          </Grid>
          }
        </Box>
      </Container>
      {importFile &&
      <Box sx={{...styles.alertContainer, ...(!hasUnmappedFiles && styles.hidden)}}>
        <ErrorOutlineOutlinedIcon tabIndex={0} sx={styles.alertIcon}></ErrorOutlineOutlinedIcon>
        <Box sx={styles.alertContent}>
          <Typography variant='body2' tabIndex={0}>
            You have unmapped files.
          </Typography>
          <Typography tabIndex={0} variant='body2' sx={styles.alertButton} onClick={() => checkPermission(navigateToDataMapping, PERMISSIONS.IMPORT_FILE)}>
            Go to Data Map
            <ArrowForwardIosIcon fontSize='inherit' sx={styles.alertArrow}/>
          </Typography>
        </Box>
      </Box>
      }
      <Grid container sx={styles.gridContainer}>
        <Grid item xs={12} sx={styles.tableGroup}>
          <Box sx={styles.outmostContainer}>
            <Box sx={styles.blockBox}>
              <Tabs sx={styles.buttonTabs} value={tabIndex} onChange={handleTabChange}>
                {TABS.map((tab) => <Tab tabIndex={0} label={tab.label} key={tab.label} /> )}
              </Tabs>
            </Box>
            <TableContainer sx={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow sx={styles.tableHeadRow}>
                    <TableCell colSpan={2} sx={styles.tableWidthFileName}>
                      <FormLabel tabIndex={0} htmlFor='file-name' sx={{...styles.tableHeaderText, ...styles.tableHeaderTextFileName}}>
                        File Name
                      </FormLabel>
                    </TableCell>
                    {isUltimateParent &&
                    <TableCell sx={{...styles.tableWidthUpcGeneral}}>
                      <FormLabel tabIndex={0} htmlFor='sheet-name' sx={styles.tableHeaderText}>
                        Sheet Name
                      </FormLabel>
                    </TableCell>}
                    <TableCell sx={isUltimateParent ? {...styles.tableWidthUpcGeneral} : {...styles.tableWidthGeneral}}>
                      <FormLabel tabIndex={0} htmlFor='document-type' sx={styles.tableHeaderText}>
                        Document Type
                      </FormLabel>
                    </TableCell>
                    {!isUltimateParent &&
                    <>
                    <TableCell sx={{...styles.tableWidthGeneral}}>
                      <FormLabel tabIndex={0} htmlFor='collateral' sx={styles.tableHeaderText}>
                        Collateral
                      </FormLabel>
                    </TableCell>
                    <TableCell sx={{...styles.tableWidthGeneral}}>
                      <FormLabel tabIndex={0} htmlFor='as-of-date' sx={styles.tableHeaderText}>
                        As of Date
                      </FormLabel>
                    </TableCell>
                    <TableCell sx={{...styles.rightAlignedText, ...styles.tableWidthGeneral}}>
                      <FormLabel tabIndex={0} htmlFor='total-amount' sx={styles.tableHeaderText}>
                        Total Amount
                      </FormLabel>
                    </TableCell>
                    </>}
                    <TableCell sx={isUltimateParent ? {...styles.tableWidthUpcGeneral} : {...styles.tableWidthGeneral}}>
                      <FormLabel tabIndex={0} htmlFor='uploaded-date' sx={styles.tableHeaderText}>
                        Uploaded Date
                      </FormLabel>
                    </TableCell>
                    <TableCell sx={{...styles.centerAlignedText, ...styles.tableWidthAction}}>
                      <FormLabel tabIndex={0} htmlFor='action' sx={styles.tableHeaderText}>
                        Actions
                      </FormLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {getTableBody(props.client,
                              getFilteredRows(searched, filteredRows, importedFiles, archivedFiles, tabIndex),
                              page,
                              rowsPerPage)}
              </Table>
            </TableContainer>
          </Box>
          <TablePagination
            component='div'
            count={getFilteredRows(searched, filteredRows, importedFiles, archivedFiles, tabIndex).length}
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(DEFAULT_PAGE);
            }}
          />
        </Grid>
      </Grid>
      <Toaster
        open={toasterState.open}
        message={toasterState.message}
        severity={toasterState.severity}
        onCloseChange={handleToasterClose}
      />
      <WarningModal
        open={warningModalOpen}
        onClose={() => setWarningModalOpen(false)}
        {...getIssueTypeAndMessage(selectedFile, documentIssues)}
        displayOnly
      />
      <ConfirmModal
        title={`${PROMPT.ARCHIVE_PROMPT.title} ${selectedFile ? selectedFile.filename : null}`}
        description={PROMPT.ARCHIVE_PROMPT.description}
        open={isModalOpen}
        alignment='left'
        onClose={handleModalClose}
        onConfirm={() => checkPermission(handleArchiveFile, PERMISSIONS.ARCHIVE_FILE, selectedFile, importedFiles, archivedFiles)}
        noButtonText={PROMPT.ARCHIVE_PROMPT.cancel}
        yesButtonText={PROMPT.ARCHIVE_PROMPT.archive}
      />
      {selectedFile && 
        <MappingModal
          open={dataMapModalOpen}
          handleClose={() => setDataMapModalOpen(false)}
          requiredFields={optionalAndRequiredFields.requiredFields}
          optionalFields={optionalAndRequiredFields.optionalFields}
          uploadedFile={selectedFile}
          showToaster={showToaster}
          setSelectedFile={setSelectedFile}
          importedFiles={importedFiles}
          setImportedFiles={setImportedFiles}
          filteredRows={filteredRows}
          setFilteredRows={setFilteredRows}
        />}
    </>
	);
};

export default FileImportTable;
