import { Form, FormikProps } from "formik";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import styles from "./styles";
import { IPermission, IPermissionItem, IRole } from "../../../../interfaces/rolesPermissionInterface";
import { FC, useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PERMISSIONS } from "../../../../utility/constants";

interface IProps {
  permissionList: IPermission[],
  index: number,
  isEditing: boolean,
  formik: FormikProps<{roleList : IRole[]}>
}
/**
 * Component for showing the contents of the permissions box.
 * @param props The props for the PermissionBox component. See the IProps interface for more information.
 */
const PermissionBox: FC<IProps> = (props) => {
  const {permissionList, index, isEditing, formik} = props;
  const [expanded, setExpanded]                    = useState<string[]>([]);
  const [viewScope, setViewScope]                  = useState<string>(PERMISSIONS.VIEW_CLIENT)
  
  /**
   * This useEffect hook is responsible for setting the default view scope state.
   * The view scope state dictates whether the View Client permission affects all clients or assigned clients only.
   */
  useEffect(() => {
    let permission: string;
    const role = formik.values.roleList[index];

    if (role !== undefined) {
      if (role.permissions.includes(PERMISSIONS.VIEW_CLIENT)) {
        permission = PERMISSIONS.VIEW_CLIENT;
      } else if (role.permissions.includes(PERMISSIONS.VIEW_ASSIGNED_CLIENT)) {
        permission = PERMISSIONS.VIEW_ASSIGNED_CLIENT;
      } else {
        permission = PERMISSIONS.VIEW_CLIENT;
      }
    } else {
      permission = PERMISSIONS.VIEW_CLIENT;
    }

    setViewScope(permission)
  }, [formik.values.roleList[index]]);


/**
 * This function retrieves the checked status of the select all checkbox of a permission group.
 * @param formik This is the formik object which contains the values of the form.
 * @param index The index of the permission.
 * @param permissionName The name of the permission header.
 * @returns A boolean value which dictates the checked status.
 */
  const getAllPermissionChecked = (formik : FormikProps<{roleList : IRole[]}>, index: number, permissionName : string) => {
    if (formik.values.roleList.length > 0) {
      const permissions : IPermission[] = permissionList.filter(permission => permission.permissionName === permissionName); 
      const permissionsToBeChecked = permissions[0].permissions.map((permissionItem) => {
        return permissionItem.value;
      }) 
      if (formik.values.roleList[index] !== undefined) {
        if (permissionName === 'Clients') {
          const tempPermissionsToBeChecked = [...permissionsToBeChecked];
          const viewClientIndex = tempPermissionsToBeChecked.indexOf(PERMISSIONS.VIEW_CLIENT);
          tempPermissionsToBeChecked.splice(viewClientIndex, 1);
          tempPermissionsToBeChecked.push(viewScope);
          let array = formik.values.roleList[index].permissions;
          let containsAll = tempPermissionsToBeChecked.every(item => array.includes(item))
          return containsAll;
        } else {
          let array = formik.values.roleList[index].permissions;
          let containsAll = permissionsToBeChecked.every(item => array.includes(item))
          return containsAll;
        }
      } else {
        return false;
      }
    } else {
      return false;
    } 
  }

  /**
 * This function retrieves the indeterminate status of the select all checkbox of a permission group.
 * @param formik This is the formik object which contains the values of the form.
 * @param index The index of the permission.
 * @param permissionName The name of the permission header.
 * @returns A boolean value which dictates the indeterminate status.
 */
  const checkIndeterminate = (formik : FormikProps<{roleList : IRole[]}>, index: number, permissionName : string) => {
    if (formik.values.roleList.length > 0 && formik.values.roleList[index] !== undefined) {
      const permissions : IPermission[] = permissionList.filter(permission => permission.permissionName === permissionName); 
      const permissionsToBeChecked = permissions[0].permissions.map(permissionItem => permissionItem.value);
      let array = formik.values.roleList[index].permissions;
      let containsAll = permissionsToBeChecked.every(item => array.includes(item))
      const tempPermissionsToBeChecked = [...permissionsToBeChecked];
      const viewClientIndex = tempPermissionsToBeChecked.indexOf(PERMISSIONS.VIEW_CLIENT);
      tempPermissionsToBeChecked.splice(viewClientIndex, 1);
      tempPermissionsToBeChecked.push(viewScope);

      if (permissionName === 'Clients') {
        containsAll = tempPermissionsToBeChecked.every(item => array.includes(item))
      }

      if (containsAll) {
        return false;
      } else {
        let containsSome = permissionsToBeChecked.some(item => array.includes(item))

        if (permissionName === 'Clients') {
          containsSome = tempPermissionsToBeChecked.some(item => array.includes(item))
        }

        return containsSome;
      }
    } else {
      return false;
    }
  }

  /**
   * This function retrieves the checked status of the select all checkbox of a permission.
   * @param formik This is the formik object which contains the values of the form.
   * @param permissionValue The name of the permission.
   * @param index The index of the permission.
   * @returns A boolean value which dictates the checked status.
   */
  const getPermissionChecked = (formik : FormikProps<{roleList : IRole[]}>, permissionValue : string, index: number) => {
    if (formik.values.roleList.length > 0 && formik.values.roleList[index] !== undefined) {
      const foundPermission = formik.values.roleList[index].permissions.filter(permission => permission === permissionValue);
      const checked = foundPermission.length > 0;
      return checked;
    } else {
      return false;
    }
  }

  /**
   * This function handles the checking/unchecking logic of a permission.
   * @param formik This is the formik object which contains the values of the form.
   * @param permissionItemName The name of the permission to be checked/unchecked.
   * @param checked The checked status.
   * @param index The index of the permission.
   * @param permission The name of the permission group.
   */
  const handleCheckPermission = (formik : FormikProps<{roleList : IRole[]}>, permissionItemName : string, checked : boolean, index: number, permission : IPermission) => {
    const permissionIndex = permission.permissions.findIndex((permissionItem) => permissionItem.value === permissionItemName);
    
    if (checked) {
      handleChecked(formik, permission, permissionIndex, permissionItemName, index);
    } else {
      handleUnchecked(formik, permission, permissionIndex, permissionItemName, index);
    }
  }

  /**
   * This function handles the action to be done when the status is checked
   * @param formik This is the formik object which contains the values of the form.
   * @param permission The name of the permission group.
   * @param permissionIndex The index if the permission item value.
   * @param permissionItemName The name of the permission to be checked/unchecked.
   * @param index The index of the permission.
   */
  const handleChecked = (formik : FormikProps<{roleList : IRole[]}>, permission : IPermission, permissionIndex : number, permissionItemName : string, index : number) => {
    const includesViewPermission = formik.values.roleList[index].permissions.includes(permission.permissions[0].value);
    const includesViewClientsPermission = formik.values.roleList[index].permissions.includes(viewScope);
    const isClientSubpermission = permission.permissionName !== 'Clients' && permission.permissionName !== 'Roles and Permissions' && permission.permissionName !== 'Lender Settings';

    const valuesToBeAdded : string[] = [];
    if ((permissionIndex !== 0 && permissionItemName !== PERMISSIONS.VIEW_ASSIGNED_CLIENT) && !includesViewPermission) {
      if (permission.permissionName === 'Clients') {
        if (!includesViewClientsPermission) {
          const permissionsToBeAdded = [viewScope, permissionItemName];
          permissionsToBeAdded.forEach(item => valuesToBeAdded.push(item))
        } else {
          const permissionsToBeAdded = [permissionItemName];
          permissionsToBeAdded.forEach(item => valuesToBeAdded.push(item))
        }
      } else {
        const permissionsToBeAdded = [permission.permissions[0].value, permissionItemName];
        permissionsToBeAdded.forEach(item => valuesToBeAdded.push(item))
      } 
    } else {
      valuesToBeAdded.push(permissionItemName)
    }

    if (isClientSubpermission) {
      if (!includesViewClientsPermission) {
        valuesToBeAdded.push(viewScope)
      }
    }
    formik.setFieldValue(`roleList[${index}].permissions`, [...formik.values.roleList[index].permissions, ...valuesToBeAdded])
  }

  /**
   * This function handles the action to be done when the status is not checked
   * @param formik This is the formik object which contains the values of the form.
   * @param permission The name of the permission group.
   * @param permissionIndex The index if the permission item value.
   * @param permissionItemName The name of the permission to be checked/unchecked.
   * @param index The index of the permission.
   */
  const handleUnchecked = (formik : FormikProps<{roleList : IRole[]}>, permission : IPermission, permissionIndex : number, permissionItemName : string, index : number) => {
    const permissions : IPermission[] = permissionList.filter(permissionListItem => permissionListItem.permissionName === permission.permissionName); 
    const clientSubpermissions = permissionList.filter(permission => permission.permissionName !== 'Clients' && permission.permissionName !== 'Roles and Permissions' && permission.permissionName !== 'Lender Settings')
    const permissionsToBeChecked = permissions[0].permissions.map((permissionItem) => permissionItem.value);

    if (permissionItemName === viewScope) {
      let array = formik.values.roleList[index].permissions;
      const subpermissionValues = clientSubpermissions.map(permission => permission.permissions.map(item => item.value));
      let filteredArray = array.filter(item => {
        return !subpermissionValues.flat().includes(item);
      });
      filteredArray = filteredArray.filter(item => !permissionList[0].permissions.map(item => item.value).includes(item))
      filteredArray = filteredArray.filter(item => item !== PERMISSIONS.VIEW_ASSIGNED_CLIENT);
      formik.setFieldValue(`roleList[${index}].permissions`, filteredArray);
    } else if (permissionIndex === 0) {
      let array = formik.values.roleList[index].permissions;
      let filteredArray = array.filter(item => {
        return !permissionsToBeChecked.includes(item);
      })
      formik.setFieldValue(`roleList[${index}].permissions`, filteredArray);
    } else {
      let permissions = formik.values.roleList[index].permissions;
      const currentPermissionIndex = permissions.indexOf(permissionItemName);
      if (currentPermissionIndex > -1) {
        permissions.splice(currentPermissionIndex, 1);
        formik.setFieldValue(`roleList[${index}].permissions`, [...permissions]);
      }
    }
  }

  /**
   * This function handles the checking/unchecking logic of a permission group.
   * @param formik This is the formik object which contains the values of the form.
   * @param permissionName The name of the permission group.
   * @param checked The checked status.
   * @param index The index of the permission.
   */
  const handleCheckAll = (formik : FormikProps<{roleList : IRole[]}>, permissionName : string, checked : boolean, index: number) => {
    const permissions : IPermission[] = permissionList.filter(permission => permission.permissionName === permissionName); 
    const clientSubpermissions = permissionList.filter(permission => permission.permissionName !== 'Clients' && permission.permissionName !== 'Roles and Permissions' && permission.permissionName !== 'Lender Settings')
    const permissionsToBeChecked = permissions[0].permissions.map((permissionItem, index) => {
      return permissionItem.value;
    }) 
    if (checked) {
      if (permissionName === 'Clients') {
        const tempPermissionsToBeChecked = [...permissionsToBeChecked];
        const viewClientIndex = tempPermissionsToBeChecked.indexOf(PERMISSIONS.VIEW_CLIENT);
        tempPermissionsToBeChecked.splice(viewClientIndex, 1);
        tempPermissionsToBeChecked.push(viewScope);
        let array = [...formik.values.roleList[index].permissions, ...tempPermissionsToBeChecked];
        let uniquePermissions = Array.from(new Set(array));
        formik.setFieldValue(`roleList[${index}].permissions`, uniquePermissions);
      } else {
        let array = [...formik.values.roleList[index].permissions, ...permissionsToBeChecked];
        let uniquePermissions = Array.from(new Set(array));
        formik.setFieldValue(`roleList[${index}].permissions`, uniquePermissions);
      }
    } else if (permissionName === 'Clients') {
      let array = formik.values.roleList[index].permissions;
      const subpermissionValues = clientSubpermissions.map(permission => permission.permissions.map(item => item.value));
      let filteredArray = array.filter(item => {
        return !subpermissionValues.flat().includes(item);
      });
      filteredArray = filteredArray.filter(item => !permissionList[0].permissions.map(item => item.value).includes(item))
      filteredArray = filteredArray.filter(item => item !== PERMISSIONS.VIEW_ASSIGNED_CLIENT);
      formik.setFieldValue(`roleList[${index}].permissions`, filteredArray);
    } else {
      let array = formik.values.roleList[index].permissions;
      let filteredArray = array.filter(item => {
        return !permissionsToBeChecked.includes(item);
      })
      formik.setFieldValue(`roleList[${index}].permissions`, filteredArray);
    }
  }

  /**
   * This function handles the expanding of an accordion panel.
   * @param panel The panel name of an accordion.
   */
  const handleExpand = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    let target = event.target as HTMLInputElement;
    if (target.localName !== 'input' && target.localName !== 'p' && target.localName !== 'span') {
      if (expanded.includes(panel)) {
        let tempExpanded = [...expanded];
        const index = tempExpanded.indexOf(panel);
        tempExpanded.splice(index, 1);
        setExpanded(tempExpanded);
      } else {
        let tempExpanded = [...expanded];
        tempExpanded.push(panel);
        setExpanded(tempExpanded)
      }
    }
  };

  /**
   * This function handles the checking of the checked status of the view client permission 
   * since the value of the permission differs depending on the view scope.
   * @param permissionItem The name of the permission.
   * @returns A boolean value for checking the checked status of the view client permission.
   */
  const checkClientView = (permissionItem : IPermissionItem) => {
    if (permissionItem.value === PERMISSIONS.VIEW_CLIENT) {
      return getPermissionChecked(formik, viewScope, index);
    } else {
      return getPermissionChecked(formik, permissionItem.value, index);
    }
  };

  /**
   * This function handles the changing of the view scope of the role.
   * @param event The change event.
   * @param value The value of the view scope.
   */
  const handleChangeViewScope = (event: React.ChangeEvent<HTMLInputElement>, value : string) => {
    const hasViewClientPermission = formik.values.roleList[index].permissions.includes(PERMISSIONS.VIEW_CLIENT);
    const hasViewAssignedClientPermission = formik.values.roleList[index].permissions.includes(PERMISSIONS.VIEW_ASSIGNED_CLIENT);
    if (hasViewClientPermission && value === PERMISSIONS.VIEW_ASSIGNED_CLIENT) {
      const permissionArray = [...formik.values.roleList[index].permissions];
      const permissionIndex = permissionArray.indexOf(PERMISSIONS.VIEW_CLIENT);
      permissionArray.splice(permissionIndex, 1);
      permissionArray.push(PERMISSIONS.VIEW_ASSIGNED_CLIENT);
      formik.setFieldValue(`roleList[${index}].permissions`, permissionArray);
    }
    if (hasViewAssignedClientPermission && value === PERMISSIONS.VIEW_CLIENT) {
      const permissionArray = [...formik.values.roleList[index].permissions];
      const permissionIndex = permissionArray.indexOf(PERMISSIONS.VIEW_ASSIGNED_CLIENT);
      permissionArray.splice(permissionIndex, 1);
      permissionArray.push(PERMISSIONS.VIEW_CLIENT);
      formik.setFieldValue(`roleList[${index}].permissions`, permissionArray);
    }
    setViewScope(value);
  }

  /**
   * This function returns the checked status of the view client permission.
   * @returns A boolean value that is true when the view client permission is checked.
   */
  const checkIfViewClientChecked = () => {
    if (getPermissionChecked(formik, PERMISSIONS.VIEW_CLIENT, index) || getPermissionChecked(formik, PERMISSIONS.VIEW_ASSIGNED_CLIENT, index)) {
      return false;
    } else {
      return true;
    }
  } 

  /**
   * This function returns the rendered permission group.
   * @param idx The index of the permission group.
   * @param permission The permission group.
   * @param formik This is the formik object which contains the values of the form.
   * @returns The rendered permission group.
   */
  const getPermissionBox = (idx: number, permission: IPermission, formik : FormikProps<{roleList : IRole[]}>) => {
    return(
        <Box key={idx} sx={styles.permissionBox}>
          <Accordion
            expanded={expanded.includes(permission.permissionName)}
            onChange={handleExpand(permission.permissionName)}
            sx={styles.accordionBox}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={styles.accordionHeader}>
              <Grid container sx={{...styles.gridPermission}}>
                <FormControlLabel 
                  disabled={!isEditing}
                  control={<Checkbox size='small' indeterminate={checkIndeterminate(formik, index, permission.permissionName)} sx={styles.headerCheckBox}/>} 
                  label={<Typography sx={{...styles.permissionLabel, ...styles.headerRoleLabel}}>{permission.permissionName}</Typography>}
                  checked={getAllPermissionChecked(formik, index, permission.permissionName)}
                  onChange={(event, checked) => {
                    handleCheckAll(formik, permission.permissionName, checked, index);
                  }}
                />
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={styles.permissionContainer}>
                {permission.permissions.map((permissionItem, idx2) =>
                <Box key={permissionItem.id} sx={styles.permissionList}>
                  <FormControlLabel 
                    disabled={!isEditing}
                    control={<Checkbox size='small' sx={styles.checkBox}/>} 
                    label={<Typography sx={styles.permissionLabel}>{permissionItem.name}</Typography>}
                    checked={getPermissionChecked(formik, permissionItem.value, index)}
                    onChange={(_event, checked) => handleCheckPermission(formik, permissionItem.value, checked, index, permission)}
                  />
                </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
    );
  }

  /**
   * This function returns the rendered client permission group.
   * @param permissionList The list of permissions.
   * @param formik This is the formik object which contains the values of the form.
   * @returns The rendered client permission group.
   */
  const getClientPermissionBox = (permissionList : IPermission[], formik : FormikProps<{roleList : IRole[]}>) => {
    return (
      <Box key={'client-permission'} sx={{...styles.permissionBox, ...styles.clientsPermissionBox}}>
        <Accordion
          expanded={expanded.includes(permissionList[0].permissionName)}
          onChange={handleExpand(permissionList[0].permissionName)}
          sx={{...styles.accordionBox, ...styles.clientsAccordionBox}}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={styles.accordionHeader}>
            <Grid container sx={{...styles.gridPermission}}>
              <FormControlLabel 
                disabled={!isEditing}
                control={<Checkbox size='small' indeterminate={checkIndeterminate(formik, index, permissionList[0].permissionName)} sx={styles.headerCheckBox}/>} 
                label={<Typography sx={{...styles.permissionLabel, ...styles.headerRoleLabel}}>{permissionList[0].permissionName}</Typography>}
                checked={getAllPermissionChecked(formik, index, permissionList[0].permissionName)}
                onChange={(event, checked) => {
                  handleCheckAll(formik, permissionList[0].permissionName, checked, index);
                }}
              />
              <FormControl disabled={!isEditing || checkIfViewClientChecked()}>
                <RadioGroup
                  aria-labelledby="radio-buttons-clients-label"
                  name="radio-buttons-group"
                  sx={styles.radioGroupBox}
                  onChange={handleChangeViewScope}
                  value={viewScope}
                >
                  <FormControlLabel value={PERMISSIONS.VIEW_CLIENT} control={<Radio size='small' />} label="All" />
                  <FormControlLabel value={PERMISSIONS.VIEW_ASSIGNED_CLIENT} control={<Radio size='small' />} label="Assigned" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={styles.permissionContainer}>
              {permissionList[0].permissions.map((permissionItem, idx2) =>
              <Box key={permissionItem.id} sx={styles.permissionList}>
                <FormControlLabel 
                  disabled={!isEditing}
                  control={<Checkbox size='small' sx={styles.checkBox}/>} 
                  label={<Typography sx={styles.permissionLabel}>{permissionItem.name}</Typography>}
                  checked={checkClientView(permissionItem)}
                  onChange={(event, checked) => {
                    if (permissionItem.value === PERMISSIONS.VIEW_CLIENT) {
                      handleCheckPermission(formik, viewScope, checked, index, permissionList[0])
                    } else {
                      handleCheckPermission(formik, permissionItem.value, checked, index, permissionList[0]);
                    }
                  }}
                />
              </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
        {permissionList.slice(1,14).map((permission, idx) => getClientSubpermissions(idx, permission, formik))}
      </Box>
    )
  }

  /**
   * This function renders the subpermissions of a permission group.
   * @param idx The index of the permission.
   * @param permission The permission group.
   * @param formik This is the formik object which contains the values of the form.
   * @returns The subpermissions of a permission group.
   */
  const getClientSubpermissions = (idx: number, permission: IPermission, formik : FormikProps<{roleList : IRole[]}>) => {
    return (
      <Box key={idx} sx={styles.subPermissionBox}>
        <Accordion
          expanded={expanded.includes(permission.permissionName)}
          onChange={handleExpand(permission.permissionName)}
          sx={styles.accordionBox}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={styles.accordionHeader}>
            <Grid container sx={{...styles.gridPermission}}>
              <FormControlLabel 
                disabled={!isEditing}
                control={<Checkbox size='small' indeterminate={checkIndeterminate(formik, index, permission.permissionName)} sx={styles.headerCheckBox}/>} 
                label={<Typography sx={{...styles.permissionLabel, ...styles.headerRoleLabel}}>{permission.permissionName}</Typography>}
                checked={getAllPermissionChecked(formik, index, permission.permissionName)}
                onChange={(event, checked) => {
                  handleCheckAll(formik, permission.permissionName, checked, index);
                }}
              />
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={styles.permissionContainer}>
              {permission.permissions.map((permissionItem, idx2) =>
              <Box key={permissionItem.id} sx={styles.permissionList}>
                <FormControlLabel 
                  disabled={!isEditing}
                  control={<Checkbox size='small' sx={styles.checkBox}/>} 
                  label={<Typography sx={styles.permissionLabel}>{permissionItem.name}</Typography>}
                  checked={getPermissionChecked(formik, permissionItem.value, index)}
                  onChange={(event, checked) => handleCheckPermission(formik, permissionItem.value, checked, index, permission)}
                />
              </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  return (
    <Form>
      <Box>
        <Box sx={styles.permissionParentContainer}>
          <Box sx={styles.permissionChildContainer}>
            {getClientPermissionBox(permissionList.slice(0,14), formik)}
          </Box>
          <Box sx={styles.permissionChildContainer}>
            {permissionList.slice(14,16).map((permission, idx) => getPermissionBox(idx, permission, formik))}  
          </Box>
        </Box>
      </Box>
    </Form>
  )
}

export default PermissionBox;