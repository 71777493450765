import * as React from 'react';
import { IconButton, TablePagination, Typography } from '@mui/material';
import {
  ClientSettingsPaginationContext,
  IClientSettingsPaginationContext
} from '../../../../../context/clientSettingsPaginationContext';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import styles from './styles';
import DisabledComponentsContainer from '../../../../common/disabled-components-container';

interface PaginationButtonProps {
  page                : number;
  handlePreviousClick : () => void;
  count               : number;
  rowsPerPage         : number;
  handleNextClick     : () => void
}

/**
 * This component is the label displayed rows for the range of the pagination.
 * @returns A component for the label display for the range of the pagination.
 */
export const getLabelDisplayedRows = () => ({ from, to, count}) => {
  return (
    <Typography component='span' tabIndex={0}>
      {`${from}-${to} of ${count}`}
    </Typography>
  );
};

/**
 * This component is the label display rows per page for table pagination.
 * @returns A component for the label display rows per page.
 */
export const getLabelRowsPerPage = () => (<Typography component='span' tabIndex={0}>Rows per page:</Typography>);

/**
 * This function returns the Previous Icon Button.
 * @param param0 The parameters of an Icon Button
 * @returns The Previous Icon Button with aria-label and components container.
 */
export const PreviousIconButton = ({ page, onClick }) => {
  const isDisabled = page === 0;

  return (
    <DisabledComponentsContainer isDisabled={isDisabled}>
      <IconButton 
        aria-label={isDisabled ? 'Previous page icon button disabled' : 'Previous page icon'} 
        data-testid={`previous-page-button`}
        onClick={onClick} 
        disabled={isDisabled} 
        sx={styles.previousPageIcon}
      >
        <KeyboardArrowLeft />
      </IconButton>
    </DisabledComponentsContainer>
  );
};

/**
 * This function returns the Next Icon Button.
 * @param param0 The parameters of an Icon Button
 * @returns The Next Icon Button with aria-label and components container.
 */
export const NextIconButton = ({ page, count, rowsPerPage, onClick }) => {
  const isDisabled = page === Math.ceil(count / rowsPerPage) - 1 || count === 0;

  return (
    <DisabledComponentsContainer isDisabled={isDisabled}>
      <IconButton 
        aria-label={isDisabled ? 'Next page icon button disabled' : 'Next page icon'} 
        data-testid={`next-page-button`}
        onClick={onClick} 
        disabled={isDisabled} 
        sx={styles.nextPageIcon}
      >
        <KeyboardArrowRight />
      </IconButton>
    </DisabledComponentsContainer>
  );
};

/**
 * This function gets the button component for the Pagination Buttons.
 * @param type The type of the Pagination Button. Either 'prev' or 'next'.
 * @param paginationProps The props for the pagination button of the table.
 * @returns A component for the Pagination buttons.
 */
const getPaginationButtonsComponent = (type: 'prev' | 'next', paginationProps: PaginationButtonProps) => {
  const { page, handlePreviousClick, count, rowsPerPage, handleNextClick } = paginationProps;

  if (type === 'prev') {
    return React.forwardRef(() => <PreviousIconButton page={page} onClick={handlePreviousClick} />);
  } else {
    return React.forwardRef(() => <NextIconButton page={page} count={count} rowsPerPage={rowsPerPage} onClick={handleNextClick} />);
  }
};

/**
 * This component is for the table pagination of the Client Settings.
 * @returns A component for the table pagination of the Client Settings.
 */
const ClientSettingsPagination = () => {
  const {
    pages,
    page,
    count,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
  } = React.useContext(ClientSettingsPaginationContext) as IClientSettingsPaginationContext;

  /**
   * This function handles the click of the previous button in the table pagination.
   */
  const handlePreviousClick = () => {
    changePage(null, page - 1);
  };

  /**
   * This function handles the click of the next button in the table pagination.
   */
  const handleNextClick = () => {
    changePage(null, page + 1);
  };

  return (
    <TablePagination
      component='div'
      rowsPerPageOptions={pages}
      colSpan={7}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={changePage}
      onRowsPerPageChange={changeRowsPerPage}
      labelDisplayedRows={getLabelDisplayedRows()}
      labelRowsPerPage={getLabelRowsPerPage()}
      backIconButtonProps={{component: getPaginationButtonsComponent('prev', { page, handlePreviousClick, count, rowsPerPage, handleNextClick })}}
      nextIconButtonProps={{component: getPaginationButtonsComponent('next', { page, handlePreviousClick, count, rowsPerPage, handleNextClick })}}
      SelectProps={{ 
        inputProps: {
          'aria-label': 'Expand below icon',
          'aria-labelledby': 'Expand below icon',
        },
        id:'expandBelowIcon'
       }}
    />
  );
}

export default ClientSettingsPagination;
