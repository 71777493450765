import { useState, ReactNode, SyntheticEvent, useContext, useEffect } from 'react';
import { Box, Typography, Tab, Tabs } from '@mui/material';
import LenderProfile from './profile';
import styles from './styles';
import LenderIneligibleCodesTable from './table';
import ConfirmModal from '../../components/modals/confirm-modal';
import LenderSettingsProvider, { LenderSettingsContext } from '../../context/lenderSettingsContext';
import { getPermissionsOfUser } from '../../utility/helper';
import { PERMISSIONS } from '../../utility/constants';
import { AuthContext } from '../../context/authContext';

interface TabPanelProps {
  children? : ReactNode;
  index     : number;
  value     : number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const mainTabsProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const LenderSettingsPage = () => {
  const { canViewLenderSettings, setCanViewLenderSettings,
          setCanUpdateCompanyInformation,
          setCanUploadCompanyLogo }                                     = useContext(LenderSettingsContext);
  const { state }                                                       = useContext(AuthContext);
  const [ value, setValue ]                                             = useState(0);
  const [ tempValue, setTempValue ]                                     = useState(0);
  const [ openModal,  setOpenModal ]                                    = useState<boolean>(false); 
  const [ dirty, setDirty ]                                             = useState<boolean>(false);

  useEffect(() => {
    if (state.token !== null) {
      getPermissions(state.token)
    }
  }, [state.token])

  const getPermissions = async (token : string) => {
    const permissions = await getPermissionsOfUser(state.uid, token);
    permissions.includes(PERMISSIONS.VIEW_LENDER_SETTINGS) ? setCanViewLenderSettings(true) : setCanViewLenderSettings(false);
    permissions.includes(PERMISSIONS.UPDATE_COMPANY_INFORMATION) ? setCanUpdateCompanyInformation(true) : setCanUpdateCompanyInformation(false);
    permissions.includes(PERMISSIONS.UPLOAD_COMPANY_LOGO) ? setCanUploadCompanyLogo(true) : setCanUploadCompanyLogo(false);
  };

  const mainTabHandler = (event: SyntheticEvent, newValue: number) => {
    if (dirty === true) {
      setOpenModal(true);
      setTempValue(newValue);
    } else {
      setValue(newValue);
    }
  };

  const handleClose = () => {
    setValue(tempValue);
    setOpenModal(false);
    setDirty(false);
  }

  return (
    <>
			<Box sx={{...styles.boxContainer1, ...(!canViewLenderSettings && styles.hidden)}}>
				<Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
          Lender Admin and Settings
				</Typography>
			</Box>
      <Box sx={{...styles.boxContainer2, ...(!canViewLenderSettings && styles.hidden)}}>
        <Box>
          <ConfirmModal
            open={openModal}
            onConfirm={() => setOpenModal(false)}
            onClose={() => handleClose()}
            onButtonClose={() => setOpenModal(false)}
            promptChecker={true}
            title={`Confirm Navigation`}
            description={`You have unsaved changes. Are you sure you want to leave this page?`}
            yesButtonText='Keep Editing'
            noButtonText='Discard Changes'
            confirmOnly
          />
          <Tabs sx={styles.buttonTabs} value={value} onChange={mainTabHandler} aria-label="Lender Settings Tabs">
            <Tab tabIndex={0} label="Lender Information" {...mainTabsProps(0)} />
            <Tab tabIndex={0} label="Ineligible Settings" {...mainTabsProps(1)} />
          </Tabs>
        </Box>
        <Box sx={styles.childTabHeader}>
          <TabPanel value={value} index={0}>
            <LenderProfile setDirty={setDirty}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LenderIneligibleCodesTable dirty={dirty} setDirty={setDirty}/>
          </TabPanel>
        </Box>
      </Box>
    </>
  )
}

const LenderSettings = () => {
  return (
    <LenderSettingsProvider>
      <LenderSettingsPage />
    </LenderSettingsProvider>
  )
}

export default LenderSettings;