import { Box, Button, Divider, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import styles from './styles';
import { ReactNode } from 'react';
import DisabledComponentsContainer from '../../common/disabled-components-container';

export interface IConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onButtonClose?: () => void;
  title: string;
  description: string;
  // error Button determines if Yes Button will be red
  errorButton?: boolean;
  yesButtonText?: string;
  disabledYesButton?: boolean;
  noButtonText?: string;
  promptChecker?: boolean;
  confirmOnly?: boolean;
  alignment? : string;
  children?  : ReactNode | ReactNode[];
  subtext?   : string
}

const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  onButtonClose,
  title,
  description,
  promptChecker = false,
  errorButton = false,
  yesButtonText = 'Yes',
  disabledYesButton = false,
  noButtonText = 'No',
  confirmOnly = false,
  alignment,
  children,
  subtext
}: IConfirmModalProps) => {

  const getAriaLabel = (yesButtonText: string, disabledYesButton: boolean): string => {
    if (disabledYesButton) {
      return `${yesButtonText} button disabled`;
    }
    switch (yesButtonText) {
      case 'Delete':
        return 'Delete';
      case 'Remove':
        return 'Remove';
      case 'Proceed to Import':
        return 'Proceed to Import';
      case 'Keep Editing':
        return 'Keep Editing';
      case 'Archive':
        return 'Archive';
      default:
        return '';
    }
  };

  return (
    <Modal open={open} onClose={promptChecker ? onButtonClose : onClose }>
      <Box sx={styles.modalBackground}>
        <Box sx={styles.headingContainer}>
          <Typography tabIndex={0} sx={styles.headingTitle}>{title}</Typography>
          <IconButton tabIndex={0} aria-label="Close icon" onClick={promptChecker? onButtonClose : onClose } data-testid='confirmation-modal-close'>
            <Close fontSize="small" sx={styles.closeButton} />
          </IconButton>
          
        </Box>
        <Divider variant="middle" sx={styles.divider} />
        <Box sx={{...styles.descriptionContainer, ...(alignment === 'left' && {justifyContent: 'flext-start'})}}>
          <Typography tabIndex={0} >{description}</Typography>
        </Box>
        {subtext &&
          <Box sx={{...styles.descriptionContainer, ...(alignment === 'left' && {justifyContent: 'flext-start'})}}>
            <Typography tabIndex={0} >{subtext}</Typography>
          </Box>
        }
        <Box tabIndex={0}>{children}</Box>
        <Divider variant="middle" sx={styles.divider} />
        <Box sx={styles.buttonContainer}>
          <Button variant="outlined" sx={styles.cancelButton} onClick={onClose} aria-label={noButtonText === 'Discard Changes' ? 'Discard Changes' : 'Cancel'} data-testid='confirmation-modal-cancel'>
            {noButtonText}
          </Button>
          <DisabledComponentsContainer isDisabled={disabledYesButton}>
            <Button
              disabled={disabledYesButton}
              variant="contained"
              aria-label={getAriaLabel(yesButtonText, disabledYesButton)}
              data-testid='confirmation-modal-confirm'
              sx={errorButton ? styles.errorButton : styles.primaryButton}
              onClick={() => {
                if (confirmOnly) {
                  onConfirm()
                } else {
                  onClose();
                  onConfirm();
                }
              }}
            >
              {yesButtonText}
            </Button>
          </DisabledComponentsContainer>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
