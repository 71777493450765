import { useState, useEffect, useContext, SyntheticEvent, FC, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlertColor, Box, Button, Container, Grid, Paper, Tooltip, Typography } from '@mui/material';
import ClientSettingsTabs from '../../components/client-settings/tabs';
import styles from './styles';
import ClientSettingsProvider from '../../context/clientSettingsContext';
import { SelectedClientContext } from '../../context/selectedClientContext';
import GeneralBreadcrumbs from '../../components/breadcrumb';
import CalculateIneligiblesButton from './calculate-ineligibles-button';
import CalculateBorrowingBaseButton from '../../components/client-settings/calculate-borrowing-base-button';
import images from '../../theme/images';
import { IComboBoxIds, IComboBoxSetStates, IOption } from '../../interfaces/comboBox';
import { getClientRequest, getLocalStorageItem, getOptions, getPermissionsOfUser } from '../../utility/helper';
import ComboBox from '../../components/common/combo-box';
import Toaster from '../../components/toaster';
import { PERMISSIONS } from '../../utility/constants';

interface IButton {
  name      : string;
  link      : string;
  ariaLabel : string;
};

const buttonNames: IButton[] = [
  { name: 'Ineligible Adjustments', link: 'ineligible-adjustments', ariaLabel: 'Link to the Ineligible Adjustments page' },
  { name: 'Loan Balances', link: 'loan-balances', ariaLabel: 'Link to the Loan Balances page' },
  { name: 'Reserves', link: 'reserves', ariaLabel: 'Link to the Reserves page' },
];

const ClientSettings: FC = () => {
  const { borrowerId }                                      = useParams();
  const navigate                                            = useNavigate();
  const selectedClientContext                               = useContext(SelectedClientContext);
  const [clients, setClients]                               = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient]                 = useState<IOption | null>(null);
  const [selectedTabIndex, setSelectedTabIndex]             = useState<number>(0);
  const [clientInput, setClientInput]                       = useState<string>('');
  const location:any                                        = useLocation();
  const [toasterOpen, setToasterOpen]                       = useState<boolean>(false);
  const [toasterMessage, setToasterMessage]                 = useState<string>('');
  const [toasterSeverity, setToasterSeverity]               = useState<AlertColor>('success');
  const [canViewClientSettings, setCanViewClientSettings]   = useState<boolean>(false);
  const [canCalculateBB, setCanCalculateBB]                 = useState<boolean>(false);
  const [canCalculateIneligible, setCanCalculateIneligible] = useState<boolean>(false);
  const [canViewLoanBalances, setCanViewLoanBalances]       = useState<boolean>(false);
  const [canViewReserves, setCanViewReserves]               = useState<boolean>(false);
  const [canViewIneligibleADJ, setCanViewIneligibleADJ]     = useState<boolean>(false);

  useEffect(() => {
    if(location?.state?.toast){
      setToasterOpen(true);
      setToasterMessage(`${location.state.getClientName} has been successfully saved!`);
      setToasterSeverity('success');
    }
    if (selectedClientContext?.selectedClient !== null && selectedClientContext?.selectedClient !== undefined) {
      const selectedClient : IOption = {
        recordId: selectedClientContext.selectedClient.recordId ? selectedClientContext.selectedClient.recordId : -1,
        label : selectedClientContext.selectedClient.borrowerName ? selectedClientContext.selectedClient.borrowerName : (
          selectedClientContext.selectedClient.label ?? ''),
        default : selectedClientContext.selectedClient.default ? selectedClientContext.selectedClient.default : false,
        parentClient: selectedClientContext.selectedClient.parentClient ? selectedClientContext.selectedClient.parentClient : false,
        parentClientFk: selectedClientContext.selectedClient.parentClientFk
      }
      setSelectedClient(selectedClient)
    };
    
    const params = { borrowerId: borrowerId ?? '0', invCollateralId: '0', bbPeriodId: '0', arCollateralId: '0', customerId: '0' };
    getOptions({ type: 'client', params, getSetStatesByType, setSelectedByType, mainRequest: getClientRequest()});
    getPermissions();
  }, [])

  useEffect(() => {
    if (borrowerId !== 'undefined' && borrowerId !== undefined) {
      localStorage.setItem('selectedClientRecordId', borrowerId);
      if (selectedClientContext?.selectedClient === null || selectedClientContext?.selectedClient === undefined) return
      navigate(`/clients/${selectedClientContext?.selectedClient?.recordId}/settings`)
    } else {
      navigate(`/clients/${selectedClientContext?.selectedClient?.recordId}/settings`)
    }
  }, [selectedClientContext?.selectedClient])

  useEffect(() => {
    if (selectedClient?.parentClient) {
      setSelectedTabIndex(0);
    }
  }, [selectedClient]);

  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanCalculateBB(permissions.includes(PERMISSIONS.CALCULATE_BB));
    setCanCalculateIneligible(permissions.includes(PERMISSIONS.CALCULATE_INELIGIBLE));
    setCanViewLoanBalances(permissions.includes(PERMISSIONS.VIEW_LOAN_BALANCES));
    setCanViewReserves(permissions.includes(PERMISSIONS.VIEW_RESERVES));
    setCanViewIneligibleADJ(permissions.includes(PERMISSIONS.VIEW_IA));
    setCanViewClientSettings(permissions.includes(PERMISSIONS.VIEW_CLIENT_SETTINGS));
  };

  const handleChange      = useCallback((e: SyntheticEvent<Element, Event>, newValue: IOption | null) => {
    return onChange(e, newValue);
  }, []);
  const handleInputChange = useCallback((_e: SyntheticEvent<Element, Event>, newInputValue: string) => {
    return setClientInput(newInputValue);
  }, []);

  const getSetStatesByType = (_type: IComboBoxIds) => {
    return { setOptions: setClients, setSelected: setSelectedClient, setInput: setClientInput };
  };

  const setSelectedByType = (selected: IOption, type: IComboBoxIds, setStates: IComboBoxSetStates) => {
    (type === 'client') && selectedClientContext?.setSelectedClient(selected);
    setStates.setSelected(selected);
    setStates.setInput(selected.label);
  };

  const onChange = (_event: SyntheticEvent<Element, Event>, newValue: IOption | null) => {
    const newContextValue = selectedClientContext.clients.find(client =>
      client.recordId === newValue?.recordId
    );
    selectedClientContext?.setSelectedClient(newContextValue ?? null);
    navigate(`/clients/${newValue?.recordId}/settings`)
    setSelectedClient(newValue);
  };

  const getBackgroundImage = (name: string) => {
    let img = '';

    switch (name) {
      case 'Ineligible Adjustments': img = images.IneligibleAdjustmentsIcon;
        break;
      case 'Loan Balances': img = images.LoanBalanceIcon;
        break;
      // changing last case to default to remove unreachable code
      default: img = images.ReservesIcon;
        break;
    }

    return { backgroundImage: `url(${img})` };
  }

  const getOnClick = (button: IButton) => {
    return { onClick: () => navigate(`${button.link}`) }
  }

  const handleTabChange = (newTabIndex) => {
    setSelectedTabIndex(newTabIndex);
  };

  return (
    <ClientSettingsProvider>
      <Box sx={{...styles.backgroundBox, ...(!canViewClientSettings && styles.hideButton)}}>
        <Box>
          <Box component="span" sx={styles.breadCrumbBox}>
            <Grid container sx={styles.headerContainer}>
              <Grid item xs={12} md={6} lg={8} xl={8.3}>
                <GeneralBreadcrumbs selectedText='Client Settings' breadcrumbLinks={[{ linkText: 'Clients', route: '/clients' }]} />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
                <Box sx={styles.clientBox}>
                  <ComboBox
                    id='client'
                    options={clients}
                    value={selectedClient}
                    inputValue={clientInput}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    excludeParentClients={selectedTabIndex === 1 || selectedTabIndex === 2}
                  />
                </Box>
              </Grid>
            </Grid>   
          </Box>
          <Box sx={styles.titleContainer}>
            <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>
              Client Settings
            </Typography>
          </Box>

          <Container maxWidth='xl'>
            <Grid
              container
              justifyContent='space-between'
              columnSpacing={2}
              rowSpacing={2}
              marginY={2}
            >
              {buttonNames.map((button) => {
                if((button.name === 'Loan Balances' && canViewLoanBalances) ||
                   (button.name === 'Reserves' && canViewReserves) ||
                   (button.name === 'Ineligible Adjustments' && canViewIneligibleADJ)
                ){
                  return <Grid item xs={12} md={6} lg={2.4} key={button.name}>
                      <Paper elevation={3} sx={styles.card}>
                        {selectedClient?.parentClient ?
                          <Tooltip title={`${button.name} does not apply to the Parent Client level`}>
                            <span>
                              <Button
                                fullWidth
                                disabled
                                {...getOnClick(button)}
                                sx={{ ...getBackgroundImage(button.name), ...styles.cardButtonCommon }}
                                aria-label={button.ariaLabel}
                              >
                                {button.name}
                              </Button>
                            </span>
                          </Tooltip>
                        : <Button
                            fullWidth
                            disabled={!selectedClient}
                            {...getOnClick(button)}
                            sx={{ ...getBackgroundImage(button.name), ...styles.cardButtonCommon }}
                            aria-label={button.ariaLabel}
                          >
                            {button.name}
                          </Button>
                        }
                      </Paper>
                    </Grid>
                }
                return <></>
              })
              }
              
                {
                  (canCalculateIneligible) &&
                  <Grid item xs={12} md={6} lg={2.4}>
                    <CalculateIneligiblesButton
                      disabled={!selectedClient || !canCalculateIneligible || Boolean(selectedClient.parentClientFk)}
                    />
                  </Grid>
                }
                {
                  (canCalculateBB) && 
                  <Grid item xs={12} md={6} lg={2.4}>
                    <CalculateBorrowingBaseButton
                      borrowerId={selectedClient?.recordId} 
                      borrowerName={selectedClient?.label}
                      disabled={!selectedClient || !canCalculateBB || Boolean(selectedClient.parentClient) || Boolean(selectedClient.parentClientFk)}
                    />
                  </Grid>
                }
                {
                  ((!canCalculateIneligible && canCalculateBB) || (canCalculateIneligible && !canCalculateBB)) && 
                  <Grid item xs={12} md={6} lg={2.4} />
                }
                {
                  (!canCalculateIneligible && !canCalculateBB) && 
                  <>
                    <Grid item xs={12} md={6} lg={2.4} />
                    <Grid item xs={12} md={6} lg={2.4} />
                  </>
                }
              
              
            </Grid>
          </Container>
        </Box>
        <ClientSettingsTabs selectedTabIndex={selectedTabIndex} onTabChange={handleTabChange} />
      </Box>
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onCloseChange={() => setToasterOpen(false)}
      />
    </ClientSettingsProvider>
  );
};
export default ClientSettings;
