import React, { useContext } from 'react';
import * as ReactRouter from 'react-router-dom';
import { Autocomplete, Button, Grid, Paper, TextField, Typography, Box } from '@mui/material';
import { API_DOMAIN, GET } from '../../../../utility/constants';
import { exportReport, formatDate, getFileName, getInvBorrowingBaseList, getBBCalcDatesRequest } from '../../../../utility/helper';
import requests from '../../../../service/requests';
import { IClient, IDatePeriod, IInvBorrowingBase, IInventoryCollateral, IInventoryIneligible, IInventoryIneligibles, IInventoryValuation, IInventoryValuations } from '../../../../interfaces/reportsInterface';
import { ReportsContext, IReportsContext } from '../../../../context/reportsContext';
import { BorrowingBaseReportContext, IBorrowingBaseReportContext } from '../../../../context/borrowingBaseReportContext';
import styles from './styles';
import BorrowingBaseReportBreadcrumbs from '../breadcrumbs';
import BorrowingBaseReportHeaderTitle from '../header-title';
import request from '../../../../service/request';
import { SelectedClientContext } from '../../../../context/selectedClientContext';
import { IExportFileType } from '../../../../interfaces';
import { inventoryAPI } from '../../../../service/api';
import DisabledComponentsContainer from '../../../common/disabled-components-container';
import axiosInstance from '../../../../service/axiosInstance';

const getExportURL = (exportAs: IExportFileType) => {
  return `${API_DOMAIN}/bb/bbReports/export${exportAs}`;
};

const PaperComponent = ({ children }) => (
  <Paper style={styles.dropdown}>{children}</Paper>
);

const BorrowingBaseReportTopContainer = () => {
  const {
    selectedClient: client,
    setSelectedClient: setClient,
    selectedDatePeriod: datePeriod,
    setSelectedDatePeriod: setDatePeriod,
    clients,
    setClients,
    datePeriods,
    setDatePeriods,
  } = React.useContext(ReportsContext) as IReportsContext;

  const {
    setIsReportVisible,
    setARCollaterals,
    setInventoryCollaterals,
    setInventoryValuations,
    setInventoryIneligibles,
    setOtherCollateralReport,
    setInvBorrowingBaseList,
  } = React.useContext(BorrowingBaseReportContext) as IBorrowingBaseReportContext;

  const navigate = ReactRouter.useNavigate();
  const selectedClientContext = useContext(SelectedClientContext);
  const [searchParams] = ReactRouter.useSearchParams();
  const clientIdSearchParam = searchParams.get('clientId');
  const bbPeriodIdSearchParam = searchParams.get('bbPeriodId');
  const calcDateSearchParam = searchParams.get('calcDate');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [clientNameInput, setClientNameInput] = React.useState('');
  const [asOfDateInput, setAsOfDateInput] = React.useState('');
  const [isGenerateReportButtonDisabled, setIsGenerateReportButtonDisabled] = React.useState(true);

  const clientId = searchParams.get('clientId') ?? 0;
  const bbPeriodId = searchParams.get('bbPeriodId') ?? 0;
  const endDate = searchParams.get('endDate') ?? datePeriod?.endDate;

  const EXPORT_DATE_FORMAT = 'MM/DD/YYYY';
  const EXCEL_FILETYPE = 'Excel';
  const PDF_FILETYPE = 'PDF';
  const REPORT_NAME = 'BBC';


  /**
   * This method fetches client data from the API.
   */
  const getClients = () => {
    axiosInstance
      .request({
        url: `${API_DOMAIN}/borrowers/search/findByCriteria?isVisible=true&isArchive=false&pageNo=0&pageSize=9999&sortBy=borrowerName,ASC`,
        method: GET,
      })
      .then((response) => {
        clientIdSearchParam !== null && getDatePeriods(parseInt(clientIdSearchParam));

        const responseContentForClients = response.data.content;
        const filteredWithoutUPCRelation = responseContentForClients.filter(client => !client.parentClient && !client.parentClientFk);
        setClients(filteredWithoutUPCRelation);
        getClientFromResponse(responseContentForClients);
      })
      .catch((error) => {
        console.log('GET CLIENTS FOR BB ERROR: ', error);
      });
  };

  /**
   *  This method processes the given client response to extract and set a specific client.
   *
   * @param responseContentForClients List of client objects from the response.
   * @returns 
   */
  const getClientFromResponse = (responseContentForClients: Array<IClient>) => {
    if (responseContentForClients === null || clientIdSearchParam === null) { return; }
    const [filteredClient] = responseContentForClients.filter(
      currentClient => currentClient.recordId === parseInt(clientIdSearchParam)
    );
    if (filteredClient !== undefined) {
      setClient(filteredClient);
      selectedClientContext?.setSelectedClient(filteredClient);
    };
  }

  /**
   * This method fetches date periods associated with a specific borrower.
   * @param borrowerId The ID of the borrower.
   * @returns 
   */
  const getDatePeriods = (borrowerId: number) => {
    if (!borrowerId) { return; }
    getBBCalcDatesRequest(borrowerId)
      .then((response) => {
        const responseContentForDatePeriods = response.data.content.map((datePeriod: IDatePeriod) => ({
          recordId: datePeriod.recordId,
          endDate: datePeriod.endDate
        }));
        setDatePeriods(responseContentForDatePeriods);
        getDatePeriodFromResponse(responseContentForDatePeriods);
      })
      .catch((error) => {
        console.log('ERROR FETCHING BB CALC DATES: ', error);
      });
  };


  /**
   * This method processes the provided date periods response to extract and set a specific date period. 
   * @param responseContentForDatePeriods 
   * @returns 
   */
  const getDatePeriodFromResponse = (responseContentForDatePeriods: Array<IDatePeriod>) => {
    if (responseContentForDatePeriods === null || calcDateSearchParam === null) { return; }
    const [filteredDatePeriod] = responseContentForDatePeriods.filter(
      currentDatePeriod => currentDatePeriod.endDate === calcDateSearchParam
    );
    filteredDatePeriod !== undefined && setDatePeriod(filteredDatePeriod);
  };

  /**
   * This method fetches Accounts Receivable (AR) Collaterals from the API.
   */
  const getARCollaterals = () => {
    axiosInstance
      .request({
        url: `${API_DOMAIN}/ar/collaterals/search/findByBorrowerId?borrowerId=${clientIdSearchParam}&pageNo=0&pageSize=9999&isDefault=true`,
        method: GET,
      })
      .then((response) => {
        setARCollaterals(response.data.content);
        setIsReportVisible(true);
      })
      .catch((error) => {
        setARCollaterals([]);
        console.log('GET AR COLLATERALS FOR BB ERROR: ', error);
      });
  };
 
  /**
   * This method fetches Inventory Collaterals from the API.
   */
  const getInventoryCollaterals = () => {
    axiosInstance
      .request({
        url: inventoryAPI.FIND_IS_ARCHIVED_BY_INPUT,
        method: GET,
        params: { borrowerId: clientIdSearchParam, pageNo: 0, pageSize: 99999, isArchived: false }
      })
      .then((response) => {
        const collaterals = response.data.content.map((collateral: IInventoryCollateral) => ({
          recordId: getPotentiallyUndefinedNumber(collateral.recordId),
          borrowerFk: getPotentiallyUndefinedNumber(collateral.borrowerFk),
          invCollateralFk: collateral.invCollateralFk,
          invCollateralName: collateral.invCollateralName,
          advanceRate: getPotentiallyUndefinedNumber(collateral.advanceRate),
          sublimit: getPotentiallyUndefinedNumber(collateral.sublimit),
          sublimitType: collateral.sublimitType,
          nolvAdvanceRate: getPotentiallyUndefinedNumber(collateral.nolvAdvanceRate),
          nolvIneligibleAmount: getPotentiallyUndefinedNumber(collateral.nolvIneligibleAmount),
          nolvAppraisal: getPotentiallyUndefinedNumber(collateral.nolvAppraisal),
          amountLimitType: collateral.amountLimitType,
          current: collateral.current,
        }));
        setInventoryCollaterals(collaterals);
        getInventoryValuations(collaterals);
        setIsReportVisible(true);
      })
      .catch((error) => {
        setInventoryCollaterals([]);
        console.log('GET INVENTORY COLLATERALS FOR BB ERROR: ', error);
      });
  };

  /**
   * This method fetches inventory valuations for the provided collaterals and processes the response.
   *
   * @param collaterals List of inventory collaterals for which to fetch valuations.
   */
  const getInventoryValuations = (collaterals: Array<IInventoryCollateral>) => {
    const requestsConfigs = collaterals.map(collateral => (
      axiosInstance.request({
        url: `${API_DOMAIN}/inv/valuations/search/findByInvCollateralId?invCollateralId=${collateral.invCollateralFk}&pageNo=0&pageSize=9999`,
        method: GET,
      })
    ));

    requests(requestsConfigs)
      .then((responses) => {
        let allValuations: IInventoryValuations = {};
        collaterals.forEach((collateral, index) => {
          allValuations[collateral.invCollateralFk] = responses[index].data.content.map((valuation: IInventoryValuation) => ({
            inventoryType: valuation.inventoryType,
            valuationAmount: getPotentiallyUndefinedNumber(valuation.valuationAmount),
            deductions: getPotentiallyUndefinedNumber(valuation.deductions),
            valueRate: getPotentiallyUndefinedNumber(valuation.valueRate),
            inventoryValue: getPotentiallyUndefinedNumber(valuation.inventoryValue),
            current: valuation.current,
          }))
        });
        setInventoryValuations(allValuations);
        setIsReportVisible(true);
      })
      .catch((error) => {
        setInventoryValuations(null);
        console.log('GET INVENTORY VALUATIONS FOR BB ERROR: ', error);
      });
  };

  /**
   * This method fetches inventory ineligibles based on a borrower's ID and processes the response.
   */
  const getInventoryIneligibles = () => {
    axiosInstance
      .request({
        url: `${API_DOMAIN}/inv/ineligibles/search/findByBorrowerId?borrowerId=${clientIdSearchParam}&pageNo=0&pageSize=9999`,
        method: GET,
      })
      .then((response) => {
        const ineligibles: IInventoryIneligibles = response.data.content.reduce((rearrangedIneligibles: IInventoryIneligibles, currentIneligible: IInventoryIneligible) => {
          if (currentIneligible.invCollateralFk === undefined) { return rearrangedIneligibles; }

          const cleanedCurrentIneligible = {
            invCollateralFk: currentIneligible.invCollateralFk,
            description: currentIneligible.description,
            amount: getPotentiallyUndefinedNumber(currentIneligible.amount),
            current: currentIneligible.current,
          };
          if (rearrangedIneligibles.hasOwnProperty(currentIneligible.invCollateralFk)) {
            rearrangedIneligibles[currentIneligible.invCollateralFk].push(cleanedCurrentIneligible);
            return rearrangedIneligibles;
          }
          rearrangedIneligibles[currentIneligible.invCollateralFk] = [cleanedCurrentIneligible];
          return rearrangedIneligibles;
        }, {});

        setInventoryIneligibles(ineligibles);
        setIsReportVisible(true);
      })
      .catch((error) => {
        setInventoryIneligibles(null);
        console.log('GET INVENTORY INELIGIBLES FOR BB ERROR: ', error);
      });
  };

  /**
   * This method fetches Inventory Borrowing Base List from the API.
   */
  const getInvBBList = async () => {
    try {
      const response = await getInvBorrowingBaseList(parseInt(clientIdSearchParam ?? '0'));
      const invBBList: Array<IInvBorrowingBase> = response.data;
      setInvBorrowingBaseList(invBBList);
      setIsReportVisible(true);
    } catch (error) {
      console.log('GET INV BORROWING BASE REPORT ERROR', error);
    }
  }

  /**
   * This method fetches "Other Collateral" for Borrowing Base Report.
   */
  const getOtherCollateralReport = () => {
    axiosInstance
      .request({
        url: `${API_DOMAIN}/bb/bbReports?borrowerId=${clientIdSearchParam}`,
        method: GET,
      })
      .then((response) => {
        setOtherCollateralReport(response.data);
        setIsReportVisible(true);
      })
      .catch((error) => {
        setOtherCollateralReport(null);
        console.log('GET OTHER COLLATERAL REPORT FOR BB ERROR: ', error);
      });
  };

  /**
   * This method constructs and returns a payload object containing data from borrower and a borrowing base period.
   * @returns 
   */
  // adjust
  const getPayloadData = () => {
    return {
      borrowerId: clientId,
      bbPeriodId: bbPeriodId,
      endDate:    endDate
    };
  };

  /**
   * This method fetches and returns the borrower name and end date for a borrowing base period.
   * 
   * @returns A tuple containing the borrower name and the end date.
   */
  const getReportParams = async () => {
    const clientResponse = await request({
      url: `${API_DOMAIN}/borrowers/${clientId}`,
      method: GET
    });

    const bbPeriodResponse = await request({
      url: `${API_DOMAIN}/bbPeriods/${bbPeriodId}`,
      method: GET
    });

    return [clientResponse.data.borrowerName, bbPeriodResponse.data.endDate];
  }

  /**
   * This method initiates the report generation process.
   */
  const generateReport = () => {
    setIsGenerateReportButtonDisabled(true);
    getARCollaterals();
    getInventoryCollaterals();
    getInventoryIneligibles();
    getOtherCollateralReport();
    getInvBBList();
  };

  /**
   * This method returns either the provided number or 0 if the number is undefined.
   * 
   * @param num The input number which might be undefined.
   * @returns The input number if it's defined, otherwise returns 0.
   */
  const getPotentiallyUndefinedNumber = (num: undefined | number) => {
    const IS_NUMBER_UNDEFINED = (num === undefined);
    if (IS_NUMBER_UNDEFINED) { return 0; }
    return num;
  };

  /**
   * This method handles the report export operation.
   *
   * @param filetype The type/format in which the report is to be exported.
   */
  const exportHandler = async (filetype: IExportFileType) => {

    if (datePeriod === null) { return; }

    try{
      const [clientName] = await getReportParams();
      
      exportReport(setIsLoading,
        getExportURL(filetype),
        getPayloadData(),
        getFileName(filetype, REPORT_NAME, formatDate(datePeriod?.endDate, EXPORT_DATE_FORMAT), clientName));
    }
    catch(error) {
      console.log('ERROR EXPORTING: ', error);
    }
  } 

  /**
   * This useEffect hook fetches clients and initiates the report generation process.
   */
  React.useEffect(() => {
    getClients();
    generateReport();
  }, []);

  return (
    <Box>
      <Box component="span" sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            <BorrowingBaseReportBreadcrumbs />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <Box sx={styles.clientBox}>
              <Typography tabIndex={0} sx={styles.clientDropdownTextLabels} component='label' htmlFor='client-name-box'>Client Name:</Typography>
              <Autocomplete
                id='client-name-box'
                data-testid="Borrowing Base Report-client-dropdown"
                disablePortal
                getOptionLabel={(clientOption) => clientOption.borrowerName}
                inputValue={clientNameInput}
                options={clients ?? []}
                onChange={(_event, selectedValue: IClient | null) => {
                  setClient(selectedValue);
                  selectedClientContext?.setSelectedClient(selectedValue);
                  setDatePeriod(null);
                  setIsReportVisible(false);
                  if (selectedValue === null) {
                    navigate(`/reports/borrowing-base`);
                    return;
                  }
                  getDatePeriods(selectedValue?.recordId);
                  navigate(`/reports/borrowing-base?clientId=${selectedValue?.recordId}`);
                }}
                onInputChange={(_event, inputValue) => {
                  setClientNameInput(inputValue);
                }}
                PaperComponent={({ children }) => PaperComponent({children})}
                renderInput={(params) => (
                  <TextField {...params} placeholder='Please Select' />
                )}
                size='small'
                sx={styles.dropdown}
                value={client}
                aria-label='Client Name Dropdown'
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
                  clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
                }}
              />
            </Box>
          </Grid>
        </Grid>   
      </Box>
      <BorrowingBaseReportHeaderTitle
          exportBBReportAsExcel={() => {exportHandler(EXCEL_FILETYPE)}}
          exportBBReportAsPDF={() => {exportHandler(PDF_FILETYPE)}}
          isLoading={isLoading}
          exportDisabled={!isGenerateReportButtonDisabled || datePeriod === null}
        />
      <Grid container columnSpacing={2} rowSpacing={2} sx={styles.topContainer}>
        <Grid item xs={12} md={3}>
          <Typography tabIndex={0} sx={styles.dropdownTextLabels} component='label' htmlFor='as-of-date-box'>As of Date</Typography>
          <Autocomplete
            id='as-of-date-box'
            data-testid="Borrowing Base Report-date-dropdown"
            disablePortal
            getOptionLabel={(datePeriodOption) => formatDate(datePeriodOption.endDate, 'MM/DD/YYYY')}
            inputValue={asOfDateInput}
            options={datePeriods}
            onChange={(_event, selectedValue: IDatePeriod | null) => {
              setDatePeriod(selectedValue);
              setIsReportVisible(false);
              if (selectedValue === null) {
                navigate(`/reports/borrowing-base?clientId=${client?.recordId}&&bbPeriodId=${bbPeriodIdSearchParam}`);
                return;
              }
              navigate(`/reports/borrowing-base?clientId=${client?.recordId}&&bbPeriodId=${bbPeriodIdSearchParam}&&calcDate=${selectedValue.endDate}`);
              setIsGenerateReportButtonDisabled(false);
            }}
            onInputChange={(_event, inputValue) => {
              setAsOfDateInput(inputValue);
            }}
            PaperComponent={({ children }) => PaperComponent({children})}
            renderInput={(params) => (
              <TextField {...params} placeholder='Please Select' />
            )}
            size='small'
            sx={styles.dropdown}
            value={datePeriod}
            disabled={client === null}
            aria-label='As Of Date Dropdown'
            componentsProps={{
              popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
              clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
            }}
          />
        </Grid>
        <Grid item xs={6} md={9} display='flex' alignSelf='flex-end'  justifyContent='flex-end'>
          <Grid>
            <DisabledComponentsContainer isDisabled={datePeriod === null || isGenerateReportButtonDisabled}>
              <Button
                disabled={datePeriod === null || isGenerateReportButtonDisabled}
                aria-label={datePeriod === null || isGenerateReportButtonDisabled ? 'Generate Report button disabled' : 'Generate Report'}
                data-testid='Borrowing Base Report-generate-button'
                variant='contained'
                sx={styles.generateReportButton}
                onClick={generateReport}>
                <Typography fontSize='0.875rem'>Generate Report</Typography>
              </Button>
              </DisabledComponentsContainer>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    
  );
};

export default BorrowingBaseReportTopContainer;
