import { AlertColor, Box, Button, CircularProgress, Divider, FormLabel, Grid, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material"
import { FieldArray, Form, Formik, FormikErrors, FormikTouched, getIn } from "formik"
import { IUsersAndRolesContext, UsersAndRolesContext } from "../../../../context/usersAndRolesContext"
import { FC, useContext, useEffect, useState } from "react"
import ConfirmModal from "../../../modals/confirm-modal"
import Prompt from "../../../modals/prompt"
import styles from "./styles"
import userSchema from "../../../../schemas/userSchema"
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IRoleAPI, IUserRowInfo } from "../../../../interfaces/rolesPermissionInterface"
import { checkUserPermissions, getLocalStorageItem } from "../../../../utility/helper"
import axiosInstance from "../../../../service/axiosInstance"
import { rolePermissionAPI } from "../../../../service/api"
import api from '../../../../service/api';
import { GET, NO_PERMISSION_MSG, PERMISSIONS, POST } from "../../../../utility/constants";

interface IProps {
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>,
  setToasterMessage : (value: string) => void,
  setToasterSeverity : (value: AlertColor) => void,
  setToasterOpen : (value: boolean) => void,
  buildUserData : () => void,
  presentUsers: IUserRowInfo[]
}

interface IUser {
  firstName: string,
  lastName:  string,
  email:     string,
  role:      string,
}

interface IFormikValue {
  users: IUser[];
}

const initialRoles: IRoleAPI[] = [
  { 
    id: '',
    name: '',
    realmRoles: []
   }
];

const initialUser = { 
  firstName: '', 
  lastName: '', 
  email: '', 
  role: ''
}; 

interface ICredentials {
  type: string;
  temporary: boolean;
  value: string;
}

interface ICreatedUser {
  username: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  enabled: string | null;
  emailVerified: string | null;
  groups: string[] | null[];
  credentials?: ICredentials[];
}

/**
 * Component for showing the contents of the add user modal.
 * @param props The props for the AddUserModal component. See the IProps interface for more information.
 */
const AddUserModal: FC<IProps> = (props) => {
  const {presentUsers, setShowAddModal, setToasterMessage, setToasterOpen, setToasterSeverity, buildUserData} = props;
  const [roleList, setRoleList]    = useState<IRoleAPI[]>(initialRoles);
  const [_isEditing, setIsEditing] = useState<boolean>(false);
  const [users]                    = useState<IUser[]>([initialUser]);
  const { setIsDirty, 
          showPrompt, 
          setShowPrompt }          = useContext(UsersAndRolesContext) as IUsersAndRolesContext;
  const [isAddUserLoading, setIsAddUserLoading]    = useState<boolean>(false);

  /**
   * This useEffect hook retrieves all of the available roles upon showing the modal.
   */
  useEffect(() => {
    getAllRoles();
  }, []);


  /**
   * This function closes the confirm navigation modal and the add user modal.
   */
  const handleClose = () => {
    setIsDirty(false)
    setShowPrompt(false)
    setShowAddModal(false)
  };

  /**
   * This function sets the dirty status of the form.
   * @param node The formik reference object containing the dirty status.
   */
  const formikRef = (node: any) => {
    if (node !== null) {
        setIsDirty(node.dirty)
    }
  };

  /**
   * This function retrieves the displayed errors for a field in the form.
   * @param errors The errors for the fields in the formik form.
   * @param touched The touched status for the fields in the formik form.
   * @param name The name of the field to be checked.
   * @param index The index of the field.
   * @returns The errors are returned if there are any errors found. Else, null is returned.
   */
  const getDisplayedError = (errors: FormikErrors<IFormikValue>, touched: FormikTouched<IFormikValue>, name: string, index: number) => {
    const fieldError = getIn(errors, `users[${index}].${name}`);
    const isFieldTouched = getIn(touched, `users[${index}].${name}`);

    if (fieldError && isFieldTouched) { return { error: true, helperText: fieldError }; }

    const rowError = getIn(errors, `users[${index}]`);
    const isRowTouched = getIn(touched, `users[${index}]`);

    if (fieldError && rowError && isRowTouched) { return { error: true, helperText: fieldError }; }
    if (rowError && isRowTouched) { return { helperText: ' ' }; }

    return null;
  };

  /** 
   * This function calls the API endpoint for retrieving all of the roles.
   * @returns The available roles in the application. See IRoleAPI interface for more information.
   */
  const getAllRoles = async () => {
    try {
      const token = getLocalStorageItem('token');
      const response = await axiosInstance.request({
        url: rolePermissionAPI.GET_ALL_ROLES,
        method: GET,
        headers: {token : token !== undefined ? token : ''}
      })
      const roles : IRoleAPI[] = response.data;
      const filteredRoles = roles.filter(role => role.name !== 'Super Admin');
      setRoleList(filteredRoles);
      return roles;
    } catch (error) {
      console.log('GET ALL ROLES ERROR:',error);
    }
  }

  /**
   * This function creates the data to be sent to the API endpoint for sending an invite to a user.
   * @param user The user to be invited.
   * @param currentUser The user inviting a new user.
   */
  const createInviteData = (user : IUser, currentUser : IUserRowInfo) => {
    const data = {
      sender: getLocalStorageItem('firstName') + ' ' + getLocalStorageItem('lastName'),
      firstName : user.firstName,
      lastName : user.lastName,
      email : user.email,
      roleId : user.role,
      invitedById : currentUser.recordId,
      host: window.location.origin
    };
    return data;
  }

  /**
   * This function calls the API endpoint for sending an invite to a user.
   * @param user The user to be invited.
   * @param currentUser The user inviting a new user.
   */
  const sendInvite = async (user : IUser, currentUser : IUserRowInfo) => {
    try {
      setIsAddUserLoading(true);
      const userToBeCreated : ICreatedUser = {
        username : user.email,
        email : user.email,
        firstName : user.firstName,
        lastName : user.lastName,
        enabled: 'True',
        emailVerified: 'True',
        groups: [user.role]
      }
  
      const response = await createUser(userToBeCreated)
      const newUserDetailId = response?.data.recordId;
      const newUserId = response?.data.keycloakId;

      const token = getLocalStorageItem('token'); 
      const data = createInviteData(user, currentUser);
      await axiosInstance.request({
        url: rolePermissionAPI.SEND_INVITE,
        method: POST,
        headers: {token : token !== undefined ? token : ''},
        params: {
          userDetailId : newUserDetailId,
          userId: newUserId
        },
        data: data
      })
      buildUserData();
    } catch (e) {
      console.log(e)
    } finally {
      setIsAddUserLoading(false);
    }
  }

  /**
   * This function calls the API endpoint for creating a public access token that can be used to create a new user.
   * @returns The public access token.
   */
  const createPublicAccessToken = async () => {
    try {
      const response = await axiosInstance.request({
        url: api.CREATE_PUBLIC_TOKEN,
        method: GET
      })
      const token = response.data.access_token;
      return token;
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * This function calls the API endpoint for creating a new user.
   * @param user The user to be creates.
   * @returns The response of the API request.
   */
  const createUser = async (user : ICreatedUser) => {
    try {
      const currentUser = presentUsers.filter(user => user.keycloakId === getLocalStorageItem('uid'))[0];
      const token = await createPublicAccessToken();
      const response = await axiosInstance.request({
        url: api.CREATE_USER,
        method: POST,
        params: {invitedBy : currentUser.recordId},
        headers: {token : token},
        data: user
      })
      return response;
    } catch (e) {
      console.log(e)
    }
  };

  /**
   * This function checks if the user has a specific permission.
   * @param func The function to be called if the user has the required permission.
   * @param permission The permission to be checked.
   * @param args The arguments to be given to the function.
   */
  const checkPermission = async (func: Function, permission: string, args: any) => {
    const isPermitted = await checkUserPermissions(getLocalStorageItem('uid'), permission)
    if(isPermitted){
      await func(args)
      return
    }
    setToasterOpen(true);
    setToasterMessage(NO_PERMISSION_MSG);
    setToasterSeverity('error');
  }

  /**
   * This function calls the sendInvite function for every user present in the add user form.
   * @param values The users to be invited.
   */
  const handleSubmit = async (values : IFormikValue) => {
    const currentUser = presentUsers.filter(user => user.keycloakId === getLocalStorageItem('uid'))[0];
    await Promise.all(values.users.map(async user => {
      await sendInvite(user, currentUser);
    }))
    setToasterMessage("User has been succesfully invited!");
    setToasterSeverity('success');
    setToasterOpen(true);
    setShowAddModal(false);
  }

  return (
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={{users}}
        validationSchema={userSchema}
        innerRef={formikRef}
        onSubmit={(values) => {checkPermission(handleSubmit, PERMISSIONS.ADD_USER, values)}}
      >
        {(formik) => (
          <Form style={styles.form}>
            <Prompt when={formik.dirty} isEditing={setIsEditing}/>
            <Grid container sx={styles.container}>
              <Grid
                container
                rowSpacing={2.5}
                columnSpacing={1}
                sx={styles.gridContainerForHeader}
              >
                <Grid item xs={2}>
                  <FormLabel
                    component='label'
                    required={true}
                    sx={styles.label}
                    htmlFor='firstName'>
                    First Name:
                  </FormLabel>
                </Grid>
                <Grid item xs={2}>
                  <FormLabel
                    component='label'
                    required={true}
                    sx={styles.label}
                    htmlFor='lastName'>
                    Last Name:
                  </FormLabel>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel
                    component='label'
                    required={true}
                    sx={styles.label}
                    htmlFor='email'>
                    E-mail Address:
                  </FormLabel>
                </Grid>
                <Grid item xs={2}>
                    <FormLabel
                      component='label'
                      required={true}
                      sx={styles.label}
                      htmlFor='roles'>
                      Roles
                    </FormLabel>
                </Grid>
              </Grid>
              <FieldArray name='users'>
                {
                  (fieldArrayHelpers) => (
                    <>
                      {
                        formik.values.users.map((user, index) => (
                          <Grid
                            key={user.role}
                            container
                            rowSpacing={1}
                            columnSpacing={1}
                            sx={styles.gridContainer}
                          >
                            <Grid item xs={2}>
                              <TextField
                                id='FirstName'
                                arial-lable='First Name Field'
                                name={`users[${index}].firstName`}
                                value={formik.values.users[index].firstName}
                                sx={styles.gridField}
                                onChange={(event: any) => { formik.handleChange(event) }}
                                {...getDisplayedError(formik.errors, formik.touched, 'firstName', index)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                id='LastName'
                                arial-lable='Last Name Field'
                                name={`users[${index}].lastName`}
                                value={formik.values.users[index].lastName}
                                sx={styles.gridField}
                                onChange={(event: any) => { formik.handleChange(event) }}
                                {...getDisplayedError(formik.errors, formik.touched, 'lastName', index)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                id='E-mail Address'
                                arial-lable='E-mail Address'
                                name={`users[${index}].email`}
                                value={formik.values.users[index].email}
                                sx={styles.gridField}
                                onChange={(event: any) => {formik.handleChange(event) }}
                                {...getDisplayedError(formik.errors, formik.touched, 'email', index)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Select
                                displayEmpty
                                name={`users[${index}].role`}
                                arial-label='roles'
                                onChange={(event) => { formik.handleChange(event) }}
                                sx={{ ...styles.gridField, ...styles.gridSelect,  ...styles.limitGridSelect, }}
                                {...getDisplayedError(formik.errors, formik.touched, 'role', index)}
                                value={formik.values.users[index].role}>
                                {formik.values.users[index].role === '' &&
                                  <MenuItem value='' sx={{ ...styles.gridMenuItem, ...styles.hidden }} disabled>
                                    Please Select
                                  </MenuItem>
                                }
                                {
                                  roleList.map((role, idx) =>
                                    <MenuItem key={role.id} value={role.id} sx={styles.gridMenuItem}>
                                      {role.name}
                                    </MenuItem>
                                  )
                                }
                              </Select>
                            </Grid>
                            <Grid item xs={2} sx={styles.gridItemActions}>
                              <Button variant='contained' color='primary' sx={styles.addRowButton} onClick={() => { fieldArrayHelpers.push(initialUser) }}>
                                <Typography>
                                  +Add
                                </Typography>
                              </Button>
                              <Divider orientation="vertical" variant='middle' flexItem sx={styles.gridVerticalBar} />
                              <IconButton onClick={() => {  
                                if (formik.values.users.length === 1) {
                                  fieldArrayHelpers.remove(index);
                                  fieldArrayHelpers.push(initialUser);
                                  return;
                                }
                                fieldArrayHelpers.remove(index);
                              }}>
                                <DeleteOutlinedIcon sx={styles.deleteIcon}/>
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))
                      }
                    </>
                  )
                }
              </FieldArray>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.bottomActionsButtonContainer}>
                <Button
                  variant='outlined'
                  sx={styles.cancelButton}
                  onClick={() => {
                    formik.resetForm();
                    setShowAddModal(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid || isAddUserLoading}
                  variant='contained'
                  type='submit'
                  sx={styles.addButton}
                >
                  {isAddUserLoading ? <CircularProgress color="inherit" size={20} /> : "Add User"}
                </Button>
              </Box>
            </Grid>
            <ConfirmModal
              open={showPrompt}
              onClose={() => {formik.resetForm(); handleClose();}}
              onConfirm={() => {setShowPrompt(false);}}
              onButtonClose={() => {setShowPrompt(false);}}
              promptChecker
              title={`Confirm Navigation`}
              description={`You have unsaved changes. Are you sure you want to leave this page?`}
              yesButtonText={`Keep Editing`}
              noButtonText={`Discard Changes`}
              confirmOnly
            />
          </Form> 
        )}
      </Formik>
    </Grid>
  )
}

export default AddUserModal
