import { Box, FormLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TablePagination, AlertColor, Typography } from '@mui/material';
import styles from './styles'
import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { getFileValuesByDocument, getTableRowsAfterPaging, cleanValues } from '../../../../../utility/helper';
import { DOCTYPE, requiredMappingFields, optionalMappingFields, GET } from '../../../../../utility/constants';
import LoadingBackdrop from '../../../../../components/common/loading-backdrop';
import SheetSelectionModal from './sheet-selection-modal';
import { FileImportContext, IFileImportContext } from '../../../../../context/fileImportContext';
import { IUploadedFile } from '../../../../../interfaces/fileimport';
import FileRow from './file-row';
import axiosInstance from '../../../../../service/axiosInstance';
import { fileImportAPI } from '../../../../../service/api';

export interface IDataMapTabProps {
  showToaster: (severity: AlertColor, message: string) => void;
}

interface PaginationButtonProps {
  page                : number;
  handlePreviousClick : () => void;
  uploadedFiles       : IUploadedFile[];
  rowsPerPage         : number;
  handleNextClick     : () => void;
}

export const dateHeaders: string[] = ['arTrxDate', 'arTrxDueDate', 'apTrxDate', 'apTrxDueDate', 'asOfDate', 'glTrxDate'];
export const amtHeaders: string[] = ['creditLimit', 'arTrxAmt', 'apTrxAmt', 'glTrxAmt1'];

/**
 * This function retrieves the list of required fields for a given document type.
 * @param documentType The document type for which to retrieve required fields.
 * @returns An array of required field names.
 */
export const getRequiredFields = (documentType: typeof DOCTYPE[keyof typeof DOCTYPE]): string[] => {
  const fields: string[] | undefined = requiredMappingFields[documentType];
  if (fields) return fields;
  else return [];
};

/**
 * This function retrieves the list of optional fields for a given document type.
 * @param documentType The document type for which to retrieve optional fields.
 * @returns An array of optional field names.
 */
export const getOptionalFields = (documentType: typeof DOCTYPE[keyof typeof DOCTYPE]): string[] => {
  const fields: string[] | undefined = optionalMappingFields[documentType];
  if (fields) return fields;
  else return [];
};

/**
 * Component for the Data Map Step.
 * @param props The props for the Data Map Step component.
 * @returns A component for the Data Map Step.
 */
const DataMap: FC<IDataMapTabProps> = (props) => {
  const DEFAULT_PAGE = 0;
  const DEFAULT_ROWS_PER_PAGE = 10;

  const { showToaster }                               = props;
  const { selectedClient,
          isPageLoading,
          uploadedFiles, setUploadedFiles }           = useContext(FileImportContext) as IFileImportContext;
  const [page, setPage]                               = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage]                 = useState(DEFAULT_ROWS_PER_PAGE);
  const [sheetSelectOpen, setSheetSelectOpen]         = useState<boolean>(false);
  const [sheetsSelected, setSheetsSelected]           = useState<boolean>(false);
  const isUltimateParent                              = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient]);

  /**
   * This hook memoizes the list of uploaded Excel documents that do not have a selected sheet.
   * It filters the `uploadedFiles` array to include only Excel files that lack a selected sheet.
   */
  const excelDocsWithUnselectedSheet = useMemo(() => uploadedFiles.filter(uploaded => 
    uploaded.isExcelFile && !uploaded.selectedSheet
  ), [uploadedFiles])

  /**
   * This useEffect performs specific actions when the component mounts or when certain dependencies change.
   * It checks if a client is selected, if the page is loading, and the status of Excel documents with unselected sheets.
   * Depending on these conditions, it may open a sheet selection dialog or parse uploaded files.
   */
  useEffect(() => {
    if (!selectedClient?.recordId) return;

    (async () => {
      if (!isPageLoading) {
        if (excelDocsWithUnselectedSheet.length && !sheetsSelected) setSheetSelectOpen(true);
        else await parseUploadedFiles(uploadedFiles);
      }
    })();
  }, [isPageLoading, uploadedFiles]);

  /**
   * This function asynchronously parses uploaded files that are marked for parsing and updates the list of uploaded files.
   * @param uploadedFiles The array of uploaded files to be parsed.
   * @returns Early return if the filesToParse is empty.
   */
  const parseUploadedFiles = async (uploadedFiles: IUploadedFile[]) => {
    const filesToParse = uploadedFiles.filter(uploaded => uploaded.isParsing);
    if (filesToParse.length === 0) return;
    
    const promises: Promise<IUploadedFile>[] = mapParsingPromises(filesToParse);
    const recentParsedFiles: IUploadedFile[] = await Promise.all(promises);
    const parsedFiles: IUploadedFile[] = uploadedFiles.map(uploaded => {
      const recentParsed = recentParsedFiles.find(recent => recent.recordId === uploaded.recordId);
      if (recentParsed) return recentParsed;
      else return uploaded;
    })
    setUploadedFiles(parsedFiles);
  };

  /**
   * This function maps and executes asynchronous parsing promises for a list of uploaded files.
   * @param filesToParse The array of uploaded files to be parsed.
   * @returns An array of promises that resolve to parsed uploaded files.
   */
  const mapParsingPromises = (filesToParse: IUploadedFile[]) => {
    return filesToParse.map(async (uploaded: IUploadedFile) => {
      const values: string[][] | undefined = await getFileValuesByDocument(uploaded);
      if (!values) {
        uploaded.isParsing = false;
        return uploaded;
      };
      uploaded.values = cleanValues(values);

      // Get Excluded Row Setting
      const excludedRowSetting: number | undefined = isUltimateParent ?
        await getLatestExcludedRowSettingByBorrowerId(uploaded.borrowerFk, uploaded.documentTypeFk) :
        await getLatestExcludedRowSettingByArCollateralId(uploaded.arCollateralFk as number, uploaded.documentTypeFk);
      uploaded.excludedRowsEndRowNum = excludedRowSetting;
      uploaded.isParsing = false;
      return uploaded;
    });
  };
  
  /**
   * Retrieves the latest excluded row setting by AR collateral ID and document type ID.
   * @param arCollateralId The AR collateral ID.
   * @param documentTypeId The document type ID.
   * @returns The latest excluded row setting or undefined if not found.
   */
  const getLatestExcludedRowSettingByArCollateralId = async (arCollateralId: number, documentTypeId: number) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.document.FIND_EXCLUDED_ROW_SETTING_IND,
        params: { arCollateralId, documentTypeId },
        method: GET
      });

      if (typeof response.data === 'string') return undefined;
      else return response.data as number;
    } catch (error) {
      console.log('GET LATEST EXCLUDED ROW SETTING BY AR COLLATERAL ERROR: ', error);
    }
  }

  /**
   * Retrieves the latest excluded row setting by borrower ID and document type ID.
   * @param borrowerId The borrower ID.
   * @param documentTypeId The document type ID.
   * @returns The latest excluded row setting or undefined if not found.
   */
  const getLatestExcludedRowSettingByBorrowerId = async (borrowerId: number, documentTypeId: number) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.document.FIND_EXCLUDED_ROW_SETTING_UPC,
        params: { borrowerId, documentTypeId },
        method: GET
      });

      if (typeof response.data === 'string') return undefined;
      else return response.data as number;
    } catch (error) {
      console.log('GET LATEST EXCLUDED ROW SETTING BY BORROWER ERROR: ', error);
    }
  }

  return(
    <>
      <Box sx={styles.comboBoxStyle}>
        <TableContainer component={Paper} sx={styles.tableContainer}>
        {uploadedFiles.length ? 
          <Table aria-label='customized table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...styles.tableHead, ...styles.fileNameHeader}}>
                  <FormLabel tabIndex={0} htmlFor='file-name' sx={{...styles.tableHeaderText}}>
                    File Name
                  </FormLabel>
                </TableCell>
                <TableCell sx={styles.tableHead}>
                  <FormLabel tabIndex={0} htmlFor='document-type' sx={{ ...styles.tableHeaderText}}>
                    Document Type
                  </FormLabel>
                </TableCell>
                <TableCell sx={{ ...styles.tableHead}}>
                  <FormLabel tabIndex={0} htmlFor='collateral' sx={styles.tableHeaderText}>
                    Collateral
                  </FormLabel>
                </TableCell>
                <TableCell sx={{ ...styles.tableHead}}>
                  <FormLabel tabIndex={0} htmlFor='as-of-date' sx={styles.tableHeaderText}>
                    As of Date
                  </FormLabel>
                </TableCell>
                <TableCell sx={{ ...styles.tableHead}}>
                  <FormLabel tabIndex={0} htmlFor='uploaded-date' sx={styles.tableHeaderText}>
                    Uploaded Date
                  </FormLabel>
                </TableCell>
                <TableCell sx={{ ...styles.tableHead}}>
                  <FormLabel tabIndex={0} htmlFor='status' sx={styles.tableHeaderText}>
                    Status
                  </FormLabel>
                </TableCell>
                <TableCell sx={{ ...styles.tableHead, ...styles.centerAlignedText }}>
                  <FormLabel tabIndex={0} htmlFor='actions' sx={styles.tableHeaderText}>
                    Actions
                  </FormLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {getTableRowsAfterPaging(page, rowsPerPage, uploadedFiles)
              .map((uploadedFile: IUploadedFile) => (
                <FileRow
                  key={uploadedFile.recordId}
                  uploadedFile={uploadedFile}
                  showToaster={showToaster}
                />))}
            </TableBody>
          </Table> :
          <Box sx={styles.verbiageContainer}>
            <Typography tabIndex={0} sx={styles.verbiage}>There are no uploaded files yet. Go back to Upload step to upload a file.</Typography>
          </Box>}
        </TableContainer>
      </Box>
      <Grid item xs={12}>
        <Box sx={styles.bottomContainer}>
          <TablePagination
            component='div'
            colSpan={7}
            count={uploadedFiles.length}
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_event: unknown, newPage: number) => setPage(newPage)}
            onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(DEFAULT_PAGE);
            }}
          />
        </Box>
      </Grid>
      <LoadingBackdrop isLoading={isPageLoading} />
      <SheetSelectionModal
        open={sheetSelectOpen}
        onClose={() => {
          setSheetSelectOpen(false);
          setSheetsSelected(true);
        }}
        excelDocsWithUnselectedSheet={excelDocsWithUnselectedSheet}
        showToaster={showToaster}
      />
    </>
  )
}

export default DataMap;