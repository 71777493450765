import React, { useState } from 'react';
import { formatCurrency, formatPercentage } from '../../../../utility/helper';
import { BorrowingBaseReportContext, IBorrowingBaseReportContext } from '../../../../context/borrowingBaseReportContext';
import TableWithLeftPinnedHeader from '../../../common/table-with-left-pinned-header';
import styles from '../grid-details/styles';

const InventoryNOLVBBListTable = () => {
  const { inventoryCollaterals, inventoryValuations, inventoryIneligibles, invBorrowingBaseList} = React.useContext(BorrowingBaseReportContext) as IBorrowingBaseReportContext;

  const [inventoryBorrowingBaseList, setInventoryBorrowingBaseList] = useState(invBorrowingBaseList);

  React.useEffect(() => {
    setInventoryBorrowingBaseList(invBorrowingBaseList);
  }, [inventoryCollaterals, inventoryValuations, inventoryIneligibles, invBorrowingBaseList]);

  return (
    (inventoryBorrowingBaseList !== null && inventoryBorrowingBaseList.length > 0) ? (
      <TableWithLeftPinnedHeader
        columns={[
          { field: 'collateralName', headerName: 'Collateral Name' },
          { field: 'grossDomesticFinishedGoodsInventory', headerName: 'Gross Domestic Finished Goods Inventory' },
          { field: 'totalNOLVDomesticFinishedGoodsInventoryIneligibles', headerName: 'Total NOLV Domestic Finished Goods Inventory Ineligibles' },
          { field: 'eligibleDomesticFinishedGoodsInventory', headerName: 'Eligible Domestic Finished Goods Inventory' },
          { field: 'netOrderlyLiquidationValuePercentage', headerName: 'Net Orderly Liquidation Value %' },
          { field: 'netOrderlyLiquidationValue', headerName: 'Net Orderly Liquidation Value' },
          { field: 'advanceRateOnNOLV', headerName: 'Advance Rate on NOLV' },
          { field: 'marginalNOLVPercentage', headerName: 'Marginal NOLV Percentage' },
          { field: 'availableDomesticFinishedGoodsInventoryPerNOLV', headerName: 'Available Domestic Finished Goods Inventory Per NOLV' },
          { field: 'effectiveAdvanceRate', headerName: 'Effective Advance Rate' },
        ]}
        rows={
          inventoryBorrowingBaseList.map(row => ({
            collateralName: <div title={row.invCollateralName}>{row.invCollateralName}</div>,
            grossDomesticFinishedGoodsInventory: `${formatCurrency(row.grossInvAmount)}`,
            totalNOLVDomesticFinishedGoodsInventoryIneligibles: `${formatCurrency(row.totalNolvInvIneligibles)}`,
            eligibleDomesticFinishedGoodsInventory: `${formatCurrency(row.nolvEligibleInv)}`,
            netOrderlyLiquidationValuePercentage: `${formatPercentage(row.nolvPercentage, 2)}`,
            netOrderlyLiquidationValue: `${formatCurrency(row.nolv)}`,
            advanceRateOnNOLV: `${formatPercentage(row.advRateOnNolv, 2)}`,
            marginalNOLVPercentage: `${formatPercentage(row.marginalNolvPercentage, 2)}`,
            availableDomesticFinishedGoodsInventoryPerNOLV: `${formatCurrency(row.availInvPerNolv)}`,
            effectiveAdvanceRate: `${formatPercentage(row.effectiveNolvAdvRate, 2)}`,
          }))
        }
        styles={{
          tableHeadCell: styles.tableHeadCell,
          tableCell: styles.tableCell,
          tableBody: styles.tableBody,
          tableHead: styles.tableHead,
        }}
      />
    ) : <></>
  );
};

export default InventoryNOLVBBListTable;
