import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { IMappingModalProps } from "..";
import { Form, Formik, FormikProps } from "formik";
import { ICalculationMapping, IDocument, IRollForwardCategory, IUploadedFile } from "../../../../../../../../interfaces/fileimport";
import axiosInstance from "../../../../../../../../service/axiosInstance";
import { fileImportAPI, processingAPIs } from "../../../../../../../../service/api";
import { API_DOMAIN, BATCH_SIZE, DELETE, GET, POST, PROMPT, PUT, STATUS_CODES } from "../../../../../../../../utility/constants";
import { Box, Button, CircularProgress, Link, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import styles from "./styles";
import ConfirmModal from "../../../../../../../../components/modals/confirm-modal";
import { FileImportContext, IFileImportContext } from "../../../../../../../../context/fileImportContext";
import CalculationMappingTable from "./calculation-mapping-table";
import { getUniqueKeys } from "../../../../../../../../utility/helper";
import DisabledComponentsContainer from "../../../../../../../../components/common/disabled-components-container";
import { IProcessingAPIs, IProcessingRequests } from "../../../../import-tab";

export interface ICalculationMappingProps extends IMappingModalProps {
  isDirty       : boolean;
  setIsDirty    : Dispatch<SetStateAction<boolean>>;
  setActiveStep : Dispatch<SetStateAction<number>>;
}

/**
 * Component for the Calculation Mapping Step of the Mapping Modal.
 * @param props The props for the Calculation Mapping Step of the Mapping Modal.
 * @returns A component for the Calculation Mapping Step of the Mapping Modal.
 */
const CalculationMapping: FC<ICalculationMappingProps> = (props) => {
  const { showToaster, uploadedFile,
          handleClose,
          isDirty, setIsDirty, setActiveStep,
          setSelectedFile,
          importedFiles, setImportedFiles,
          filteredRows, setFilteredRows,
  }                                                   = props;
  const { uploadedFiles, setUploadedFiles,
          isInLandingPage }                           = useContext(FileImportContext) as IFileImportContext;
  const [calculationMappings, setCalculationMappings] = useState<ICalculationMapping[]>([]);
  const [rfCategories, setRfCategories]               = useState<IRollForwardCategory[]>([]);
  const [isLoading, setIsLoading]                     = useState<boolean>(false);
  const [showPrompt, setShowPrompt]                   = useState<boolean>(false);
  const [buttonClicked, setButtonClicked]             = useState<'save' | 'cancel' | 'back'>('save');
  const [initialized, setInitialized]                 = useState<boolean>(false);

  /**
   * This useEffect block fetches roll forward categories and sets them in the component's state.
   */
  useEffect(() => {
    (async () => {
      const categories: IRollForwardCategory[] | undefined = await getRfCategories();
      if (categories) return setRfCategories(categories);
    })();
  }, []);

  /**
   * This useEffect block handles the initialization of calculation mappings when the uploadedFile changes.
   */
  useEffect(() => {
    (async () => {
      setIsLoading(true);

      if (uploadedFile.calculationMappings?.length) {
        setCalculationMappings(uploadedFile.calculationMappings);
        if (isInLandingPage) {
          await applyAndUpdateDocumentWithMappings(uploadedFile.calculationMappings, false);
        }
      } else {
        const initialMappings: ICalculationMapping[] | undefined = await getInitialCalculationMapping(uploadedFile);
        if (initialMappings) {
          setCalculationMappings(initialMappings);

          // Check if all are mapped and apply auto mapping
          const allMapped = initialMappings.every(mapping => mapping.rfCategoryId);
          if (allMapped) await applyAndUpdateDocumentWithMappings(initialMappings, false);
          setInitialized(true);
        };
      }

      setIsLoading(false);
    })();
  }, [uploadedFile]);

  /**
   * This function fetches roll forward categories from an API.
   * @returns An array of roll forward categories or undefined if an error occurs.
   */
  const getRfCategories = async () => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.rollForwardCategory.MAIN_ENDPOINT,
        method: GET,
        params: { sortBy: 'categoryName,ASC' }
      });
      const categories: IRollForwardCategory[] = response.data.content;
      return categories;
    } catch (error) {
      showToaster('error', 'Failed getting categories');
      console.log('GET RF CATEGORIER ERROR: ', error);
    }
  }

  /**
   * This function fetches the initial calculation mappings for an uploaded file.
   * @param uploadedFile The uploaded file for which to fetch initial calculation mappings.
   * @returns An array of initial calculation mappings or undefined if an error occurs.
   */
  const getInitialCalculationMapping = async (uploadedFile: IUploadedFile) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.calculationMapping.GET_INITIAL_CALCULATION_MAPPING,
        method: GET,
        params: { documentId: uploadedFile.recordId }
      });
      const initialMappings: ICalculationMapping[] = response.data;
      return initialMappings;
    } catch (error) {
      showToaster('error', 'Error fetching initial mappings!');
      console.log('GET INITIAL CALCULATION MAPPING ERROR: ', error);
      setIsLoading(false);
    }
  };

  /**
   * This function handles the submission of calculation mappings.
   * @param values The values to be submitted, including calculationMappings.
   */
  const handleSubmit = async (values: {calculationMappings: ICalculationMapping[]}, isDirty: boolean) => {
    setButtonClicked('save');

    if (isDirty || !uploadedFile.mapped) {
      const updatedMappings: ICalculationMapping[] | undefined = await updateCalculationMappings(values.calculationMappings);
      if (updatedMappings) {
        const updatedDocument: IUploadedFile | undefined = await applyAndUpdateDocumentWithMappings(updatedMappings, !isInLandingPage);
        if (isInLandingPage && updatedDocument) {
          const processedFile: IUploadedFile = await processRemapping(updatedDocument, uploadedFiles);
          if (processedFile.dataMappingStatus === 'Applied') handleClose();
        }
      }
    } else {
      handleClose();
    }
  };

  /**
   * This function updates calculation mappings with new values.
   * @param calculationMappings An array of calculation mappings to be updated.
   * @returns An array of updated calculation mappings or undefined if an error occurs.
   */
  const updateCalculationMappings = async (calculationMappings: ICalculationMapping[]) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.calculationMapping.LIST_MAIN_ENDPOINT,
        method: POST, 
        data: calculationMappings
      });
      const updated: ICalculationMapping[] = response.data;
      return updated;
    } catch (error) {
      showToaster('error', 'Error updating mappings!')
      console.log('ERROR UPDATING CALCULATION MAPPINGS: ', error);
    }
  };

  /**
   * Applies calculation mappings to an uploaded file and updates the document with the applied mappings.
   * @param uploadedFile - The uploaded file to which calculation mappings will be applied.
   * @param calculationMappings - The calculation mappings to be applied.
   * @returns A promise that resolves when the document has been updated with the applied mappings.
   */
  const applyAndUpdateDocumentWithMappings = async (calculationMappings: ICalculationMapping[], closeModalAfterSuccess?: boolean) => {
    const applied: IUploadedFile | undefined = await applyCalculationMappings(uploadedFile, calculationMappings);
    if (applied) {
      const updatedDocument: IUploadedFile | undefined = await updateDocument(applied);

      if (updatedDocument) {
        setSelectedFile && setSelectedFile(updatedDocument);
        setUploadedFiles(uploadedFiles.map(uploaded => mapUpdatedFile(uploaded, updatedDocument)));
        filteredRows && setFilteredRows && setFilteredRows(filteredRows.map(uploaded => mapUpdatedFile(uploaded, updatedDocument)));
        importedFiles && setImportedFiles && setImportedFiles(importedFiles.map(uploaded => mapUpdatedFile(uploaded, updatedDocument)));
        showToaster('success', `Calculation mappings have been applied!`);
        if (closeModalAfterSuccess) {
          handleClose();
          return;
        };
        return updatedDocument;
      }
    }
  };

  /**
   * This function applies updated calculation mappings to an uploaded file.
   * @param uploadedFile The uploaded file to which the calculation mappings will be applied.
   * @param updatedMappings An array of updated calculation mappings.
   * @returns The updated uploaded file with applied calculation mappings or undefined if an error occurs.
   */
  const applyCalculationMappings = async (uploadedFile: IUploadedFile, updatedMappings: ICalculationMapping[]) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.calculationMapping.APPLY_CALCULATION_MAPPING,
        method: POST,
        params: { documentId: uploadedFile.recordId },
        data: updatedMappings
      });
      if (response.status === STATUS_CODES.CREATED) {
        uploadedFile.calculationMappings = updatedMappings;
        return uploadedFile;
      } else {
        throw new Error();
      }
    } catch (error) {
      showToaster('error', 'Error applying mappings!')
      console.log('ERROR APPLYING CALCULATION MAPPINGS: ', error);
    }
  };

  /**
   * This function updates a document with new data and marks it as mapped.
   * @param uploadedFile The uploaded file object to be updated.
   * @returns The updated uploaded file object or undefined if an error occurs.
   */
  const updateDocument = async (uploadedFile: IUploadedFile) => {
    try {
      const response = await axiosInstance.request({
        url: `${fileImportAPI.document.MAIN_ENDPOINT}/${uploadedFile.recordId}`,
        method: PUT,
        data: {
          recordId: uploadedFile.recordId,
          borrowerFk: uploadedFile.borrowerFk,
          documentTypeFk: uploadedFile.documentTypeFk,
          arCollateralFk: uploadedFile.arCollateralFk,
          bbPeriodFk: uploadedFile.bbPeriodFk,
          filename: uploadedFile.filename,
          processed: uploadedFile.processed,
          mapped: true,
          archive: uploadedFile.archive,
          totalTrxAmt: uploadedFile.totalTrxAmt,
          selectedSheet: uploadedFile.selectedSheet,
          sheetNames: uploadedFile.sheetNames,
          shouldReplace: uploadedFile.shouldReplace,
          hasPrevious: uploadedFile.hasPrevious,
          updatedAt: uploadedFile.updatedAt,
          createdAt: uploadedFile.createdAt,
          excludedRowsEndRowNum: uploadedFile.excludedRowsEndRowNum,
        }
      });
      const updated: IDocument = response.data;
      uploadedFile.mapped = updated.mapped;
      uploadedFile.updatedAt = updated.updatedAt;
      return uploadedFile;
    } catch (error) {
      console.log('UPDATE DOCUMENT ERROR: ', error);
      showToaster('error', 'Error in updating document');
    }
  };

  /**
   * Checks if the uploaded file and the current item of the array has the same record id.
   * @param uploaded - The current file in the array.
   * @param uploadedFile - The uploaded file object used to update the array.
   * @returns A file whether the new or the current depending on the matched record id.
   */
  const mapUpdatedFile = (uploaded: IUploadedFile, uploadedFile: IUploadedFile) => {
    if (uploaded.recordId === uploadedFile.recordId) return uploadedFile;
    else return uploaded;
  };

  /**
   * Asynchronously processes remapped files by making API requests for staging, processing, and cleaning.
   * Updates the status of the processed file accordingly.
   * @param remapped - The remapped file object to be processed.
   * @param uploadedFiles - Array of uploaded files.
   */
  const processRemapping = async (remapped: IUploadedFile, uploadedFiles: IUploadedFile[]) => {
    const uploadedFile: IUploadedFile = { ...remapped };

    const apis: IProcessingAPIs  = processingAPIs[uploadedFile.documentType ?? ''];
    if (apis === undefined) {
      console.log('API ENDPOINTS NOT FOUND, PROCESSING OF MAP FAILED');
      showToaster('error', `Failed in fetching processing endpoints!`);
      return uploadedFile;
    }

    const apiRequests = [
      {
        url: apis.STAGE,
        method: POST,
        params: { documentId: uploadedFile.recordId, isStillMapping: false, batchSize: BATCH_SIZE },
          // no need to pass mapped since data is already present in the staging tables
        data: []
      },
      {
        url: apis.PROCESS,
        method: POST,
        params: {
          documentId: uploadedFile.recordId,
          bbPeriodId: uploadedFile.bbPeriodFk,
          arCollateralId: uploadedFile.arCollateralFk,
          shouldReplace: uploadedFile.shouldReplace,
          batchSize: BATCH_SIZE,
          isRemapped: true,
        }
      },
      {
        url: apis.CLEAN,
        method: DELETE,
        params: {
          documentId: uploadedFile.recordId,
          isStillMapping: false,
          batchSize: BATCH_SIZE
        }
      }
    ];

    try {
      const statusCode: number = await startRequests(apiRequests);

      if (statusCode === 204) {
        await updateProcessedFile(uploadedFile);
        uploadedFile.processed = true;
        uploadedFile.dataMappingStatus = 'Applied';
        showToaster('success', `Calculation Mappings have been appplied!`);
      } else {
        const cleanStaging = apiRequests[2];
        await axiosInstance.request(cleanStaging);
        uploadedFile.dataMappingStatus = 'Remapped';
      }
    } catch (error) {
      console.log('DOCUMENT PROCESSING ERROR: ', error);
    } finally {
      uploadedFile.isLoading = false;
    }

    setSelectedFile && setSelectedFile(uploadedFile);
    setUploadedFiles(uploadedFiles.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    filteredRows && setFilteredRows && setFilteredRows(filteredRows.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    importedFiles && setImportedFiles && setImportedFiles(importedFiles.map(uploaded => mapUpdatedFile(uploaded, uploadedFile)));
    return uploadedFile;
  };

  /**
   * This function initiates a series of API requests sequentially and returns the status code of the last completed request.
   * @param apiRequests An array of API request objects to be executed sequentially.
   * @returns The HTTP status code of the last completed API request.
   */
  const startRequests = async (apiRequests: IProcessingRequests[]) => {
    let statusCode: number = 0;

    for (const apiRequest of apiRequests) {
      try {
        const response = await axiosInstance.request(apiRequest);
        statusCode = response.status;
      } catch (error) {
        break;
      }
    }

    return statusCode;
  };
  
  /**
   * This function sends a request to update a processed document after post-processing.
   * @param document The processed document to be updated.
   */
  const updateProcessedFile = async (document : IUploadedFile) => {
    try {
      await axiosInstance.request({
        url: `${API_DOMAIN}/document/postProcess`,
        method: PUT,
        params: { documentId: document.recordId }
      })
    } catch (error) {
      console.log('POST PROCESSED UPDATE ERROR: ', error)
    }
  };

  /**
   * This function sets the component's dirty state based on the Formik form's dirty property.
   * @param formik The Formik form props.
   * @returns Early return if the formik is null.
   */
  const formikRef = (formik: FormikProps<{ calculationMappings: ICalculationMapping[]; }>) => {
    if (formik === null) { return; }
    setIsDirty(formik.dirty);
  };

  /**
   * This function returns the table body component based on the current loading state and calculation mappings.
   * @returns A JSX element representing the table body or a message if there are no calculation mappings.
   */
  const getTableBody = () => {
    if (isLoading) {
      return (
        <TableContainer sx={{ width: '100%' }}>
          <Table>
            <TableBody>
              {[...Array(5)].map((row) => (
                <TableRow key={getUniqueKeys(row)}>
                  {[...Array(3)].map((cell) => (<TableCell key={getUniqueKeys(cell)} sx={styles.tableCell}><Skeleton /></TableCell>))}
                </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else if (calculationMappings.length) {
      return (
        <CalculationMappingTable
          rfCategories={rfCategories}
          calculationMappings={calculationMappings}
          isLoading={isLoading}
        />
      )
    } else {
      return (
        <Box sx={styles.emptyContainer}>
          <Typography tabIndex={0} sx={styles.verbiage}>The calculation mapping is empty.</Typography>
        </Box>
      )
    }
  };

  /**
   * This function returns a set of buttons based on the provided Formik form props.
   * @param formik The Formik form props.
   * @returns A JSX element representing the set of buttons.
   */
  const getButtons = (formik: FormikProps<{ calculationMappings: ICalculationMapping[]; }>, initialized: boolean) => {
    const disabledSave = !initialized && formik.isSubmitting;

    return (
      <Box sx={styles.buttonsContainer}>
        <Link
          data-testid='stepper-back-button'
          onClick={() => {
            setButtonClicked('back');
            if (formik.dirty) setShowPrompt(true);
            else setActiveStep(1)
          }}
          sx={styles.buttonBack}
        >
          Back
        </Link>
        <Box sx={styles.saveAndBackContainer}>
          <Button
            variant='outlined'
            onClick={() => {
              setButtonClicked('cancel');
              if (formik.dirty) setShowPrompt(true);
              else handleClose();
            }}
            sx={styles.buttonCancel}
          >
            Cancel
          </Button>
          {formik.values.calculationMappings.some(mappings => !mappings.rfCategoryId) ? 
            <Tooltip title='Complete the fields first to save changes' placement='top'>
              <span>
                <DisabledComponentsContainer isDisabled={true}>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={true}
                    sx={styles.buttonSave}
                  >
                    Save Changes
                  </Button>
                </DisabledComponentsContainer>
              </span>
            </Tooltip> : 
            <DisabledComponentsContainer isDisabled={disabledSave}>
              <Button
                variant='contained'
                type='submit'
                disabled={disabledSave}
                aria-label={disabledSave ? 'Save changes button disabled' : 'Save changes'}
                sx={styles.buttonSave}
              >
                {formik.isSubmitting ? <CircularProgress size={15} /> : 'Save Changes'}
              </Button>
            </DisabledComponentsContainer>
            }
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={styles.calculationMappingContainer}>
      <Formik
        enableReinitialize
        initialValues={{calculationMappings}}
        onSubmit={(values: {calculationMappings: ICalculationMapping[]}) => {
          handleSubmit(values, isDirty)
        }}
        innerRef={formikRef}
      >
          {formik => (
            <Form>
              <TableContainer sx={styles.containerTable}>
                {getTableBody()}
              </TableContainer>
              {getButtons(formik, initialized)}
              <ConfirmModal
                open={showPrompt}
                onClose={() => {
                  setShowPrompt(false);
                  if (buttonClicked === 'cancel') handleClose();
                  else setActiveStep(1);
                }}
                onConfirm={() => setShowPrompt(false)}
                onButtonClose={() => setShowPrompt(false)}
                promptChecker
                title={PROMPT.NAV_PROMPT.title}
                description={`${PROMPT.NAV_PROMPT.description}`}
                yesButtonText={PROMPT.NAV_PROMPT.keepEditing}
                noButtonText={PROMPT.NAV_PROMPT.discardChanges}
                confirmOnly
              />
            </Form>
          )}
      </Formik>
    </Box>
  )
}

export default CalculationMapping;