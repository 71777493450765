import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Alert, Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormLabel, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import styles from "./styles";
import { addDocumentAttribute, checkIfBlankString, cleanPayload, customAsyncStagingRequest, deleteDocumentAttribute, displayTextWidth, excludeRows, formatDate, getDocumentAttributesByBorrowerId, getTableRowsAfterPaging, getUniqueKeys, haveSameContentsAndPosition, haveSamePositions, stringCurrencyToNumber } from "../../../../../../../../utility/helper";
import { CheckCircleRounded, CircleOutlined } from "@mui/icons-material";
import { BATCH_SIZE, CONCURRENCY_OF_STAGING_REQUESTS, DELETE, GET, POST, PROMPT, PUT, fieldsPerDocumentType } from "../../../../../../../../utility/constants";
import { IDocumentAttribute, IDocumentAttributePayload } from "../../../../../../../../interfaces/dataMap";
import AddNewFieldModal, { IFormikNewFieldFieldValues } from "../../../../../../../../components/file-import/modals/add-new-field";
import { IDataMapping, IMappingStatus, IDocument, IDocumentIssue, IUploadedFile } from "../../../../../../../../interfaces/fileimport";
import ConfirmModal from "../../../../../../../../components/modals/confirm-modal";
import LoadingBackdrop from "../../../../../../../../components/common/loading-backdrop";
import WarningModal from "../../../../../../../../components/file-import/modals/warning-modal";
import axiosInstance from "../../../../../../../../service/axiosInstance";
import { checkingIssueAPIs, fileImportAPI, processingAPIs, stagingAPIs } from "../../../../../../../../service/api";
import { FileImportContext, IFileImportContext } from "../../../../../../../../context/fileImportContext";
import { IProcessingAPIs } from "../../../../import-tab";
import { amtHeaders, dateHeaders } from "../../..";
import HeaderSelect from "./header-select";
import { IMappingModalProps } from "..";
import { getLabelDisplayedRows, getLabelRowsPerPage } from "../../../../../../../../components/client-settings/tabs/tab-panel-contents/pagination";

export interface IFieldMappingProps extends IMappingModalProps {
  setActiveStep         : Dispatch<SetStateAction<number>>;
  isGLTransaction       : boolean;
  setIsDirty            : Dispatch<SetStateAction<boolean>>;
  selectedSheet         : string | null;
  excludedRowsEndRowNum : number | undefined;
  isAutoMapped          : boolean;
  setIsAutoMapped       : Dispatch<SetStateAction<boolean>>;
}

const apTrxFileTypes: string[] = ['Vendor List', 'AP Aging'];
const arTrxFileTypes: string[] = ['Customer List', 'AR Aging'];
const calcMappingHeaders: string[] = ['glTrxType', 'glTrxAmt1', 'glTrxAmt2'];


/**
 * This function returns an initial payload object based on the document type of an uploaded file.
 * @param uploaded The uploaded file for which to generate the initial payload.
 * @returns An initial payload object with relevant properties based on the document type.
 */
export const getInitialPayloadByDocType = (uploaded: IUploadedFile) => {
  const customers = {
    borrowerId: uploaded.borrowerFk,
    arCollateralId: uploaded.arCollateralFk,
    documentId: uploaded.recordId,
  };

  const transactional = {
    borrowerId: uploaded.borrowerFk,
    arCollateralId: uploaded.arCollateralFk,
    documentId: uploaded.recordId,
    bbPeriodId: uploaded.bbPeriodFk,
    shouldReplace: uploaded.shouldReplace,
  };

  const payloads = {
    'AR Aging': transactional,
    'AP Aging': transactional,
    'GL Transaction': transactional,
    'Customer List': customers,
    'Vendor List': customers,
  }

  return payloads[uploaded.documentType as string];
};

/**
 * This function handles the amount mapping.
 * @param value The value of a document.
 * @returns A parsed amount of the value.
 */
const handleAmountMapping = (value: string) => {
  const amount = stringCurrencyToNumber(value.trim());
  if (isNaN(amount)) return 0.00;
  else return amount;
}

/**
 * This function updates the payload object with user-defined fields based on the document type.
 * @param documentType The document type.
 * @param fields The user-defined fields to consider.
 * @param header The header name to update.
 * @param payload The payload object to update.
 * @param value The value to set for the specified header.
 */
export const handleUserDefinedFields = (documentType: string, fields: { name: string; value: string; }[], header: string, payload: any, value: string, dataType: string | undefined) => {
  if (fields.some((field) => field.value === header)) {
    payload[header] = value.trim();
  } else {
    const fieldName = documentType === 'AR Aging' || documentType === 'AP Aging' || documentType === 'GL Transaction' ?
      'transactionJsonAttributes' :
      'attributesJson';
      switch (dataType) {
        case 'date':
          payload[fieldName] = {
            ...(payload[fieldName]),
            [header]: formatDate(value.trim(), 'YYYY-MM-DD'),
          };
          break;
        case 'number':
          payload[fieldName] = {
            ...(payload[fieldName]),
            [header]: handleAmountMapping(value),
          };
          break;
        default:
          payload[fieldName] = {
            ...(payload[fieldName]),
            [header]: value.trim(),
          };
      }
  }
};


/**
 * Component for the Field Mapping Step of the Mapping Modal.
 * @param props The props for the Field Mapping Step of the Mapping Modal.
 * @returns A component for the Field Mapping Step of the Mapping Modal.
 */
const FieldMapping: FC<IFieldMappingProps> = (props) => {
  const {
    handleClose, showToaster, setIsDirty,
    requiredFields, optionalFields,
    uploadedFile, setActiveStep, isGLTransaction,
    selectedSheet, excludedRowsEndRowNum, isAutoMapped,
    setIsAutoMapped, setSelectedFile,
    importedFiles, setImportedFiles,
    filteredRows, setFilteredRows,
  }                                                       = props;
  const { uploadedFiles, setUploadedFiles,
          userDefinedFields, isInLandingPage,
          selectedClient, setUserDefinedFields }          = useContext(FileImportContext) as IFileImportContext;
  const [headers, setHeaders]                             = useState<(string | null)[]>([]);
  const [filteredValues, setFilteredValues]               = useState<string[][]>([]);
  const [documentIssues, setDocumentIssues]               = useState<IDocumentIssue[]>([]);
  const [updatedUploaded, setUpdatedUploaded]             = useState<IUploadedFile>();
  const [isSaving, setIsSaving]                           = useState<boolean>(false);
  const [warningModalOpen, setWarningModalOpen]           = useState<boolean>(false);
  const [newFieldModalOpen, setNewFieldModalOpen]         = useState<boolean>(false);
  const [addedFields, setAddedFields]                     = useState<IDocumentAttribute[]>([]);
  const [deleteFieldModalProps, setDeleteFieldModalProps] = useState({ isOpen: false, deleteName: '', deleteRecordId: 0 });
  const [page, setPage]                                   = useState<number>(0);
  const [rowsPerPage, setRowsPerPage]                     = useState<number>(5);
  const [isLoading, setIsLoading]                         = useState<boolean>(false);
  const [changeAmt2Sign, setChangeAmt2Sign]               = useState<boolean>(false);
  const [showPrompt, setShowPrompt]                       = useState<boolean>(false);
  const [warningModalSaving, setWarningModalSaving]       = useState<boolean>(false);
  const [buttonClicked, setButtonClicked]                 = useState<'save' | 'cancel' | 'back'>('save');
  const [addedUdfIndex, setAddedUdfIndex]                 = useState<number>(0);

  /**
   * This memoized value returns a boolean value.
   * True if the client is either a Parent Client or a Child Client.
   * False if it does not have any relationship.
   */
  const hasUpcRelationship = useMemo(() => 
    Boolean(selectedClient?.parentClient) ||
    Boolean(selectedClient?.parentClientFk)
  , [selectedClient]);

  /**
   * This useEffect handles various operations when the uploadedFile changes.
   */
  useEffect(() => {
    (async() => {
      await getDocumentAttributes(uploadedFile);
    })();

    setHeaders(uploadedFile.defaultMapping ?? []);
    setDocumentIssues(uploadedFile.documentIssues ?? []);
    setChangeAmt2Sign(Boolean(uploadedFile.dataMappingChangeAmt2Sign));

    const filtered = excludeRows(uploadedFile.values ?? [], excludedRowsEndRowNum);
    setFilteredValues(filtered);
  }, [uploadedFile]);

  /**
   * A memoized selector that filters document issues to retrieve only errors.
   */
  const documentIssueErrors: IDocumentIssue[] = useMemo(() => documentIssues.filter(issue => issue.issueType === 'error'), [documentIssues]);

  /**
   * A memoized selector that filters document issues to retrieve only warnings.
   */
  const documentIssueWarnings: IDocumentIssue[] = useMemo(() => documentIssues.filter(issue => issue.issueType === 'warning'), [documentIssues]);

  /**
   * A memoized boolean value indicating whether the 'changeAmt2Sign' value matches the 'dataMappingChangeAmt2Sign' value in the uploaded file.
   */
  const notChangedAmt2Sign: boolean = useMemo(() =>
    changeAmt2Sign === Boolean(uploadedFile.dataMappingChangeAmt2Sign)
  , [changeAmt2Sign, uploadedFile.dataMappingChangeAmt2Sign]);

  /**
   * A memoized boolean value indicating whether there is no mapping change in the uploaded file.
   */
  const noMappingChange: boolean = useMemo(() => {
    const noSheetChange = !uploadedFile.isExcelFile || selectedSheet === uploadedFile.selectedSheet;
    const hasExcluded = uploadedFile.excludedRowsEndRowNum !== undefined || excludedRowsEndRowNum !== undefined;
    const noExcludedRowChange = !hasExcluded || excludedRowsEndRowNum === uploadedFile.excludedRowsEndRowNum;
    const noHeaderChange = haveSameContentsAndPosition(headers, uploadedFile.defaultMapping ?? []);
    return noHeaderChange && noSheetChange && noExcludedRowChange;
  }, [headers, uploadedFile.defaultMapping, uploadedFile.selectedSheet])

  /**
   * A memoized boolean value indicating whether field mapping changes are dirty.
   */
  const isFieldMappingDirty: boolean = useMemo(() => {
    return isGLTransaction ? !(noMappingChange && notChangedAmt2Sign) : !noMappingChange;
  }, [isGLTransaction, noMappingChange, notChangedAmt2Sign])

  /**
   * A memoized boolean value indicating whether to reset the calculation mapping
   */
  const resetCalcMapping: boolean = useMemo(() => {
    const samePositions = haveSamePositions(calcMappingHeaders, uploadedFile.defaultMapping ?? [], headers);
    const isAmount2Mapped = headers.find(header => header === 'glTrxAmt2');
    if (isAmount2Mapped) {
      return !samePositions || !notChangedAmt2Sign;
    } else {
      return !samePositions
    }
  }, [headers, uploadedFile.defaultMapping, notChangedAmt2Sign])

  /**
   * This useEffect hook updates the `isDirty` state based on changes in field mapping dirtiness.
   */
  useEffect(() => {
    setIsDirty(isFieldMappingDirty);
  }, [isFieldMappingDirty]);

  /**
   * A memoized value that determines whether a certain action should be disabled.
   */
  const isDisabled: boolean = useMemo(() => {
    if (isAutoMapped) return false;

    const filled = requiredFields.every((field: string) => headers.includes(field));
    return !filled || documentIssueErrors.length > 0 || (!isGLTransaction && noMappingChange)
  }, [requiredFields, documentIssueErrors, noMappingChange]);

  /**
   * A memoized array of header options based on the document type of an uploaded file.
   */
  const headerOptions = useMemo(() => {
    return fieldsPerDocumentType[uploadedFile.documentType ?? ''] ?? [];
  }, [uploadedFile])

  /**
   * Callback function to handle a page change event.
   */
  const handleChangePage = useCallback((_event: unknown, newPage: number) => setPage(newPage), []);

  /**
   * A callback function for handling a change in the number of rows per page in a paginated data view.
   */
  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  /**
   * This function generates a JSX element displaying required fields with icons based on the document type and
   * isGLTransaction flag.
   * @returns The JSX element representing the required fields display, or null
   * if the document type or conditions do not match any case.
   */
  const getRequiredFieldsTextDisplay = () => {
    const requiredTextFieldDisplay = {
      'AR Aging': (
        <Box sx={styles.requiredFieldsStyles}>
          {getCircleIcon('customerName', ' Customer Name')}
          {getCircleIcon('arTrxNo', ' Document #')}
          {getCircleIcon('arTrxDate', ' Invoice Date')}
          {getCircleIcon('arTrxAmt', ' Amount')}
        </Box>
      ),
      'AP Aging': (
        <Box sx={styles.requiredFieldsStyles}>
          {getCircleIcon('vendorName', ' Vendor Name')}
          {getCircleIcon('apTrxAmt', ' Amount')}
        </Box>
      ),
      'Customer List': (
        <Box sx={styles.requiredFieldsStyles}>
          {getCircleIcon('custName', ' Customer Name')} 
        </Box>
      ),
      'Vendor List': (
        <Box sx={styles.requiredFieldsStyles}>
          {getCircleIcon('vendorName', ' Vendor Name')}
        </Box>
      ),
      'GL Transaction': (
        <Box sx={styles.requiredFieldsStyles}>
          {getCircleIcon('glTrxType', ' Transaction Type')}
          {getCircleIcon('glTrxDate', ' Date')}
          {getCircleIcon('glTrxAmt1', ' Amount1')}
        </Box>
      )
    };

    return requiredTextFieldDisplay[uploadedFile.documentType ?? ''];
  };

  /**
   * This function generates a JSX element representing a circle icon with a label, depending on whether
   * the specified field is present in the headers array.
   * @param field The field name to check against the headers array.
   * @param label The label associated with the circle icon.
   * @returns The JSX element representing the circle icon with the label.
   */
  const getCircleIcon = (field: string, label: string ) => {
    const hasFieldInRequiredFields = headers.some(requiredField => requiredField === field);
    return hasFieldInRequiredFields ? 
    <Typography tabIndex={0} sx={styles.requiredNoteHighlight}>
      <CheckCircleRounded sx={styles.checkCircleStyle}/>{label}  
    </Typography> :   
    <Typography tabIndex={0} sx={styles.requiredNote}>      
      <CircleOutlined sx={styles.circleOutlinedStyle}/>{label}
    </Typography>
  };

  /**
   * This function generates an informational alert based on the 'documentType' of the uploaded file.
   * @returns The JSX element representing the informational alert,
   * or null if the document type doesn't match any expected cases.
   */
  const getAlertForIdAutoPopulation = () => {
    if (apTrxFileTypes.includes(uploadedFile.documentType ?? '')) {
      return (  
        <Alert severity="info" tabIndex={0} sx={{ ...styles.info}}>
          <b>Vendor ID</b> will be auto-populated with the <b>Vendor Name</b> in the event there is no field mapped to <b>Vendor ID</b>.
        </Alert>
      );
    } else if (arTrxFileTypes.includes(uploadedFile.documentType ?? '')) {
      return (
        <Alert severity="info" tabIndex={0} sx={{ ...styles.info}}>
          <b>Customer ID</b> will be auto-populated with the <b>Customer Name</b> in the event there is no field mapped to <b>Customer ID</b>.
        </Alert>
      );
    } else {
      return null
    }
  }

  /**
   * This function generates a table body JSX element based on the current state and data.
   * @returns The JSX element representing the table body or null if no data is available.
   */
  const getTableBody = () => {
    if (filteredValues.length) {
      return (
        <Table stickyHeader aria-label='customized table' size='small'>
          <TableHead>
            <TableRow>
              {headers.map((header: string | null, index: number) => 
                <TableCell key={getUniqueKeys(header)} sx={{ ...styles.tableHead, ...styles.leftAlignedText }}>
                  <Tooltip title={getHeaderTooltips(header)}>
                    <FormLabel htmlFor='date-created-box' sx={{...styles.tableHeaderText}}>
                      {getHeaderData(header, index)}
                    </FormLabel>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getTableRowsAfterPaging(page, rowsPerPage, filteredValues).map((values: string[]) => 
              <TableRow key={getUniqueKeys(values[0])} sx={styles.tableRow}>
                {values.map((value: string) =>
                  <TableCell tabIndex={0} key={getUniqueKeys(value)} component='th' scope='row' sx={{...styles.tableCell, ...styles.leftAlignedText}}>
                    {value.length >= 15 ? <Tooltip title={value}><Box>{`${value.slice(0, 15)}...`}</Box></Tooltip> : value}
                  </TableCell>)}
              </TableRow>)}
          </TableBody>
        </Table>
      )
    } else {
      return (
        <Box sx={styles.emptyContainer}>
          <Typography tabIndex={0} sx={styles.verbiage}>The document is empty.</Typography>
        </Box>
      )
    }
  };

  /**
   * This function retrieves a tooltip text for a table header based on the header's mapping field.
   * @param header The header value for which to retrieve the tooltip.
   * @returns The tooltip text, which is the mapping field name if it exceeds 73 characters, or an empty string if not.
   */
  const getHeaderTooltips = (header: string | null) => {
    const mappingField = headerOptions.find(field => field.value === header);
    if (mappingField && displayTextWidth(mappingField.name) > 73) return mappingField.name;
    else return '';
  };

  /**
   * This function generates a JSX element for rendering header data within a table cell.
   * @param header The header value to be rendered.
   * @param index The index of the header in the headers array.
   * @returns The JSX element representing the header data.
   */
  const getHeaderData = (header: string | null, index: number) => {
    return (
      <HeaderSelect
        index={index}
        header={header}
        headers={headers}
        addedFields={addedFields}
        headerOptions={headerOptions}
        handleHeaderChange={handleHeaderChange}
        handleClickDelete={handleClickDelete}
        setNewFieldModalOpenCallback={setNewFieldModalOpenCallback}
        setAddedUdfIndex={setAddedUdfIndex}
        {...props}
      />
    );
  };

  /**
   * This function handles changes in the header value at a specified index in the headers array.
   * @param newHeader The new header value to be set.
   * @param index The index at which the header value should be updated.
   */
  const handleHeaderChange = (newHeader: string | null, index: number) => {
    const newHeaders = [...headers];
    newHeaders[index] = newHeader;
    setHeaders(newHeaders);
    setDocumentIssues([]);
  };

  /**
   * This function retrieves and sets document attributes based on the uploaded file's borrower and document type.
   * @param uploadedFile The uploaded file object containing borrower and document type information.
   */
  const getDocumentAttributes = async (uploadedFile: IUploadedFile) => {
    try {
      const response = await getDocumentAttributesByBorrowerId(selectedClient?.parentClientFk ?? uploadedFile.borrowerFk);
      const documentAttributes: IDocumentAttribute[] = response.data.map((docAttribute: any) => (
        {
          recordId: docAttribute.documentAttributeId,
          fieldName: docAttribute.fieldName,
          value: docAttribute.fieldName,
          fieldType: docAttribute.fieldType,
        }
      ));

      setAddedFields(documentAttributes);
    } catch (error) {
      console.log('DOCUMENT ATTRIBUTES REQUEST ERROR', error);
    }
  };

  /**
   * This function handles a click event to initiate the deletion of a field by opening a delete field modal.
   * @param deleteName The name of the field to be deleted.
   * @param deleteRecordId The unique record identifier of the field to be deleted.
   */
  const handleClickDelete = (deleteName: string, deleteRecordId: number) => {
    setDeleteFieldModalProps({
      isOpen: true,
      deleteName: deleteName,
      deleteRecordId: deleteRecordId,
    });
  };

  /**
   * This function returns a callback function that sets the state to open or close the new field modal.
   * @param newValue The new value to set for the new field modal open state.
   * @returns A callback function that sets the new field modal open state.
   */
  const setNewFieldModalOpenCallback = (newValue: boolean) => () => setNewFieldModalOpen(newValue);

  /**
   * This function creates a new document attribute based on form values and adds it to the list of added fields.
   * @param values The form values containing field information.
   */
  const createDocumentAttribute = async (values: IFormikNewFieldFieldValues) => {
    const payload = {
      borrowerId: selectedClient?.parentClientFk ?? uploadedFile.borrowerFk,
      documentTypeId: uploadedFile.documentTypeFk,
      fieldName: values.fieldName,
      fieldType: values.dataType?.value as string,
    };

    try {
      setIsLoading(true);
      const response = await addDocumentAttribute(payload);
      const added: IDocumentAttributePayload = response.data;
      const mapped: IDocumentAttribute = {
        recordId: added.documentAttributeId ?? 0,
        fieldName: added.fieldName,
        value: added.fieldName,
        fieldType: added.fieldType,
      }

      const updatedList: IDocumentAttribute[] = [...addedFields, mapped];
      setAddedFields(updatedList);
      showToaster('success', `${values.fieldName} has been added.`);
      handleHeaderChange(added.fieldName, addedUdfIndex);
    } catch (error) {
      console.log('ADD DOCUMENT REQUEST ERROR', error);
      showToaster('error', `Request failed. Please try again.`);
    } finally {
      setIsLoading(false);
      const borrowerId = selectedClient?.parentClientFk ?? uploadedFile.borrowerFk;
      const udf  = await getDocumentAttributesByBorrowerId(borrowerId) as { data: IDocumentAttribute[] };
      setUserDefinedFields(udf.data);
    }
  };

  /**
   * This function handles the deletion of a document attribute based on the deleteFieldModalProps and updates the addedFields list.
   */
  const handleDeleteField = async () => {
    try {
      setDeleteFieldModalProps({ ...deleteFieldModalProps, isOpen: false });
      setIsLoading(true);
      await deleteDocumentAttribute(deleteFieldModalProps.deleteRecordId);
      const filtered: IDocumentAttribute[] = addedFields.filter(
        field => field.recordId !== deleteFieldModalProps.deleteRecordId
      );
      setAddedFields(filtered);
      showToaster('success', `${deleteFieldModalProps.deleteName} has been deleted`);
    }
    catch (error) {
      console.log('ERROR DELETE DOCUMENT ATTRIBUTE', error);
      showToaster('error', 'Request failed. Please, try again.');
    }
    finally {
      setIsLoading(false);
    }
  };

  /**
   * This function handles the process of saving data mapping and file updates.
   * @param uploadedFile The uploaded file object.
   */
  const handleSave = async (uploadedFile: IUploadedFile, selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    setButtonClicked('save');
    setIsSaving(true);
    const issues: IDocumentIssue[] | undefined = await handleChecking(uploadedFile);

    if (issues) {
      setUpdatedUploaded({...uploadedFile});
      const newErrors = issues?.filter(issue => issue.issueType === 'error');
      const newWarnings = issues?.filter(issue => issue.issueType === 'warning');

      if (newErrors?.length) {
        showToaster('error', newErrors[0].issueMessage ?? '');
      } else if (newWarnings?.length) {
        setWarningModalOpen(true);
      } else {
        await proceedSaving(uploadedFile, issues, selectedSheet, excludedRowsEndRowNum);
      }
    }

    setIsSaving(false);
  };

  /**
   * This function performs document checking operations based on the provided uploaded file.
   * @param uploadedFile The uploaded file object.
   * @returns An array of document issues if found, or undefined if no issues were detected.
   */
  const handleChecking = async (uploadedFile: IUploadedFile) => {
    const apis: IProcessingAPIs  = processingAPIs[uploadedFile.documentType as string];

    const isStaged: boolean | undefined = await checkIfStaged(uploadedFile);
    if (isStaged === true) {
      const cleanedPrevious: IUploadedFile | undefined = await cleanStaged(uploadedFile, apis);
      if (cleanedPrevious) {
        return proceedToDataMapping(uploadedFile, filteredValues, headers, isStaged);
      }
    } else if (isStaged === false) {
      return proceedToDataMapping(uploadedFile, filteredValues, headers, isStaged);
    } else {
      return undefined;
    }
  }

  /**
   * This function asynchronously checks if an uploaded file is already staged and returns a boolean indicating its status.
   * @param uploaded The uploaded file to check for staging.
   * @returns A promise that resolves to `true` if the file is staged, `false` if it's not, or `undefined` if an error occurs.
   */
  const checkIfStaged = async (uploaded: IUploadedFile) => {
    try {
      const response = await axiosInstance.request({
        url: stagingAPIs[uploaded.documentType as string].IS_EXISTING,
        method: GET,
        params: { documentId: uploaded.recordId }
      });
      const isExisting: boolean = response.data;
      return isExisting;
    } catch (error) {
      console.log('CHECKING STAGED ERROR: ', error);
    }
  };

  /**
   * This function cleans the previous staged data for the provided uploaded file using the specified APIs.
   * @param uploadedFile The uploaded file object.
   * @param apis An object containing processing APIs for the document type.
   * @returns The updated uploaded file object if the cleaning operation is successful, or undefined if an error occurs.
   */
  const cleanStaged = async (uploadedFile: IUploadedFile, apis: IProcessingAPIs) => {
    try {
      const response = await axiosInstance.request({
        url: apis.CLEAN,
        method: DELETE,
        params: { documentId: uploadedFile.recordId, isStillMapping: true, batchSize: BATCH_SIZE }
      });
      if (response.status === 204) {
        return uploadedFile;
      } else {
        showToaster('error', 'Error in cleaning previous staged');
        setIsSaving(false);
        throw new Error();
      }
    } catch (error) {
      console.log('CLEANING PREVIOUS STAGED: ', error);
      showToaster('error', 'Error in cleaning previous staged');
      setIsSaving(false);
    }
  };

  /**
   * This function handles the application of data mapping, staging of document, and checking of issues.
   * @param uploadedFile The uploaded file object.
   * @param filteredValues The filtered values of the parsed file.
   * @param headers The mappings to be applied.
   * @returns An array of document issues if found, or undefined if no issues were detected.
   */
  const proceedToDataMapping = async (
    uploadedFile: IUploadedFile,
    filteredValues: string[][],
    headers: (string | null)[],
    isStaged?: boolean
  ) => {
    const dataMapped: any[] = applyDataMapping(uploadedFile, filteredValues, headers);
    if (dataMapped.length === 0) {
      showToaster(
        'error',
        isGLTransaction ? 'There are no records to map!' : 'There are no records to import!'
      );
      return;
    }

    const stagedStatusCode: number | undefined = await stageDocument(uploadedFile, dataMapped);
    if (stagedStatusCode === 201) {
      if (isStaged) {
        uploadedFile.origMappingChanged = true;
        setUpdatedUploaded({...uploadedFile});
      }

      const issues: IDocumentIssue[] | undefined = await checkDocumentIssues(uploadedFile);
      if (issues) {
        setDocumentIssues(issues);
        return issues;
      }
    }
  }

  /**
   * This function applies data mapping to sheet values and generates a list of data payloads for further processing.
   * @param uploaded The uploaded file object.
   * @param sheetValues The values from the sheet.
   * @param headers The headers corresponding to the sheet values.
   * @returns An array of data payloads generated based on the data mapping.
   */
  const applyDataMapping = (uploaded: IUploadedFile, sheetValues: string[][], headers: (string | null)[]) => {
    const requestList: any[] = [];
    const fields = fieldsPerDocumentType[uploaded.documentType as string];

    sheetValues.forEach((values: string[], valIndex: number) => {
      if (valIndex !== 0) {
        const payload: any = handleHeaderMapping(uploaded, values, headers, fields);
        const cleaned = cleanPayload(payload);
        const isPayloadEmpty: boolean = Object.keys(cleaned).length === 0;

        if (!isPayloadEmpty) {
          const addedIds = {...cleaned, ...getInitialPayloadByDocType(uploaded)};
          requestList.push(addedIds);
        }
      }
    })

    return requestList;
  };
  
  /**
   * This function maps header values to corresponding payload fields based on the uploaded file's document type.
   * @param uploaded The uploaded file object.
   * @param values The values corresponding to headers.
   * @param headers The headers corresponding to the values.
   * @param fields An array of fields specific to the document type.
   * @returns A payload object with mapped fields.
   */
  const handleHeaderMapping = (uploaded: IUploadedFile, values: string[], headers: (string | null)[], fields: { name: string; value: string; }[]) => {
    const payload: any = {};
    headers.forEach((header: string | null, headerIndex: number) => {
      const value: string = values[headerIndex];
  
      if (header && !checkIfBlankString(value)) {
        if (dateHeaders.includes(header)) {
          payload[header] = formatDate(value.trim(), 'YYYY-MM-DD');
        } else if (amtHeaders.includes(header)) {
          payload[header] = handleAmountMapping(value);
        } else if (header === 'glTrxAmt2') {
          const amount2 = handleAmountMapping(value);
          payload[header] = changeAmt2Sign ? -amount2 : amount2
        } else {
          const dataType = userDefinedFields?.find(field => header === field.fieldName)?.fieldType ?? '';
          handleUserDefinedFields(uploaded.documentType as string, fields, header, payload, value, dataType);
        }
      }
    })
  
    return payload;
  };

  /**
   * This function stages the document data for processing using specified APIs and data chunks.
   * @param uploaded The uploaded file object.
   * @param dataMapped An array of data payloads to be staged.
   * @returns The HTTP status code indicating the result of the staging process.
   */
  const stageDocument = async (uploaded: IUploadedFile, dataMapped: any[]) => {
    const docType = uploaded.documentType as string;
    const apis: IProcessingAPIs  = processingAPIs[docType];

    const stagingRequest =
      {
        url: apis.STAGE,
        method: POST,
        params: {
          documentId: uploaded.recordId,
          isStillMapping: true,
          batchSize: BATCH_SIZE,
          forUpcCustomers: hasUpcRelationship,
          parentClientId: selectedClient?.parentClientFk
        },
      };

    const apiRequests = [
      {...stagingRequest},
      {
        url: apis.CLEAN,
        method: DELETE,
        params: { documentId: uploaded.recordId, isStillMapping: true, batchSize: BATCH_SIZE }
      }
    ];

    try {
      // Last argument determines concurrency of requests
      const statusCode: number =
        await customAsyncStagingRequest(
          apiRequests,
          dataMapped,
          BATCH_SIZE,
          CONCURRENCY_OF_STAGING_REQUESTS
        )
      return statusCode;
    } catch (error) {
      showToaster('error', 'Error in staging the document.');
      console.log('STAGING UPLOADED ERROR: ', error);
      setIsSaving(false);

      const cleanStaging = apiRequests[1];

      try {
        await axiosInstance.request(cleanStaging);
      } catch (error) {
        console.log('CLEANUP ERROR: ', error);
        showToaster('error', 'Error in cleaning up the staged recrods.');
      }
    }
  };

  /**
   * This function checks and retrieves document issues for the uploaded file based on document type.
   * @param uploadedFile The uploaded file object.
   * @returns An array of document issues if found, or undefined if an error occurs.
   */
  const checkDocumentIssues = async (uploadedFile: IUploadedFile) => {
    try {
      const checkingIssueEndpt = checkingIssueAPIs[uploadedFile.documentType as string];

      const response = await axiosInstance.request({
        url: checkingIssueEndpt,
        method: POST,
        params: { borrowerId: uploadedFile.borrowerFk, documentId: uploadedFile.recordId },
        data: { requiredFields, optionalFields }
      });
      const issues: IDocumentIssue[] = response.data;
      return issues;
    } catch (error) {
      console.log('CHECKING DOCUMENT ISSUE ERROR: ', error);
      showToaster('error', 'There was an error in checking issues.');
      setIsSaving(false);
    }
  };
  
  /**
   * This function handles the action to proceed with saving, either directly or after field mapping updates.
   */
  const handleProceedSavingFromModal = async () => {
    if (isFieldMappingDirty || isAutoMapped) {
      await proceedSaving(updatedUploaded as IUploadedFile, documentIssues, selectedSheet, excludedRowsEndRowNum);
    } else {
      setActiveStep(2);
    }
  };

  /**
   * This function proceeds with saving the data mapping and handling document issues for the uploaded file.
   * @param uploadedFile The uploaded file object.
   * @param checkedIssues An array of checked document issues.
   */
  const proceedSaving = async (uploadedFile: IUploadedFile, checkedIssues: IDocumentIssue[], selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    setWarningModalSaving(true);
    const updatedMapping: IUploadedFile | undefined = await addUpdateDataMapping(uploadedFile);
    if (updatedMapping) {
      const updated: IUploadedFile | undefined = await handleDocumentIssues(uploadedFile, checkedIssues, selectedSheet, excludedRowsEndRowNum);
      if (updated) {
        if (isGLTransaction) {
          showToaster('success', `${uploadedFile.filename}'s fields have been successfully mapped`);
          setActiveStep(2);
          setIsDirty(false);
        } else {
          showToaster('success', `${uploadedFile.filename} has been successfully mapped`);
          setIsDirty(false);
          handleClose();
        }
      }
    }
    setWarningModalSaving(false);
  };

  /**
   * This function adds or updates data mapping for the uploaded file and updates the uploaded file object accordingly.
   * @param uploadedFile The uploaded file object.
   * @returns The updated uploaded file object with data mapping information.
   */
  const addUpdateDataMapping = async (uploadedFile: IUploadedFile) => {
    try {
      const payload: IDataMapping = {
        documentFk: uploadedFile.recordId ?? 0,
        documentFields: filteredValues[0],
        tableFields: headers,
        status: isInLandingPage ? 'Remapped' : 'Completed',
        changeAmt2Sign: changeAmt2Sign,
        documentTypeFk: uploadedFile.documentTypeFk,
        arCollateralFk: uploadedFile.arCollateralFk,
        borrowerFk: uploadedFile.borrowerFk
      }
  
      if (uploadedFile.dataMappingFk) {
        const response = await axiosInstance.request({
          url: `${fileImportAPI.dataMapping.MAIN_ENDPOINT}/${uploadedFile.dataMappingFk}`,
          method: PUT,
          data: { recordId: uploadedFile.dataMappingFk, ...payload },
        });
        const completed: IDataMapping = response.data;
        uploadedFile.dataMappingTableFields = completed.tableFields;
        uploadedFile.dataMappingChangeAmt2Sign = completed.changeAmt2Sign;
        uploadedFile.defaultMapping = completed.tableFields;
        uploadedFile.mapped = isInLandingPage || !isGLTransaction;
      } else {
        const response = await axiosInstance.request({
          url: `${fileImportAPI.dataMapping.MAIN_ENDPOINT}`,
          method: POST,
          data: payload,
        });
        const completed: IDataMapping = response.data;
        uploadedFile.dataMappingFk = completed.recordId;
        uploadedFile.dataMappingStatus = completed.status as IMappingStatus;
        uploadedFile.dataMappingTableFields = completed.tableFields;
        uploadedFile.dataMappingChangeAmt2Sign = completed.changeAmt2Sign;
        uploadedFile.defaultMapping = completed.tableFields;
        uploadedFile.mapped = isInLandingPage || !isGLTransaction;
      }
      setIsAutoMapped(false);
      return uploadedFile;
    } catch (error) {
      console.log('ADD/UPDATE DATA MAPPING ERROR: ', error);
      showToaster('error', 'Error in saving data mapping');
      setIsSaving(false);
    }
  };

  /**
   * This function handles document issues by deleting existing issues, adding new issues, and performing calculations if needed.
   * @param uploadedFile The uploaded file object.
   * @param checkedIssues An array of checked document issues to be added.
   */
  const handleDocumentIssues = async (uploadedFile: IUploadedFile, checkedIssues: IDocumentIssue[], selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    if (uploadedFile.documentIssues?.length) {
      const deletedIssues: IUploadedFile | undefined = await deleteDocumentIssues(uploadedFile);
      if (deletedIssues) {
        return await proceedAddingDocumentIssues(deletedIssues, checkedIssues, selectedSheet, excludedRowsEndRowNum);
      }
    } else {
      return await proceedAddingDocumentIssues(uploadedFile, checkedIssues, selectedSheet, excludedRowsEndRowNum);
    }
  };

  const proceedAddingDocumentIssues = async (uploadedFile: IUploadedFile, checkedIssues: IDocumentIssue[], selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    if (checkedIssues.length) {
      const addedDocumentIssues: IUploadedFile | undefined = await addDocumentIssues(uploadedFile, checkedIssues);
      if (addedDocumentIssues) {
        const updated: IUploadedFile | undefined = await handleDeleteCalculationMappings(addedDocumentIssues, selectedSheet, excludedRowsEndRowNum);
        if (updated) return updated;
      }
    } else {
      const updated: IUploadedFile | undefined = await handleDeleteCalculationMappings(uploadedFile, selectedSheet, excludedRowsEndRowNum);
      if (updated) return updated;
    }
  }

  /**
   * This function deletes existing document issues associated with the uploaded file.
   * @param uploadedFile The uploaded file object.
   * @returns The updated uploaded file object with deleted document issues, or undefined if an error occurs.
   */
  const deleteDocumentIssues = async (uploadedFile: IUploadedFile) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.documentIssue.LIST_MAIN_ENDPOINT,
        method: DELETE,
        params: { documentId: uploadedFile.recordId }
      });
      if (response.status === 204) {
        uploadedFile.documentIssues = [];
        return uploadedFile;
      } else {
        showToaster('error', 'Error deleting previous issues.');
        setIsSaving(false);
        throw new Error();
      }
    } catch (error) {
      showToaster('error', 'Error deleting previous issues.');
      console.log('DELETING DOCUMENT ISSUES ERROR: ', error);
      setIsSaving(false);
    }
  };

  /**
   * This function adds document issues to the uploaded file and updates the uploaded file object accordingly.
   * @param uploaded The uploaded file object.
   * @param checkedIssues An array of checked document issues to be added.
   * @returns The updated uploaded file object with added document issues, or undefined if an error occurs.
   */
  const addDocumentIssues = async (uploaded: IUploadedFile, checkedIssues: IDocumentIssue[]) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.documentIssue.LIST_MAIN_ENDPOINT,
        method: POST,
        data: [...checkedIssues]
      });
      const added: IDocumentIssue[] = response.data;
      uploaded.documentIssues = added;
      return uploaded;
    } catch (error) {
      showToaster('error', 'Error adding document issues.');
      console.log('ADDING DOCUMENT ISSUE ERROR: ', error);
      setIsSaving(false);
    }
  };

  /**
   * This function handles the deletion of calculation mappings for the uploaded file and updates the document accordingly.
   * @param uploadedFile The uploaded file object.
   */
  const handleDeleteCalculationMappings = async (uploadedFile: IUploadedFile, selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    if (isGLTransaction && resetCalcMapping) {
      const deletedCalcMappings: IUploadedFile | undefined = await deleteCalculationMappings(uploadedFile);
      if (deletedCalcMappings) {
        const updated: IUploadedFile | undefined = await handleUpdateDocument(deletedCalcMappings, selectedSheet, excludedRowsEndRowNum);
        if (updated) return updated;
      }
    } else {
      const updated: IUploadedFile | undefined = await handleUpdateDocument(uploadedFile, selectedSheet, excludedRowsEndRowNum);
      if (updated) return updated;
    }
  };

  /**
   * This function deletes calculation mappings associated with the uploaded file.
   * @param uploadedFile The uploaded file object.
   * @returns The updated uploaded file object with deleted calculation mappings, or undefined if an error occurs.
   */
  const deleteCalculationMappings = async (uploadedFile: IUploadedFile) => {
    try {
      const response = await axiosInstance.request({
        url: fileImportAPI.calculationMapping.LIST_MAIN_ENDPOINT,
        method: DELETE,
        params: { documentId: uploadedFile.recordId }
      });
      if (response.status === 204) {
        uploadedFile.calculationMappings = [];
        return uploadedFile;
      } else {
        showToaster('error', 'Error deleting calculation mapping.');
        setIsSaving(false);
        throw new Error();
      }
    } catch (error) {
      showToaster('error', 'Error deleting calculation mapping.');
      console.log('DELETING CALCULATION MAPPING ERROR: ', error);
      setIsSaving(false);
    }
  };

  /**
   * This function handles the update of a document and updates the state accordingly.
   * @param uploaded The uploaded file object to be updated.
   */
  const handleUpdateDocument = async (uploaded: IUploadedFile, selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    const updatedDocument: IUploadedFile | undefined = await updateDocument(uploaded, selectedSheet, excludedRowsEndRowNum);

    if (updatedDocument) {
      setSelectedFile && setSelectedFile(updatedDocument);
      setUploadedFiles(uploadedFiles.map(uploaded => mapUpdatedFile(uploaded, updatedDocument)));
      filteredRows && setFilteredRows && setFilteredRows(filteredRows.map(uploaded => mapUpdatedFile(uploaded, updatedDocument)));
      importedFiles && setImportedFiles && setImportedFiles(importedFiles.map(uploaded => mapUpdatedFile(uploaded, updatedDocument)));
      return updatedDocument;
    }
  }

  /**
   * Checks if the uploaded file and the current item of the array has the same record id.
   * @param uploaded - The current file in the array.
   * @param uploadedFile - The uploaded file object used to update the array.
   * @returns A file whether the new or the current depending on the matched record id.
   */
  const mapUpdatedFile = (uploaded: IUploadedFile, uploadedFile: IUploadedFile) => {
    if (uploaded.recordId === uploadedFile.recordId) return uploadedFile;
    else return uploaded;
  };

  /**
   * This function updates a document and returns the updated document object.
   * @param uploadedFile The uploaded file object to be updated.
   * @returns The updated uploaded file object, or undefined if an error occurs.
   */
  const updateDocument = async (uploadedFile: IUploadedFile, selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    try {
      const response = await axiosInstance.request({
        url: `${fileImportAPI.document.MAIN_ENDPOINT}/${uploadedFile.recordId}`,
        method: PUT,
        data: {
          recordId: uploadedFile.recordId,
          borrowerFk: uploadedFile.borrowerFk,
          documentTypeFk: uploadedFile.documentTypeFk,
          arCollateralFk: uploadedFile.arCollateralFk,
          bbPeriodFk: uploadedFile.bbPeriodFk,
          filename: uploadedFile.filename,
          processed: uploadedFile.processed,
          mapped: isInLandingPage || !isGLTransaction, // mapped = true if remapping only
          archive: uploadedFile.archive,
          totalTrxAmt: uploadedFile.totalTrxAmt,
          selectedSheet,
          sheetNames: uploadedFile.sheetNames,
          shouldReplace: uploadedFile.shouldReplace,
          hasPrevious: uploadedFile.hasPrevious,
          updatedAt: uploadedFile.updatedAt,
          createdAt: uploadedFile.createdAt,
          excludedRowsEndRowNum,
        }
      });
      const updated: IDocument = response.data;
      uploadedFile.selectedSheet = updated.selectedSheet;
      uploadedFile.excludedRowsEndRowNum = updated.excludedRowsEndRowNum;
      uploadedFile.updatedAt = updated.updatedAt;
      return uploadedFile;
    } catch (error) {
      console.log('UPDATE DOCUMENT ERROR: ', error);
      showToaster('error', 'Error in updating document');
      setIsSaving(false);
    }
  };

  /**
   * This function returns a save button component based on the current state and conditions.
   * @param uploadedFile The uploaded file object.
   * @returns A JSX element representing the save button, or null if conditions are not met.
   */
  const getSaveButton = (uploadedFile: IUploadedFile, selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    if (isSaving) {
      return (
        <Button
          variant='contained'
          disabled={isSaving}
          sx={styles.saveButton}
        >
          <>
            Checking Issues
            <CircularProgress size={15} />
          </>
        </Button>
      )
    } else if (isGLTransaction) {
      return (
        <Button
          data-testid='next-btn-field-mapping'
          variant='contained'
          disabled={isDisabled || isSaving}
          onClick={async () => await handleNext(uploadedFile, selectedSheet, excludedRowsEndRowNum)}
          sx={styles.saveButton}
        >
          Next
        </Button>
      )
    } else {
      return (
        <Button
          data-testid='save-btn-field-mapping'
          variant='contained'
          disabled={isDisabled || isSaving}
          onClick={async () => await handleSave(uploadedFile, selectedSheet, excludedRowsEndRowNum)}
          sx={styles.saveButton}
        >
          Save
        </Button>
      )
    }
  };

  /**
   * This function handles the "Next" button click action, either navigating to the next step or saving if needed.
   * @param uploadedFile The uploaded file object.
   */
  const handleNext = async (uploadedFile: IUploadedFile, selectedSheet: string | null, excludedRowsEndRowNum: number | undefined) => {
    if (isFieldMappingDirty || isAutoMapped) {
      await handleSave(uploadedFile, selectedSheet, excludedRowsEndRowNum);
    } else {
      setUpdatedUploaded(uploadedFile);
      if (documentIssueWarnings.length) {
        setWarningModalOpen(true);
      } else {
        setActiveStep(2);
      }
    }
  }
  
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid item xs={12} xl={12}>
          <Box sx={styles.noteContainer}>
            <Box>
              <Typography tabIndex={0} style={{...styles.fontNote}}>
                <b style={{...styles.note}}> Note: </b> These are the required fields needed to be mapped before proceeding to importing of file.
              </Typography>
              {getRequiredFieldsTextDisplay()}
              {isGLTransaction &&
              <FormControlLabel
                sx={styles.checkBoxAmt2Container}
                control={
                  <Checkbox
                    size='small'
                    checked={changeAmt2Sign}
                    onChange={(e) => setChangeAmt2Sign(e.target.checked)}
                    sx={styles.checkBoxAmt2}
                  />
                }
                label={
                  <Typography tabIndex={0} sx={styles.checkBoxAmt2Label}>
                    Change the sign of Amount 2
                  </Typography>
                }
              />}
            </Box>
            {uploadedFile.isExcelFile &&
              <Autocomplete
                disabled={true}
                value={selectedSheet}
                id='sheet-names-dropdown'
                options={uploadedFile.sheetNames ?? []}
                sx={styles.sheetNameDropdown}
                size='small'
                renderInput={(params) => (
                  <TextField {...params}
                    label='Sheet Name'
                    placeholder='Select Sheet Name'
                    size='small'
                  />
                )}
                componentsProps={{
                  popupIndicator: { 'aria-label':'Dropdown icon', tabIndex: 0 },
                  clearIndicator: { 'aria-label':'Clear icon', tabIndex: 0}
                }}
              />}
          </Box>
          <Grid>
          {documentIssueErrors.length ? 
            <Alert tabIndex={0} severity='error' sx={styles.alert}>
              {documentIssueErrors.map(issue => issue.issueMessage)}
            </Alert> : getAlertForIdAutoPopulation()
          }
          </Grid>
        </Grid>
        <Grid item xs={12} xl={12}>
          <Box sx={styles.comboBoxStyle}>
            <Grid container sx={styles.gridContainer}>
              <Paper sx={{...styles.paper}}>
                <TableContainer sx={styles.valuesTableContainer}>
                  {getTableBody()}
                </TableContainer>
                <TablePagination
                  component='div'
                  count={filteredValues.length}
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={getLabelDisplayedRows()}
                  labelRowsPerPage={getLabelRowsPerPage()}
                  backIconButtonProps={{ 'aria-label': 'Previous page icon' }}
                  nextIconButtonProps={{ 'aria-label': 'Next page icon' }}
                  SelectProps={{ 
                    inputProps: {
                      'aria-label': 'Expand below icon',
                      'aria-labelledby': 'Expand below icon',
                    },
                    id:'expandBelowIcon'
                    }}
                />
              </Paper>
              <Box sx={styles.bottomActionsButtonContainer}>
                <Link
                  data-testid='stepper-back-button'
                  onClick={() => {
                    setButtonClicked('back');
                    if (isFieldMappingDirty) setShowPrompt(true);
                    else setActiveStep(0);
                  }}
                  sx={styles.buttonBack}
                >
                  Back
                </Link>
                <Box sx={styles.saveAndCancelButtons}>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setButtonClicked('cancel');
                      if (isFieldMappingDirty) setShowPrompt(true);
                      else handleClose();
                    }}
                    sx={styles.cancelButton}
                  >
                    Cancel
                  </Button>
                  {getSaveButton(uploadedFile, selectedSheet, excludedRowsEndRowNum)}
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <AddNewFieldModal
        isOpen={newFieldModalOpen}
        mapFields={headerOptions}
        addedFields={addedFields}
        handleClose={setNewFieldModalOpenCallback(false)}
        handleSave={createDocumentAttribute}
      />
      <ConfirmModal 
        open={deleteFieldModalProps.isOpen}
        onClose={() => { setDeleteFieldModalProps({ ...deleteFieldModalProps, isOpen: false })}}
        onConfirm={handleDeleteField}
        title={`${PROMPT.DELETE_PROMPT.title} ${deleteFieldModalProps.deleteName}`}
        description={PROMPT.DELETE_PROMPT.description}
        errorButton
        noButtonText={PROMPT.DELETE_PROMPT.cancel}
        yesButtonText={PROMPT.DELETE_PROMPT.delete}
      />
      <WarningModal
        open={warningModalOpen}
        onClose={() => setWarningModalOpen(false)}
        onConfirm={handleProceedSavingFromModal}
        issueMessages={documentIssueWarnings.map(issue => issue.issueMessage ?? '')}
        issueType='warning'
        warningModalSaving={warningModalSaving}
      />
      <ConfirmModal
        open={showPrompt}
        onClose={() => {
          setShowPrompt(false);
          if (buttonClicked === 'cancel') handleClose();
          else setActiveStep(0);
        }}
        onConfirm={() => setShowPrompt(false)}
        onButtonClose={() => setShowPrompt(false)}
        promptChecker
        title={PROMPT.NAV_PROMPT.title}
        description={`${PROMPT.NAV_PROMPT.description}`}
        yesButtonText={PROMPT.NAV_PROMPT.keepEditing}
        noButtonText={PROMPT.NAV_PROMPT.discardChanges}
        confirmOnly
      />
      <LoadingBackdrop isLoading={isLoading} />
    </>
  )
}
export default FieldMapping;