import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import FileImportTable from './table';
import { Box, Grid, Typography } from '@mui/material';
import styles from './styles';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { IOption } from '../../../interfaces/comboBox';
import { checkUserPermissions, getPermissionsOfUser } from '../../../utility/helper';
import GeneralBreadcrumbs from '../../../components/breadcrumb';
import ComboBox from '../../../components/common/combo-box';
import { AuthContext } from '../../../context/authContext';
import { PERMISSIONS } from '../../../utility/constants';
import { FileImportPermissionsContext, IFileImportPermissionsContext } from '../../../context/fileImportPermissionsContext';
import { IClient } from '../../../interfaces';

/**
 * Component for the File Import Page.
 * @returns A component for the File Import Page.
 */
const FileImport = () => {
  const selectedClientContext               = useContext(SelectedClientContext);
  const {state}                             = useContext(AuthContext);
  const {
    setViewFileImport,
    setImportFile,
    setDownloadFile,
    setArchiveFile
  }                                         = useContext(FileImportPermissionsContext) as IFileImportPermissionsContext
  const [clients, setClients]               = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient] = useState<IOption | null>(null);
  const [clientInput, setClientInput]       = useState<string>('');
  const [canViewPage, setCanViewPage]       = useState<boolean>(false);

  /**
   * This useEffect hook fetches file import permissions based on roles.
   */
  useEffect(() => {
    (async () => {
      await getPermissions();
      const isPermitted: boolean | undefined = await checkPermissions();

      if (isPermitted && selectedClientContext.clients.length) {
        const mapped: IOption[] = selectedClientContext.clients.map(client => {
          const opt: IOption = {
            recordId: client.recordId ?? 0,
            label: client.borrowerName ?? '',
            default: Boolean(client.default),
            parentClient: client.parentClient,
            parentClientFk: client.parentClientFk,
            currencyId: client.currencyId,
          }
          return opt;
        })
        setClients(mapped);
        
        const selected: IOption | undefined = mapped.find(client => client.recordId === selectedClientContext.selectedClient?.recordId);
        setSelectedClient(selected ?? null);
      }
    })();
  },[selectedClientContext.clients]);

  useEffect(() => {
    if (selectedClient?.recordId !== selectedClientContext.selectedClient?.recordId) {
      const selected: IOption = {
        recordId: selectedClientContext.selectedClient?.recordId ?? 0,
        label: selectedClientContext.selectedClient?.borrowerName ?? '',
        default: Boolean(selectedClientContext.selectedClient?.default),
        parentClient: selectedClientContext.selectedClient?.parentClient,
        parentClientFk: selectedClientContext.selectedClient?.parentClientFk,
        currencyId: selectedClientContext.selectedClient?.currencyId,
      }
      setSelectedClient(selected);
    }
  }, [selectedClientContext.selectedClient])

  /**
   * This function asynchronously verifies if the currently logged in user has the permission
   * to view the File Import page. If so, it updates the state to allow the user
   * to view the File Import page.
   */
  const checkPermissions = async () => {
    const isPermitted = await checkUserPermissions(state.uid, PERMISSIONS.VIEW_FILE_IMPORT);
    setCanViewPage(Boolean(isPermitted));
    return isPermitted;
  }

  /**
   * This function asynchronously fetches the permissions of the current user:
   * - View the File Import page.
   * - Import a File.
   * - Download a File.
   * - Archive a File.
   */
  const getPermissions = async () => {
    const permissions = await getPermissionsOfUser(state.uid)
    setViewFileImport(permissions.includes(PERMISSIONS.VIEW_FILE_IMPORT));
    setImportFile(permissions.includes(PERMISSIONS.IMPORT_FILE));
    setDownloadFile(permissions.includes(PERMISSIONS.DOWNLOAD_FILE));
    setArchiveFile(permissions.includes(PERMISSIONS.ARCHIVE_FILE))
  }

  /**
   * This function returns the onChange function for the change event for combo boxes
   * and the onInputChange function for the change event on when the input in a combo box changes.
   */
  const getHandleChange = () => {
    return {
      onChange: (_e: SyntheticEvent<Element, Event>, newValue: IOption | null) => {
        const selected: IClient | undefined = selectedClientContext.clients.find(client => client.recordId === newValue?.recordId);
        selectedClientContext?.setSelectedClient(selected ?? null);
        setSelectedClient(newValue);
      },
      onInputChange: (_e: SyntheticEvent<Element, Event>, newInputValue: string) => setClientInput(newInputValue)
    }
  };

  return (
    <>
    {canViewPage &&
      <Box sx={styles.pageStyle}>
        <Box sx={styles.breadcrumbsBox}>
          <Grid container sx={styles.headerContainer}>
            <Grid item xs={12} md={6} lg={8} xl={8.3}>
              <GeneralBreadcrumbs selectedText='File Import' breadcrumbLinks={[{ linkText: 'Clients', route: '/clients' }]} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
              <Box sx={styles.clientBox}>
                <ComboBox
                  id='client'
                  options={clients}
                  value={selectedClient}
                  inputValue={clientInput}
                  {...getHandleChange()}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.titleContainer}>
          <Typography tabIndex={0} variant='h6' component='h3' sx={styles.title}>File Import</Typography>
        </Box>
        <FileImportTable client={selectedClient}/>
      </Box>}
    </>
  );
};

export default FileImport;